@import "../../../assets/styles/mixins.scss";

.disputesdetails-page-desktop {
  .content-wrapper {
    .detail-row {
      padding-bottom: 40px;
      border-bottom: 1px solid $border-color;
      width: 90%;
      padding-top: 60px;

      .dispute-detail {
        width: 50%;
        padding-right: 20px;

        .detail {
          width: 100%;
          margin-bottom: 10px;

          .label {
            width: 30%;
            text-align: left;
            font-size: 1rem;
            font-weight: 700;
            color: #404553;
          }
          .label-rtl {
            width: 30%;
            text-align: left;
            font-size: 1rem;
            font-weight: 700;
            color: #404553;
          }

          .value {
            width: 70%;
            text-align: left;
            padding-left: 15px;
            font-size: 1rem;
            font-weight: 400;
            color: #404553;
          }
          .value-rtl {
            width: 70%;
            text-align: start;
            padding-right: 15px;
            font-size: 1rem;
            font-weight: 400;
            color: #404553;
          }
        }
      }

      .customer-detail {
        width: 50%;
        border-left: 1px solid $border-color;
        padding-left: 15px;
        .detail {
          width: 100%;
          margin-bottom: 10px;

          .label {
            width: 30%;
            text-align: left;
            font-size: 1rem;
            font-weight: 700;
            color: #404553;
          }
          .label-rtl {
            width: 30%;
            text-align: left;
            font-size: 1rem;
            font-weight: 700;
            color: #404553;
          }

          .value {
            width: 70%;
            text-align: left;
            padding-left: 15px;
            font-size: 1rem;
            font-weight: 400;
            color: #404553;

            .green {
              background-color: #e1f3f2;
              border-radius: 4px;
              padding: 2px 10px;
              width: fit-content;
            }

            .border {
              background-color: white;
              border-radius: 4px;
              padding: 2px 10px;
              border: 1px solid $border-color;
              width: fit-content;
            }
          }
          .value-rtl {
            width: 70%;
            text-align: start;
            padding-right: 15px;
            font-size: 1rem;
            font-weight: 400;
            color: #404553;

            .green {
              background-color: #e1f3f2;
              border-radius: 4px;
              padding: 2px 10px;
              width: fit-content;
            }

            .border {
              background-color: white;
              border-radius: 4px;
              padding: 2px 10px;
              border: 1px solid $border-color;
              width: fit-content;
            }
          }
        }
      }
      .customer-detail-rtl {
        width: 50%;
        border-right: 1px solid $border-color;
        padding-right: 15px;
        .detail {
          width: 100%;
          margin-bottom: 10px;

          .label {
            width: 30%;
            text-align: right;
            font-size: 1rem;
            font-weight: 700;
            color: #404553;
          }
          .label-rtl {
            width: 30%;
            text-align: left;
            font-size: 1rem;
            font-weight: 700;
            color: #404553;
          }

          .value {
            width: 70%;
            text-align: left;
            padding-left: 15px;
            font-size: 1rem;
            font-weight: 400;
            color: #404553;

            .green {
              background-color: #e1f3f2;
              border-radius: 4px;
              padding: 2px 10px;
              width: fit-content;
            }

            .border {
              background-color: white;
              border-radius: 4px;
              padding: 2px 10px;
              border: 1px solid $border-color;
              width: fit-content;
            }
          }
          .value-rtl {
            width: 70%;
            text-align: start;
            padding-right: 15px;
            font-size: 1rem;
            font-weight: 400;
            color: #404553;

            .green {
              background-color: #e1f3f2;
              border-radius: 4px;
              padding: 2px 10px;
              width: fit-content;
            }

            .border {
              background-color: white;
              border-radius: 4px;
              padding: 2px 10px;
              border: 1px solid $border-color;
              width: fit-content;
            }
          }
        }
      }
    }

    .chat-row {
      width: 90%;
      margin-top: 17px;
      background: #ebebeb 0% 0% no-repeat padding-box;
      box-shadow: 0px 0px 2px #00000029;
      border-radius: 8px;
      padding: 17px;
      height: 488px;
      min-height: 488px;
      position: relative;
      margin-bottom: 17px;

      .header {
        margin-bottom: 17px;
        font-size: 1rem;
        font-weight: 700;
        color: #404553;
      }

      .messages {
        height: 350px;
        max-height: 350px;
        overflow-y: auto;
      }

      .editor-row {
        position: absolute;
        bottom: 17px;
        left: 0;
        right: 0;
        padding: 0px 17px;

        .send-button {
          width: 100px;
          padding: 10px;
          background: #1074a8 0% 0% no-repeat padding-box;
          border-radius: 4px;
          color: white;
          font-size: 1rem;
          font-weight: 700;
          margin-inline-start: 17px;
          border: none;
          outline: none;
          box-shadow: none;
        }

        .editor {
          width: calc(100% - 117px);
          background-color: white;
          color: $head-color;
          height: 50px;
          min-height: 50px;
          max-height: 50px;
          box-shadow: 0px 0px 3px #00000029;
          border-radius: 8px;
          border: none;
          outline: none;
          padding-inline-start: 20px;
        }
      }
    }
  }
}
