@import "../mixins.scss";
.channel img {
  margin-right: 10px;
}
.col-sm-4.social-link-col .channel img {
  height: 25px!important;
width: 25px!important;
}
.helpcolright img {
  background-color: white;
  border-radius: 100%;
  padding: 5px;
}
.secpay{
  margin-right: 10px;
  font-size: 12px !important;
  font-weight: 500;
  color: #7e859b;
}
.shopongo{
  flex: 1 1 0%;
  margin: 15px auto 25px;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  margin: 40px auto 40px;
}
.shopongocol{
  flex: 1 1 0%;
    place-content: center space-around;
}
.sog-heading{
  text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    color: rgb(64, 69, 83);
    font-size: 14px;
    margin-bottom: 8px;
    letter-spacing: 0.2px;
}
.sog-icon-row{
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
}
.sog-icon{
  height: 35px;
  cursor: pointer;
  transition: opacity 250ms ease-in-out 0s;
  margin-right: 7px;
}
.follow-us-row .col-sm-4.social-link-col .header {
  display: none;
}
.follow-us-row .col-sm-4.color-col .header {
  display: none;
}
.website-footer-desktop.mx-auto.px-0 {
  background-color:#ffffff;
}

.website-footer-desktop .coloured-bottom-footer {
  background-color: #ffffff;
  border-top: 1px solid #cccccc6b;
}
.website-footer-desktop {
  width: 100%;
  padding-top: 20px;
  border-top: $gray;

  .bottom-footer {
    width: 100%;

    .about-and-contact-row {
      width: 100%;
      font-size: 13px;
      padding-bottom: 10px !important;
  
      svg {
        padding-left: 0 !important;
        width: 14px !important;
      }
      .about-the-shop-col {
        .header-row {
          width: 90% !important;
          text-align: start;
          .alisuq-about-p {
            height: 60px;
            line-height: 19px;
          }
          .addres-p {
            margin-top: 25px;
          }
        }
        @include footer-col-style;
      }

      .contact-us-col {
        text-align: start;
        padding-top: 20px;
        @include footer-col-style;
        font-size: 13px !important;
        .header {
          padding-top: 5px;
          font-size: 14px;
          font-weight: 700;
          color: #404553;
          opacity: 1;
          text-transform: uppercase;
        }
      }

      .news-letter-col {
        text-align: start;
        padding-top: 20px;
        @include footer-col-style;
        .header {
          padding-top: 5px;
          font-size: 13px;
          font-weight: bold;
          color: #404553;
          opacity: 1;
        }
      }

      .footer-menu-col {
        text-align: start;
        padding-top: 20px;
        @include footer-col-style;
        .header {
          padding-top: 5px;
          font-size: 14px!important;
          font-weight: 700!important;
          color: #404553;
          opacity: 1;
          text-transform: uppercase;
        }
      }
    }
  }
  .coloured-bottom-footer {
    background-color: #f7f7f9;
    .follow-us-row {
      width: 100%;
      .copy-right-col {
        text-align: left;
        align-items: center;
        display: flex;
        padding-inline-start: 25px;
        .header {
          font-size: 12px !important;
          font-weight: 500;
          color: #7e859b;
          opacity: 1;
        }
      }

      .social-link-col {
        .channel {
          margin-top: 12px;
          margin-inline-start: -15px;
        }

        svg {
          margin-inline-end: 25px;
          height: 29px;
          width: 29px;
          padding: 0px;

          &:last-child {
            margin-inline-end: 0px;
          }
        }

        .header {
          padding-top: 5px;
          font-family: "Futura Heavy";
          text-align: center;
          font-size: 13px;
          font-weight: bold;
          color: #404553;
          opacity: 1;
        }
      }

      .color-col {
        .channel {
          margin-top: 12px;
          .channel svg {
            color: #183153;
            margin-right: 5px;
            margin-left: 5px;
        }
          .red {
            background: #ff2323 0% 0% no-repeat padding-box;
          }

          .blue {
            background: #0a3fef 0% 0% no-repeat padding-box;
          }

          .black {
            background: #000000 0% 0% no-repeat padding-box;
          }

          .yellow {
            background: #ccf10e 0% 0% no-repeat padding-box;
          }

          .lightblue {
            background: #0dc2d6 0% 0% no-repeat padding-box;
          }

          .green {
            background: #827f27 0% 0% no-repeat padding-box;
          }

          .pink {
            background: #e60db4 0% 0% no-repeat padding-box;
          }
        }

        .color {
          background-color: aqua;
          width: 40px;
          height: 23px;
          margin-inline-end: 5px;
          box-shadow: 0px 0px 3px #00000029;
          opacity: 1;

          &:last-child {
            margin-inline-end: 0px;
          }
        }

        .header {
          text-align: center;
          margin-inline-start: 30%;
          padding-top: 5px;
          width: 50%;
          font-size: 13px;
          font-weight: bold;
          color: #404553;
          opacity: 1;
        }
      }
    }
  }
}
