@import "../mixins.scss";
@import "../variables.scss";

.user-signup-container {
  margin-top: 61px;
  margin-bottom: 200px;

  @include for-md-screen {
    max-width: 500px;
  }

  .user-signup-logo-col {
    margin-bottom: 66px;

    .logo-img {
      margin-bottom: 24px;

      img:hover {
        opacity: 0.7;
      }
    }

    .signup-create-account-text {
      font-size: 23px;
      font-weight: bold;
      margin-bottom: 20px;
      @include welcome_tex($head-color, 1);
    }

    .signup-no-account-text {
      font-size: 15px;
      @include welcome_tex($p-color, 1);

      a {
        color: $blue-header;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }

  .user-signup-form-col {
    max-width: 900px;
    width: 80%;

    .user-signup-form-container {
      .MuiFormControl-root {
        margin-bottom: 15px;

        .MuiFormLabel-root {
          color: gray;
        }

        .MuiInputBase-root {
          &::before {
            border-bottom: 1px solid $border-color;
          }

          &::after {
            border-bottom: 1px solid $border-color;
          }

          input {
            padding-inline-start: 3px;
            &::-ms-fill {
              background-color: white !important;
            }
            &::-internal-autofill-selected {
              background-color: white !important;
            }
          }
        }
      }

      .icon-position-rtl {
        position: absolute !important;
        top: 12px !important;
        right: 95% !important;
        opacity: 0.34;
      }
      .email-rtl {
        .MuiFormControl-root {
          label {
            left: auto;
            font-size: 1rem;
            font-weight: 400;
            color: $p-color;
          }
          .MuiInputBase-root {
            &::before {
              border-bottom: 1px solid $border-color;
            }

            &::after {
              border-bottom: 1px solid $border-color;
            }

            .MuiInputAdornment-root {
              color: gray !important;
            }
          }
        }
      }
      .email-ltr {
        .MuiFormControl-root {
          label {
            font-size: 1rem;
            font-weight: 400;
            color: $p-color;
            text-align: start;
          }

          .MuiInputBase-root {
            &::before {
              border-bottom: 1px solid $border-color;
            }

            &::after {
              border-bottom: 1px solid $border-color;
            }

            .MuiInputAdornment-root {
              color: gray !important;
            }
          }
        }
      }
      .firstName-rtl {
        .MuiFormControl-root {
          label {
            left: auto;
            font-size: 1rem;
            font-weight: 400;
            color: $p-color;
          }
          .MuiInputBase-root {
            &::before {
              border-bottom: 1px solid $border-color;
            }

            &::after {
              border-bottom: 1px solid $border-color;
            }

            .MuiInputAdornment-root {
              color: gray !important;
            }
          }
        }
      }
      .firstName-ltr {
        .MuiFormControl-root {
          label {
            font-size: 1rem;
            font-weight: 400;
            color: $p-color;
            text-align: start;
          }

          .MuiInputBase-root {
            &::before {
              border-bottom: 1px solid $border-color;
            }

            &::after {
              border-bottom: 1px solid $border-color;
            }

            .MuiInputAdornment-root {
              color: gray !important;
            }
          }
        }
      }

      .lastName-rtl {
        .MuiFormControl-root {
          label {
            left: auto;
            font-size: 1rem;
            font-weight: 400;
            color: $p-color;
          }
          .MuiInputBase-root {
            &::before {
              border-bottom: 1px solid $border-color;
            }

            &::after {
              border-bottom: 1px solid $border-color;
            }

            .MuiInputAdornment-root {
              color: gray !important;
            }
          }
        }
      }
      .lastName-ltr {
        .MuiFormControl-root {
          label {
            font-size: 1rem;
            font-weight: 400;
            color: $p-color;
            text-align: start;
          }

          .MuiInputBase-root {
            &::before {
              border-bottom: 1px solid $border-color;
            }

            &::after {
              border-bottom: 1px solid $border-color;
            }

            .MuiInputAdornment-root {
              color: gray !important;
            }
          }
        }
      }

      .password-rtl {
        .MuiFormControl-root {
          label {
            left: auto;
            font-size: 1rem;
            font-weight: 400;
            color: $p-color;
          }
          .MuiInputBase-root {
            &::before {
              border-bottom: 1px solid $border-color;
            }

            &::after {
              border-bottom: 1px solid $border-color;
            }

            .MuiInputAdornment-root {
              color: gray !important;
            }
          }
        }
      }
      .password-ltr {
        .MuiFormControl-root {
          label {
            font-size: 1rem;
            font-weight: 400;
            color: $p-color;
            text-align: start;
          }

          .MuiInputBase-root {
            &::before {
              border-bottom: 1px solid $border-color;
            }

            &::after {
              border-bottom: 1px solid $border-color;
            }

            .MuiInputAdornment-root {
              color: gray !important;
            }
          }
        }
      }
      .confirmPassword-rtl {
        .MuiFormControl-root {
          label {
            left: auto;
            font-size: 1rem;
            font-weight: 400;
            color: $p-color;
          }
          .MuiInputBase-root {
            &::before {
              border-bottom: 1px solid $border-color;
            }

            &::after {
              border-bottom: 1px solid $border-color;
            }

            .MuiInputAdornment-root {
              color: gray !important;
            }
          }
        }
      }
      .confirmPassword-ltr {
        .MuiFormControl-root {
          label {
            font-size: 1rem;
            font-weight: 400;
            color: $p-color;
            text-align: start;
          }

          .MuiInputBase-root {
            &::before {
              border-bottom: 1px solid $border-color;
            }

            &::after {
              border-bottom: 1px solid $border-color;
            }

            .MuiInputAdornment-root {
              color: gray !important;
            }
          }
        }
      }

      padding: auto 20px;

      .user-signup-form-button {
        width: 100%;
        background: #c78e2b 0% 0% no-repeat padding-box !important;
        border: none !important;
        box-shadow: none !important;
        border-radius: 2px !important;
        color: #ffffff !important;
        padding-top: 12px !important;
        padding-bottom: 12px !important;
        margin-top: 20px;
        text-transform: uppercase;
        font-weight: 700;
        height: 48px;
        line-height: normal;
      }
    }
    .vertical-line-with-text {
      width: 1px;

      @include for-md-screen {
        height: 0px;
        width: 100%;
      }

      @include for-lg1-screen {
        height: auto;
        width: 0px;
      }

      .vertical-line-befor-text {
        height: 150px;
        width: 0px;
        border: 1px solid #707070;
        opacity: 0.32;

        @include for-md-screen {
          height: 0px;
          width: 48%;
        }

        @include for-lg1-screen {
          height: 150px;
          width: 0;
        }
      }

      .vertical-line-after-text {
        height: 150px;
        width: 0px;
        border: 1px solid #707070;
        opacity: 0.32;

        @include for-md-screen {
          height: 0px;
          width: 48%;
        }

        @include for-lg1-screen {
          height: 150px;
          width: 0;
        }
      }
    }

    .signup-social-buttons {
      .signup-with-facebook {
        @include social_btn(#4166b1, #ffffff);

        img {
          position: absolute;
          left: 14px;
          top: 11px;
        }
      }

      .signup-with-google {
        margin-bottom: 20px;
        border: 2px solid rgb(154, 154, 154);
        @include social_btn(#fefefe, $head-color);

        img {
          position: absolute;
          left: 16px;
          top: 15px;
        }
      }
    }
  }
}
