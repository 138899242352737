@import "../../../mixins.scss";
@import "../../../variables.scss";

.items-page-desktop {
  .small-screen-none.ml-2.d-flex.flex-direction-row.justify-content-between.top-header {
    // display: none!important;
}
  .offerssectiondesk1{
    max-width: 1440px !important;
    padding: 0 20px;
    margin: auto!important;
    padding-top: 40px;
  }
  .offerssectiondesk2{
    max-width: 1440px !important;
    padding: 30px 20px;
    margin: auto!important;
  }
  .offerstop.image-container{
    padding-left: 15px;
    padding-right: 15px;
    margin: 0px!important;
  }
  .emptyspace-20 {
    margin-top: 20px;
  }
  .top-img {
    border-radius: 8px;
    max-width: 200px;
  }
  .top-img:hover {
    opacity: 0.7;

    .centered {
      display: block;
    }
  }
  .offertabs.col-12.col-sm-6.col-md-4.col-lg-3 {
    max-width: 300px!important;
    background-color: #742013;
    text-align-last: center;
    margin: 20px;
    padding: 5px;
  }
  .belowabove {
    margin-left: 30px !important;
    margin-right: 30px !important;
  }

  .offertabstxt {
    color: white;
    font-size: 19px;
    font-family: 'Futura Heavy';
    letter-spacing: 1.5px;
    margin-bottom: 0px;
    padding: 0.5rem;
  }
  @media (min-width: 993px) {
    .sidebar-desktop {
      width: 20%;
    }

    .items-desktop {
      margin-top: 40px;
      width: 80%;
    }
  }

  @include for-md-screen {
    .sidebar-desktop {
      width: 25%;
    }

    .items-desktop {
      margin-top: 40px;
      width: 70%;
    }
  }
}

.items-page-mobile {
  .all-page-container {
    @include for-mob-screen {
      padding: 0px;
    }
  }

  .mobile-header {
    @include for-desktop-screen {
      display: none;
    }

    @include for-mob-screen {
      padding: 0px 10px;
      background: #fff;
      height: 50px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .logo {
      }

      .mobile-search {
        background-color: #fff;
        width: 72%;
        height: 70%;
        border: 1px solid rgb(198, 204, 221);
        border-radius: 8px;
        margin-left: 2%;
        display: flex;
        align-items: center;

        .input-field {
          border: none;
          outline: none;
          background-color: transparent;
          width: 80%;
          font-size: 11.5px;
          font-weight: 500;
          color: #979aa7;
          &::placeholder {
            color: #979aa7;
          }
          &:hover {
            outline: none;
          }
          &:focus {
            outline: none;
          }
        }
      }
    }
  }

  .sidebar-desktop {
    @include for-mob-screen {
      display: none;
    }
  }

  .items-desktop {
    @include for-mob-screen {
      width: 100%;
    }
  }

  .header-mobile {
    position: sticky;
    top: 0;
    z-index: 99999;
    background-color: #fff;
    @include for-mob-screen {
      display: none;
    }
  }

  .footer-mobile {
    @include for-mob-screen {
      display: none;
    }
  }
}

.mobile-sections {
  width: 100%;
  padding: 10px;

  .product-list-row {
    .title-row {
      .title {
        display: block;
        font-family: 'Futura Heavy';
       


      }
      .viewalldiv{
        width: 30%;
      }
      .view-all-text {
        padding-top: 0.4rem;
        padding-bottom: 0.4rem;
        padding-left: 0.8rem;
        padding-right: 0.8rem;
        font-size: 0.916666rem;
        background-color: black;
        color: white;
        width: 30%;
      }
    }
    .product-cont {
      display: flex;
      flex-direction: row;
      max-width: 95%;
      margin-left: auto;
      margin-right: auto;
      overflow-x: auto;
      overflow-y: hidden;
      .image-cont {
        width: 160px;
        max-width: 160px;
        margin-inline-end: 10px;
      }
    }
  }
}