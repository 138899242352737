@import "../../../../assets/styles/mixins.scss";

.meassage-desktop {
  padding: 17px;

  .wrapper {
    .image-container {
      width: 50px;
      height: 50px;
      border-radius: 100%;
    }
    .text-container {
      position: relative;
      width: 45%;
      height: auto;
      min-height: 50px;
      border-radius: 8px;
      max-width: 45%;
      overflow-wrap: anywhere;

      .date {
        position: absolute;
        top: 100%;
        left: 0;
        color: #2c3338;
        font-size: 0.8rem;
        font-weight: 300;
      }
    }
  }

  .direction-left {
    direction: ltr;

    .image-container {
      background-color: #c8dde8;
    }
    .text-container {
      margin-left: 17px;
      background-color: #c8dde8;
      display: inline-flex;
      justify-content: flex-start;
      align-items: center;
      .text {
        margin-left: 10px;
        color: grey;
      }

      .date {
        top: 100%;
        left: 0;
        right: unset;
        bottom: unset;
      }
    }
  }

  .direction-right {
    direction: rtl;

    .image-container {
      background-color: rgba(100, 160, 99, 0.3);
    }

    .text-container {
      margin-right: 17px;
      background-color: rgba(100, 160, 99, 0.3);
      display: inline-flex;
      justify-content: flex-end;
      align-items: center;
      .text {
        margin-left: 10px;
        color: grey;
        direction: ltr;
      }
      .date {
        top: 100%;
        right: 0;
        left: unset;
        bottom: unset;
      }
    }
  }
}
