@import "../../../mixins.scss";

.item-viewed-desktop {
  width: 100%;
  margin: auto;

  @include for-mob-screen {
    display: none !important;
  }

  .heading {
    width: 98%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 35px;
    color: #404553;
    font-size: 18px;
    font-weight: bold;
  }
}

.item-viewed-mobile {
  margin-top: 20px;
  
  .heading {
    color: #373737;
    font-size: 16px;
    font-family: 'Futura Heavy';
    margin-left: 10px;
    margin-right: 10px;
    @include for-mob-screen {
    color: #000000;
    font-size: 18px;
    font-family: 'Futura Heavy';
    padding-left: 20px;
    padding-right: 20px;
    letter-spacing: 0.3px;
    margin-left: unset;
    margin-right: unset;
    }
  }

  .heading-ar {
    text-align: right;
    color: #373737;
    font-size: 16px;
    font-family: 'Futura Heavy';
    margin-left: 10px;
    margin-right: 10px;
    @include for-mob-screen {
    color: #000000;
    font-size: 18px;
    font-family: 'Futura Heavy';
    padding-left: 20px;
    padding-right: 20px;
    letter-spacing: 0.3px;
    margin-left: unset;
    margin-right: unset;
    }
  }

  .product-cont {
    display: flex;
    flex-direction: row;
    max-width: 95%;
    margin-left: auto;
    margin-right: auto;
    overflow-x: auto;
    overflow-y: hidden;
    margin-bottom: 15px;
    .image-cont {
      width: 160px;
      max-width: 160px;
      margin-inline-end: 10px;
    }
  }
}