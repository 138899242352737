.order-items-list-for-refund-main-mobile {
  background-color: #fff;
  .information-tag-line {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fafafa;
    text-align: start;
    font-size: 1rem;
    color: #94979e;
    margin-top: 30px;
    margin-bottom: 30px;
    .tag-line-text {
      margin: auto;
      width: 90%;
    }
  }
  .list-of-items-refunds {
    text-align: start;
    .item {
      width: 100%;
      display: flex;

      .description-container {
        width: 80%;
        margin-inline-start: 10%;

        .description {
          color: #404553;
        }
        .sku {
          color: rgb(148, 145, 145);
          margin-top: 2px;
          margin-bottom: 3px;
        }
        .order-id {
          color: lightgrey;
          margin-top: 5px;
        }
      }
      .right-arrow {
        direction: ltr;
        margin-top: 10px;
        margin-inline-end: 10px;
      }
    }
  }
}
