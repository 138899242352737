@import "../../mixins.scss";
@import "../../variables.scss";

.page-container {
  width: 100%;
  min-width: 100%;

  .all-page-container {
    @include for-mob-screen {
      padding: 0px;
    }
  }
  .content-container {
    width: 100%;
    max-width: 1440px;

    @include for-md-screen {
      padding-left: 0;
      padding-right: 0;
    }

    @include for-lg1-screen {
      padding-left: 12px;
      padding-right: 12px;
    }

    .left-pane {
      width: 230px;
      padding-right: 12px;
      padding-left: 0px;

      @include for-md-screen {
        width: 200px;
      }

      @include for-lg1-screen {
        width: 230px;
      }

      @include for-mob-screen {
        display: none;
      }
    }

    .right-pane {
      width: calc(100% - 230px);
      padding-right: 0px;
      padding-left: 12px;
      padding-bottom: 30px;

      @include for-md-screen {
        width: calc(100% - 200px);
      }

      @include for-lg1-screen {
        width: calc(100% - 230px);
      }

      @include for-mob-screen {
        width: 100%;
        padding-left: 0px;
        height: calc(100vh - 55px);
      }
    }
  }

  .unstyled-anchor {
    text-decoration: none;
    cursor: pointer;
  }

  .overflow-auto {
    overflow: auto;
  }

  .word-break-none {
    word-break: keep-all;
  }
}

.header-mobile {
  @include for-mob-screen {
    display: none;
  }
}

.header-to-show-on-mobile-only {
  @include for-desktop-screen {
    display: none;
  }

  @include for-mob-screen {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 7px 10px;
    background: #fff;

    .img {
      margin-inline-start: 30px;
      width: 38%;
      height: 8%;
    }
  }
}
