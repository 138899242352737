@import "../../mixins.scss";

.checkout-header-desktop {
  counter-reset: progressCount;
  padding: 0px;
  background-color: white;

  .checkout-row {
    width: 100%;
    margin: 0px;
    box-shadow: 0px 0px 3px #00000070;

    .checkout {
      width: 100%;
      max-width: 1440px;
      padding: 5px 45px;

      @include for-md-screen {
        padding: 5px 30px;
      }

      @include for-lg1-screen {
        padding: 5px 45px;
      }

      .back-col {
        color: $head-color;
        font-size: $header-heading-size;
        font-weight: 600;
        text-align: start;
        cursor: pointer;

        svg {
          direction: ltr;
          font-size: $header-heading-size;
          font-weight: $customer-page-subtext-weight;
          margin-inline-start: 8px;
          margin-inline-end: 20px;
          color: $head-color;
        }

        .bold-text {
          font-size: $customer-page-subheading-size;
          font-weight: $customer-page-button-weight;
          white-space: nowrap;
        }
      }

      .checkout-col {
        width: 100%;

        .assistance-col {
          white-space: nowrap;

          .bold-text {
            font-size: $customer-page-subheading-size;
            font-weight: $customer-page-button-weight;
          }

          .small-text {
            font-size: $customer-page-subtext-size;
            font-weight: $customer-page-subtext-weight;
            color: $p-color;

            &::before {
              display: inline-block;
              content: "|";
              margin-left: 10px;
              margin-right: 10px;
            }
          }
        }

        .logo-col {
          cursor: pointer;
          width: 150px;
          margin-top: 10px;
          margin-bottom: 10px;
          margin-left: 20px;
        }
      }
    }
  }

  .shipping-row {
    width: 100%;
    margin: 0px;
    .shipping {
      white-space: nowrap;
      width: 100%;
      max-width: 1440px;
      padding: 18px 45px 15px 45px;

      @include for-md-screen {
        padding: 18px 30px 15px 30px;
      }

      @include for-lg1-screen {
        padding: 18px 45px 15px 45px;
      }
      .signIn {
        color: $p-color;
        font-size: 0.875rem;
        font-weight: $customer-page-subtext-weight;
        width: 28.5%;
        &::after {
          content: "";
          display: inline-block;
          position: relative;
          margin: 0px 8px 0px 12px;
          top: unset;
          right: unset;
          width: 100%;
          height: 0.33rem;
          background-color: $border-color;
          border-radius: 5px;
        }
        .signIn-circle1 {
          display: inline-block;
          margin: 0px 5px 5px;
          background-color: white;
          width: 1.667rem;
          border: 0.186rem solid $border-color;
          height: 1.667rem;
          text-align: center;
          font-size: 0.9rem;
          flex: 0 0 1.667rem;
          padding: 0px;
          border-radius: 100%;
        }
      }
      .address {
        color: $p-color;
        font-size: 0.875rem;
        font-weight: $customer-page-subtext-weight;
        width: 35%;

        &::after {
          content: "";
          display: inline-block;
          position: relative;
          margin: 0px 8px 0px 12px;
          top: unset;
          right: unset;
          width: 100%;
          height: 0.33rem;
          background-color: $border-color;
          border-radius: 5px;
        }
        .address-circle2 {
          display: inline-block;
          margin: 0px 5px 5px;
          background-color: white;
          width: 1.667rem;
          border: 0.186rem solid $border-color;
          height: 1.667rem;
          text-align: center;
          flex: 0 0 1.667rem;
          padding: 0px;
          font-size: 0.9rem;
          border-radius: 100%;
          border: 0.186rem solid $border-color;
        }
      }

      .payment {
        color: $p-color;
        font-size: 0.875rem;
        font-weight: $customer-page-subtext-weight;
        width: 28.5%;

        .payment-circle3 {
          display: inline-block;
          margin: 0px 5px 5px;
          background-color: white;
          width: 1.667rem;
          height: 1.667rem;
          border: 0.186rem solid $border-color;
          text-align: center;
          font-size: 0.9rem;
          flex: 0 0 1.667rem;
          padding: 0px;
          border-radius: 100%;
        }
        &::after {
          content: "";
          display: inline-block;
          position: relative;
          margin: 0px 8px 0px 12px;
          top: unset;
          right: unset;
          width: 100%;
          height: 0.33rem;
          background-color: $border-color;
          border-radius: 5px;
        }
      }

      .order {
        color: $p-color;
        font-size: 0.875rem;
        font-weight: $customer-page-subtext-weight;
        width: 10%;
        .order-circle4 {
          display: inline-block;
          counter-increment: progressCount 1;
          content: counter(progressCount);
          margin: 0px 5px 5px;
          background-color: white;
          width: 1.667rem;
          height: 1.667rem;
          border: 0.186rem solid $border-color;
          font-size: 0.9rem;

          text-align: center;
          flex: 0 0 1.667rem;
          padding: 0px;
          border-radius: 100%;
        }
      }
      .colored-if-active {
        border: 0.186rem solid white !important;
        background-color: rgb(56, 174, 4) !important;
        color: white !important;
      }
    }
  }
}
