@import "../../../mixins.scss";

.customer-address-body-mobile {
  max-width: 768px;
  width: 98%;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 60px;

  .continue-button {
    position: fixed;
    left: 4%;
    bottom: 0;
    width: 92%;
    height: 50px;
    background-color: #c78e2b;
    font-size: 15px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
  }

  .empty-cont {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 50vh;

    .img-cont {
      @include for-mob-screen {
        width: 60%;
      }
    }
    .save-address {
      color: #404553;
      font-weight: bold;
      font-size: 16px;

      @include for-mob-screen {
        font-size: 13px;
      }
    }
    .delivery-address {
      color: #404553;
      font-size: 15px;

      @include for-mob-screen {
        font-size: 13px;
      }
    }
  }

  .add-address {
    margin-top: 4px;
    background: #c78e2b 0% 0% no-repeat padding-box;
    border-radius: 4px;
    text-align: left;
    font-size: 14px;
    font-weight: bold;
    color: #ffffff;
    padding: 10px 20px 10px 20px;
    text-decoration: none;
    border: none;
  }
}
