@import "../../../../mixins.scss";

.shipment {
  $section-title-font-size: 0.8rem;
  $green-color: #36af00;

  .small-screen-none {
    @include for-mob-screen {
      display: none !important;
    }
  }

  .green-text {
    color: $green-color;
  }
  .red-text {
    color: red;
  }
  .orange-text {
    color: #ff8c00;
  }

  .display-row-mob {
    @include for-mob-screen {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }

  .large-screen-none {
    @include for-desktop-screen {
      display: none !important;
    }
  }

  .shipment-summary {
    text-align: initial;

    .shipment-title {
      font-size: $section-title-font-size;
      font-weight: 700;
      margin-inline-end: 10px;

      svg {
        margin-inline-end: 5px;
      }
    }

    .shipment-number {
      font-size: $section-title-font-size;
      font-weight: 500;

      @include for-mob-screen {
        font-weight: 700;
      }
    }

    .progress {
      height: 6px;
      max-height: 6px;

      .default-bar {
        background: transparent;
      }
      .bar {
        background: $green-color;
      }
      .red-bar {
        background: red;
      }
      .orange-bar {
        background: #ff8c00;
      }

      .default-bar:not(:last-child) {
        border-right: 3px solid white;
      }
      .bar:not(:last-child) {
        border-right: 3px solid white;
      }
      .red-bar:not(:last-child) {
        border-right: 3px solid white;
      }
      .orange-bar:not(:last-child) {
        border-right: 3px solid white;
      }
    }

    .status {
      font-size: 0.9rem;
      text-align: initial;
      text-transform: uppercase;

      @include for-mob-screen {
        font-size: 12px;
        font-weight: bold;
      }
    }

    .delivery-date {
      @include for-desktop-screen {
        display: none;
      }

      @include for-mob-screen {
        color: #404553;
        font-size: 12px;
        font-weight: 500;

        .green-text {
          color: $green-color;
          font-weight: bold;
        }
      }
    }
  }

  .shipment-items {
    .shipment-item:not(:last-child) {
      border-bottom: 1px solid #f3f3f5;
    }
  }
}
