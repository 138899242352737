@import "../mixins";
@import "../variables";

.forget-password-mobile-page-main {
  display: flex;
  flex-direction: column;
  .error {
    color: red;
    font-size: 12px;
    text-align: center;
    margin-top: -40px;
    margin-bottom: 50px;
  }

  .mt-40-px {
    margin-top: 40px;
  }
  .inner-wrapper {
    .first-row-forget-label {
      width: 100%;
      text-align: start;
      .forget-pw-label {
        width: 90%;
        margin-inline-start: 35px;
        display: block;
        margin-top: 40px;
        font-size: 1.8rem;
        font-weight: bold;
      }
    }
    .second-row-forgetpw-desc {
      width: 100%;
      text-align: start;
      .forget-pw-desc {
        width: 70%;
        margin-inline-start: 35px;

        display: block;
        margin-top: 30px;
        opacity: 0.8;
        font-size: 0.875rem;
        font-weight: 500;
      }
    }
    .third-row-forget-apssword-email-input {
      width: 100%;

      .e-label {
        width: 70%;
        margin-inline-start: 35px;
        text-align: start;
        display: block;
        margin-top: 40px;
        opacity: 0.6;
        font-size: 0.875rem;
        font-weight: 500;
      }
      .email {
        .e-input {
          width: 70%;
          margin-inline-start: 35px;
          border-top: none;
          border-left: none;
          border-right: none;
          background-color: transparent;
          margin-top: 10px;
          border-bottom: 1px solid $lightGray;
          padding-bottom: 10px;
          font-size: 13px;
          &:hover {
            outline: none;
          }
          &:focus {
            outline: none;
          }
        }
        ::placeholder {
          font-weight: 500 !important;
          color: #a5a5a5;
          font-size: 0.9rem;
        }
      }
    }
    .fourth-row-reset-password-btn-div {
      width: 100%;

      .reset-pw {
        margin-inline-start: 35px;

        margin-top: 35px;
        background-color: $form-btn-bg;
        width: 80%;
        max-width: 80%;
        height: 50px;
        font-weight: bold;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        cursor: pointer;
      }
    }
    .last-row-signup {
      margin-inline-start: 35px;
      text-align: start;
      width: 70%;
      margin-top: 35px;
      font-size: 0.9rem;
      align-items: center;
      color: $p-color;

      .signup-text {
        font-weight: bold;
        font-size: 1rem;
        margin-left: 5px;
        cursor: pointer;
      }
    }
  }
}
