@import "../mixins.scss";
@import "../variables.scss";

  .emptyspace{
    margin-top: 30px;
  }

  .subheadings{
    text-transform: uppercase;
    color: #7d252a;
    font-weight: 600;
    font-size:20px;
  }


.leadername{
  font-weight: 600;
  margin-bottom: 0px;
}
blockquote {
  font-family: Georgia, serif;
  font-size: 18px;
  font-style: italic;
  width: 300px;
  margin: 0.25em 0;
  padding: 0.35em 40px;
  line-height: 1.45;
  position: relative;
  color: #383838;
  background-color: #eaeae1;
    border-radius: 5px;
  }
  
  blockquote:before {
  display: block;
  content: "\201C";
  font-size: 60px;
  position: absolute;
  left: 10px;
  top: -20px;
  color: #7a7a7a;
  }
  blockquote:after {
    display: block;
    content: "\201D";
    font-size: 60px;
    position: absolute;
  right:60px;
  bottom:-40px;
    color: #7a7a7a;
    }
  .team {
    text-align: -webkit-center;
}
p{
  font-size: 12px;
}
.shipping-page-main strong {
  color: #742013;
  font-size:14px;
}
.shipping-page-main .heading {
  display: none;
}
.alisuqstorycontent{

  width: 80%;
}