.cart-sidebar-product-list-container-desktop {
  width: auto;
  background-color: white;

  overflow-y: auto;
  overflow-x: hidden;
  // margin-bottom: 20px;
  
  padding-bottom: -10px;
}
.height-70 {
  height: 70%;
  max-height: 70%;
}
.height-80 {
  height: 82%;
  max-height: 82%;
}
.height-60 {
  height: 60%;
  max-height: 60%;
}
