@import "../mixins.scss";

.customer-vendor-shop-page-desktop {
  max-width: 100%;
  background-color: white;
  .dropdown-menu {
    padding: 0px;
  }
  .dropdown-divider {
    margin: 0px;
  }
  .feat{
    padding: 7.5px;
  }
  a.nav-link.store-menu {
  
  letter-spacing: 1px;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 600;
    color: #000!important;
    cursor: pointer;
  }
  .content-wrapper {
    margin: auto;
    width: 100%;
    max-width: 1440px;

    .product-category-list-row {
      margin-bottom: 50px;
    }

    .product-list-row {
      justify-content: center;
      margin-bottom: 5px;
      @include for-768-screen {
        margin-bottom: 0px;
      }
    }

    .kid-fashion-banner-row {
      width: 98%;
      max-width: 1440px;

      .kid-fashion-banner-container {
        width: 100%;

        .vendor-shop-banner {
          height: 300px;
          color: #fff;
          border-radius: 4px;
        }
      }
    }
  }
}
