@import "../mixins.scss";
@import "../variables.scss";
.select-shipping-address-desktop {
  width: 100%;
  background-color: #f7f7fa;
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 100px;
  .w-340-px {
    min-width: 300px;
    max-width: 500px;
  }

  .address-row {
    margin: 0px;
    padding: 0px;

    .addresses {
      width: 100%;
      max-width: 1440px;
      margin-top: 20px;
      padding: 0px 45px 40px 45px;
      flex-wrap: wrap;

      @include for-md-screen {
        padding: 0px 30px;
      }

      @include for-lg1-screen {
        padding: 0px 45px;
      }

      .address-col {
        padding-right: 20px;
        margin-bottom: 0px;
        width: 25%;
        text-decoration: none;

        @include for-md-screen {
          width: 100%;
          margin-bottom: 15px;
        }

        @include for-lg1-screen {
          width: 33.3333%;
          margin-bottom: 0px;
        }

        @include for-lg2-screen {
          width: 33.3333%;
          margin-bottom: 0px;
        }

        .add-address-col {
          border: 1px solid rgb(226, 229, 241);
          box-shadow: 0px 3px 2px 0px rgba(0, 0, 0, 0.04);
          border-radius: 2px;
          background-color: #ffffff;
          width: 100%;
          padding: 14px 15px 16px 15px;
          height: 190px;

          @include for-md-screen {
            height: auto;
          }

          @include for-lg1-screen {
            height: 190px;
          }

          @media only screen and (min-width: 1118px) and (max-width: 1200px) {
            height: 172px;
          }

          @media only screen and (min-width: 1201px) and (max-width: 1264px) {
            height: 208px;
          }

          &:hover {
            border: 1px solid rgb(56, 102, 223);
            box-shadow: 0px 0px 0px 1px rgb(56, 102, 223),
              0px 3px 2px 0px rgba(0, 0, 0, 0.4);
          }

          .add-address {
            text-align: center;
            color: $head-color;
            font-size: $header-heading-size;
            font-weight: 400;
          }
        }
      }
    }

    .continue {
      width: 100%;
      margin-top: 30px;
      padding: 0px 45px;

      @include for-md-screen {
        padding: 0px 30px;
      }

      @include for-lg1-screen {
        padding: 0px 45px;
      }

      .continue-button {
        width: 147px;
        max-width: 147px;
        min-width: 147px;
        border: none;
        border-radius: 2px;
        background-color: $form-btn-bg;
        padding: 17.5px 40px;
        color: #ffffff;
        font-size: $customer-page-subheading-size;
        font-weight: 700;
      }
    }
  }
}
