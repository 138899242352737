@import "../../mixins.scss";

.not-foun-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  .heading {
    color: #404553;
    font-weight: bold;
    font-size: 6rem;
    text-align: center;
  }
  .sub-heading {
    color: #404553;
    font-weight: 700;
    font-size: 1.5rem;
    text-align: center;
    margin-top: -20px;
  }
  .text {
    color: #404553;
    font-weight: 400;
    font-size: 0.9rem;
    text-align: center;
    margin-top: 10px;
  }

  .go-to-home {
    margin-top: 17px;

    @include for-mob-screen {
      width: 200px;
    }
    @include for-desktop-screen {
      width: 250px;
    }
    background: #3866df 0% 0% no-repeat padding-box;
    border-radius: 4px;
    text-align: left;
    font-size: 0.8rem;
    font-weight: bold;
    text-align: center;
    color: #ffffff;
    padding: 12px 20px;
    text-decoration: none;
    border: none;
  }
}
