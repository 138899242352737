@import "../../mixins.scss";
.cart-desktop {
  display: flex;
  justify-content: center;

  .title {
    font-size: 14px;
    font-weight: 700;
    color: $head-color;
    text-align: initial;
  }
  .data-change {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    text-align: start;
    background: #fbfdf4;
    color: #9898a1;
    font-size: 12px;
    padding: 10px 15px;
    font-weight: 600;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
    .dismiss {
      padding-left: 10px;
      color: #e6a309;
      padding-top: 15px;
      padding-bottom: 15px;
      text-align: start;
    }
    .dismiss-border {
      border-inline-start: 1px solid #fae4b2;
    }
  }
  .data-change {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    background: #fbfdf4;
    color: #9898a1;
    font-size: 12px;
    padding: 10px 15px;
    font-weight: 600;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
    .dismiss {
      padding-left: 10px;
      color: #e6a309;
      padding-top: 15px;
      padding-bottom: 15px;
    }
    .dismiss-border {
      border-left: 1px solid #fae4b2;
    }
  }

  .cart-data-state {
    width: 100%;
    max-width: $max-page-width;
    .orderitems-and-wishlist {
      display: flex;
      flex-direction: column;
      .cart-left {
        padding: 10px 30px 10px;

        .cart-items {
          font-size: 17px;
          font-weight: bold;
          text-align: left;
          color: #3f3f3f;
          opacity: 1;
          text-align: initial;

          .items-text {
            font-size: 14px;
            padding-left: 3px;
          }
        }

        .cart-product-image-area {
        
          background: #a5c7e3 0% 0% no-repeat;
          border: 1px solid #dee2e6;
        }

        .order-row {
          .remove-text {
            display: none;
          }

          h2 {
            display: none;
          }

          .currency-text-cart {
            font-size: 14px;
            color: #3f3f3f;
            margin-right: 2px;
          }

          .price-bold-text {
            font-size: 24px;
            font-weight: 700;
          }

          .buttons-desktop {
            margin-top: 50px;
            border: 0.8px solid rgb(179, 179, 179);

            .same-width {
              font-size: 14px;
              font-weight: bold;
              text-align: center;
              padding: 3px 15px;
              border-left: rgb(151, 151, 151) 1px solid;
              border-right: rgb(151, 151, 151) 1px solid;
            }
            button {
              color: rgb(151, 151, 151);
              width: 22px;
            }
            @media only screen and (max-width: 768px) {
              margin-top: 30px;
            }
          }
        }
        .cart-continue-shopping-button {
          padding: 10px 20px;
          font-size: 13px;
          font-weight: 700;
          text-align: center;
          color: #4575ee;
          background: #ffffff 0% 0% no-repeat padding-box;
          border: 1px solid #4575ee;
          border-radius: 5px;
          opacity: 1;
        }

        .remove-all-button {
          text-align: center;
          padding: 0px 10px 0px;
          width: 120px;
          font-size: 13px;
          font-weight: 700;
          color: #3f3f3f;
          opacity: 0.6;
          background: #ffffff 0% 0% no-repeat padding-box;
          border: 1px solid #3f3f3f;
          border-radius: 6px;
        }
      }
      .cart-wishlist {
        display: flex;
        justify-content: center;
        @include for-md-screen {
          display: none;
        }
        @include for-lg1-screen {
          display: flex;
        }
        margin-bottom: 20px;
        padding-bottom: 15px;
      }
    }
    .ordersummary-and-productarea {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      .cart-product-area {
        min-height: 200px;
        height: 250px;
        background: #a5c7e3 0% 0% no-repeat;
        border: 1px solid #dee2e6;

        opacity: 1;
        margin-top: 5px;
      }
      .cart-wishlist-md {
        display: flex;
        justify-content: center;
        justify-items: flex-end;
        width: 100%;
        @include for-md-screen {
          .cart-wishlist-container-desktop {
            padding-right: 0 !important;
          }
          .wishlist-list-container-desktop {
            padding-right: 0 !important;
          }
        }
        margin-bottom: 20px;
        padding-bottom: 15px;
        @include for-lg1-screen {
          display: none;
        }
        @include for-lg2-screen {
          display: none;
        }
        @include for-xl-screen {
          display: none;
        }
        @include for-xxl-screen {
          display: none;
        }
      }
    }
  }

  @media only screen and (max-width: 995px) {
    padding: 20px;
  }
}
