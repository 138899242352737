@import "../../../mixins.scss";
@import "../../../variables.scss";

.spro {
  @include for-mob-screen {
    padding-bottom: 0px !important;

  }
}

.hid {
  display: none !important;
}

.d-flex.flex-direction-column.header-div.mb-5 {
  @include for-mob-screen {
    margin-bottom: 15px !important;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 30px;
  }
}

.item-cmp-desktop {
  width: 100%;

  .carousel-indicators li {
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
    background-color: #f3f3f3;
    border: 1px solid #949494;
  }

  .carousel-indicators li.active {
    background-color: #949494;
    border: 1px solid #949494;
  }

  .top-video-div>iframe {
    width: 400px !important;
    height: 300px !important;
  }

  @include for-mob-screen {
    .small-screen-none {
      display: none !important;
    }

    .mob-image-div {
      height: 50vh;
    }

    .max-height-width {
      width: 300px;
      height: 357px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  @include for-desktop-screen {
    display: flex;
    flex-direction: row;

    .large-screen-none {
      display: none !important;
    }
  }

  .img-div {
    margin-left: 20px;
    margin-right: 40px;
    width: 500px;
    height: 450px;

    @include for-mob-screen {
      display: none !important;
    }
  }

  .text-div {
    width: 60%;
    // border: 1px solid #CCc;
    padding: 10px;
    // box-shadow: 0px 0px 8px 1px #cccccca6;
    border-radius: 5px;
    margin-right: 20px;
    height: fit-content;

    @include for-mob-screen {
      border: none;
      box-shadow: none;
      width: 100% !important;
    }

    .vendure-text {
      color: rgb(126, 133, 155);
      font-size: 14px;
      margin-bottom: 6px;

      @include for-mob-screen {
        font-size: 12px !important;
      }
    }

    .vendure-text {
      margin-bottom: 5px;
      font-size: 13px !important;
      color: #777;
      letter-spacing: .3px;

      @include for-mob-screen {
        font-size: 14px !important;
        color: #777;
        letter-spacing: 0.3px;
        margin-bottom: 0px;
      }
    }

    .item-title {
      color: rgb(64, 69, 83);
      font-weight: 600;
      font-size: 24px;
      margin-top: 5px;
      margin-bottom: 5px;
      letter-spacing: .3px;

      @include for-mob-screen {
        font-size: 16px !important;
      }
    }

    .prev-price {
      color: #777;
      font-size: 14px;
      display: flex;
      align-items: center;
      margin-bottom: 15px;
    }

    .price-row {
      border-top: 1px solid #D9D9D9;
      border-bottom: 1px solid #D9D9D9;

      div {
        padding: 16px;

        .price {
          font-size: 16px !important;
          font-weight: bold;
        }
      }

      .col1 {
        background-color: #FAFAFA;

        .price {
          color: #742013;
        }
      }

      .col2 {
        border-left: 1px solid #D9D9D9;
        border-right: 1px solid #D9D9D9;
      }
    }

    .old-price {
      margin-left: 10px;
      font-size: 12px;
    }

    .priceblock {
      margin-bottom: 0px;
    }

    .variatspace {
      margin-bottom: 7px !important;
    }

    .price-style {
      color: #000000;
      font-size: 19px !important;
      font-family: "Futura Heavy";

      @include for-mob-screen {
        font-size: 22px !important;
        font-family: 'Futura Heavy';
      }
    }

    .coupon-box {
      border-radius: 3px;
      background: #742013;
      color: white;
      width: fit-content;
      margin-bottom: 15px;

      p {
        font-size: 13px !important;
      }
    }

    .saving-style {
      color: #777777;
      font-size: 14px;
      margin-left: 5px !important;
    }

    .inclusive-style {
      color: #777;
      font-size: 13px;
      // padding-inline-start: 50px;
      letter-spacing: 0.3px;

      @include for-mob-screen {
        color: #b2b8ca !important;
        font-size: 12px !important;
      }
    }

    .rating-style {
      background-color: #742013;
      font-size: 12px;
      color: #fff;
      padding: 2px 12px;
      border-radius: 3px;
    }

    .rating-count {
      font-size: 12px;
      margin-inline-start: 12px;
      color: rgb(56, 102, 223);
      padding: 2px 0px;
      border-bottom: 1px solid rgb(56, 102, 223);
      cursor: pointer;

      &:hover {
        border: none;
        color: $head-color;
      }
    }

    .off-perc-style {
      background-color: #b12704 !important;
      font-size: 12px;
      color: #ffffff;
      border-radius: 2px;
      padding: 2px;
      margin-left: 12px;
      font-weight: 500;
      padding-left: 4px;
      padding-right: 4px;

      @include for-mob-screen {
        font-weight: bold;
        background-color: #B12704 !important;
        color: white;
        // float: right;
        margin-bottom: 20px;
        margin-left: 0px;
        padding-left: 10px;
        padding-right: 10px;
        padding: 5px;
        letter-spacing: 1px;
      }
    }

    span.seperator-line {
      margin: 5px;
      font-weight: 600;
    }

    .vendure-text123 a {
      color: rgb(0, 0, 0);
      font-size: 13px;
      font-weight: 600;
      letter-spacing: 0.2px;
    }

    .storename {
      text-transform: uppercase;
    }

    .variation-text {
      color: #777;
      font-size: 14px;
      letter-spacing: 0.3px;

      strong {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        font-weight: 600;
        color: rgb(0, 0, 0);
      }
    }

    .city-cont {
      margin-bottom: 20px;
      font-size: 12px;
      display: flex;
      width: 100%;
      flex-direction: row;
      flex-wrap: wrap;

      .simple-span {
        cursor: pointer;
        border: 1px solid #8080809e;
        text-align: center;
        vertical-align: baseline;
        border-radius: 4px;
        background-color: white;
        margin-inline-end: 10px;
        max-width: 45%;
        width: 100px;
        height: 100px;
        padding: 5px !important;
      }

      .selected-span {
        max-width: 45%;
        border: 2px solid #742013;
        text-align: center;
        vertical-align: baseline;
        border-radius: 4px;
        background-color: white;
        margin-inline-end: 10px;
        width: 100px;
        height: 100px;
        padding: 5px !important;
      }
    }

    .quantity-cont {
      width: 100%;

      .quantity-heading {
        font-size: 14px;
        color: #404553;
        font-weight: bold;
        margin-bottom: 12px;
      }

      .notify-me-btn {
        width: 100%;
        border: 1px solid rgb(160, 160, 190);
        border-radius: 5px;
        align-items: center;
        display: flex;
        padding: 8px;
        justify-content: center;
        cursor: pointer;
    
        .notify-me-btn-text {
          color: black;
          font-size: 18px;
          font-weight: bold;
        }
      }

      .quantity-body {
        @include for-lg1-screen {
          height: 30px;
        }

        @include for-lg2-screen {
          height: 30px;
        }

        @include for-md-screen {
          height: 40px;
        }

        .dropdown-cont {
          .dropdown-outer {
            width: 90%;

            .Dropdown-control {
              height: 100%;

              @include for-xxl-screen {
                padding: 8px 52px 8px 10px;
              }

              @include for-xl-screen {
                padding: 8px 52px 8px 10px;
              }

              @include for-lg2-screen {
                padding: 4px 40px 4px 10px;
              }

              @include for-lg1-screen {
                padding: 4px 40px 4px 10px;
              }

              @include for-md-screen {
                padding: 8px 52px 8px 10px;
              }

              .dropdownMenu {
                color: #404553;
                font-size: 14px;
                border-left: 1px solid #404553;
              }

              .dropdownPlaceholder {
                color: #404553;
                font-size: 14px;
              }
            }

            .Dropdown-arrow {
              @include for-xxl-screen {
                top: 14px;
              }

              @include for-xl-screen {
                top: 14px;
              }

              @include for-lg2-screen {
                top: 11px;
              }

              @include for-lg1-screen {
                top: 11px;
              }

              @include for-md-screen {
                top: 14px;
              }
            }
          }
        }

        .add-to-cont {
          margin: auto;
          margin-top: 10px;
          width: 100%;
          height: 40px;
          // margin-left: 2%;
          background-color: #742013;
          border-radius: 3px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;

          .add-to-cart-text {
            color: white;
            font-size: 14px;
            font-weight: bold;
          }
        }

        .get-inquiry-btn {
          width: 100%;
          margin-left: 2%;
          height: 45px;
          padding: 0px 10px;
          background-color: #4dc247;
          border-radius: 3px;
          display: flex;
          // justify-content: center;
          align-items: center;
          cursor: pointer;
          text-decoration: none;

          .add-to-cart-text {
            p {
              color: white;
              font-size: 15px !important;
              font-weight: 500;
              letter-spacing: 0.3px;
            }
          }
        }

        .out-of-stock {
          width: 100%;
          .add-to-cart-text {
            color: #dc3545;
            font-size: 20px;
            font-weight: bold;
          }
        }
      }

      .buy-now-btn {
        width: 100%;
        margin: auto;
        margin-top: 2%;
        height: 40px;
        background-color: #d4982b;
        border-radius: 3px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        .buy-now-text {
          color: white;
          font-size: 14px;
          font-weight: bold;
        }
      }

      .product-series {
        margin-top: 20px;
        color: #7f869a;
        font-size: 16px;

        .bold-text {
          color: #000000;
          font-size: 16px;
          margin-left: 5px;
          font-weight: 500;
        }
      }

      .like-cont {
        height: 100%;
        margin-left: 2%;
        display: flex;
        justify-content: center;
        cursor: pointer;

        .like-icon {
          @include for-xxl-screen {
            width: 40px;
            height: 40px;
          }

          @include for-xl-screen {
            width: 40px;
            height: 40px;
          }

          @include for-lg2-screen {
            width: 30px;
            height: 30px;
          }

          @include for-lg1-screen {
            width: 30px;
            height: 30px;
          }

          @include for-md-screen {
            width: 40px;
            height: 40px;
          }

          background: #00000029;
          border-radius: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }

  .image-gallery-modal {
    .cross-icon {
      position: absolute;
      top: 15px;
      right: 15px;
      z-index: 999;
    }

    .image-gallery {
      height: 100%;

      .image-gallery-content {
        height: 100%;

        .image-gallery-slide-wrapper {
          height: 83%;
          display: flex;
          align-items: center;
        }

        .image-gallery-thumbnails-wrapper {
          .image-gallery-thumbnails {
            .image-gallery-thumbnails-container {
              .image-gallery-thumbnail {
                border: 1px solid gray;
                margin: 5px;
              }

              .active {
                border: 2px solid #742013;
              }
            }
          }
        }
      }
    }
  }

  .custom-image-gallery {
    background-color: red;
  }
}

.service-modal {
  .continue-btn {
    margin: auto;
    margin-top: 10px;
    width: 100%;
    height: 40px;
    // margin-left: 2%;
    background-color: #742013;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    .add-to-cart-text {
      color: white;
      font-size: 14px;
      font-weight: bold;
    }
  }
}