.bottom-sheet {
  position: fixed;
  bottom: -100%;
  left: 0;
  width: 100%;
  background: white;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.2);
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  transition: bottom 0.3s ease-in-out;
  z-index: 10000;
  max-height: calc(100% - 200px);
  overflow: scroll;
}

.bottom-sheet::before {
  content: "";
  position: absolute;
  top: 12px;
  left: 50%;
  margin-left: -60px;
  width: 120px;
  height: 4px;
  border-radius: 4px;
  background-color: rgb(218, 220, 227);
}
  
.bottom-sheet.open {
  bottom: 0;
}
  
.bottom-sheet-content {
  padding: 30px 20px;
  display: grid;
  gap: 10px
}

.bottom-sheet-top {
  position: absolute;
  inset: 0px;
  opacity: 1;
  transition: all 0.1s ease 0s;
  background-color: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(4px);
}

.bottom-sheet-content h5 {
  position: sticky; 
  top: 0; 
  background: #fff; 
  z-index: 1000; 
  padding: 10px; 
  border-bottom: 1px solid rgb(226, 229, 241); 
}