.cart-order-summary-desktop {
  border-radius: 3px;
  margin-top: 27px;
  .pay-later-conitainer{
    display: none;
  }
  .suggestfree{
    background-color: #28a74540;
    text-align: center;
    color: #28a745;
    font-weight: 600;
    font-size: 0.85rem;
    letter-spacing: 0.5px;
    padding-top: 2px;
    padding-bottom: 2px;
    margin-bottom: 30px;
  }
  .summary-title {
    text-align: left;
    font-size: 16px;
    text-align: initial;
    line-height: 40px;
    color: #3f3f3f;
    opacity: 1;
  }
  .view-offers {
    border: 1px solid rgb(236, 235, 235);
    border-radius: 3px;
    padding: 8px !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    .view-title {
      display: flex;
      gap: 8px;
    }
  }

  .input-group {
    box-shadow: 0px 0px 2px #00000040;

    .input-field {
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: none;
      border: none !important;

      &:focus {
        box-shadow: none;
        border: none;
      }

      &::placeholder {
        text-align: left;
        font-size: 14px;
        color: #3f3f3f;
        opacity: 0.4;
        text-align: initial;
      }
    }

    .cart-apply-button {
      background: #d3af44 0% 0% no-repeat;
      border: 0px;
      text-align: left;
      font-size: 14px;
      width: 55px;
      color: #ffffff;
      padding: 9.7px 9.5px 9.5px 9.8px;
    }
    .cart-remove-button {
      background: #d3af44 0% 0% no-repeat;
      border: 0px;
      text-align: left;
      font-size: 14px;
      color: #ffffff;
      min-width: 60px;
      padding: 9px 7px;
    }
  }

  .cart-summary-text {
    text-align: left;
    font-size: 13px;
    color: rgb(111, 114, 126);
    line-height: 28px;
    margin-top: -9px;

    .price-text {
      color: #404553;
    }
  }

  .cart-border {
    border-top: 2px solid #ebebeb;

    .cart-total-text {
      text-align: right;
      font-size: 18px;
      font-weight: bold;
      color: #3f3f3f;
      line-height: 50px;
    }
  }
  .pay-later-conitainer {
    background: #fef7eb 0% 0% no-repeat padding-box;
    width: 98%;

    div {
      text-align: left;
      font-size: 12px;
      color: #3f3f3f;
    }

    .bank-icon {
      font-size: 28px;
      text-align: center;
      color: #9c9312;
    }
  }

  .cart-checkout-button {
    background: #742013 0% 0% no-repeat padding-box;
    border-radius: 4px;
    border: 0px;
    text-align: center;

    font-size: 16px;
    font-weight: bold;

    color: #ffffff;
    padding: 15px;
  }
}
