.shipping-page-main {
  .heading {
    margin-top: 50px;
    font-size: 25px;
    font-weight: bold;
    text-align: center;
  }

  .body {
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: 14px !important;
    margin-left: auto;
    margin-right: auto;
  }
}
