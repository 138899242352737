@import "../../mixins.scss";
@import "../../variables.scss";
.customer-returns {
  .header-row {
    padding: 27px 0px;

    .left {
      h3 {
        color: $head-color;
        font-size: $customer-page-header-size;
        font-weight: $customer-page-header-weight;
        margin-bottom: 0px;
      }
    }

    .right {
      .return-request {
        background-color: $form-btn-bg;
        color: rgb(255, 255, 255);
        padding: 13px 24px;
        font-size: $customer-page-subheading-size;
        font-weight: $customer-page-button-weight;
        text-transform: uppercase;
      }
    }
  }
  .modal-wrapper {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 10000;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .returns-data {
    background: #f0f3fa 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 6px #00000020;
    margin-bottom: 15px;
    border-radius: 4px;
    opacity: 1;
    .return-head {
      padding: 10px 15px;
      opacity: 1;
    }
    .return-head-text {
      font-size: $customer-address-heading-size;
      font-weight: $customer-page-subheading-weight;
      text-align: start;
      color: $head-color;
      opacity: 1;
    }

    .header {
      font-size: $customer-page-subheading-size;
      font-weight: $customer-page-subheading-weight;
    }

    .return-fade-text {
      text-align: start;
      font-size: $customer-page-subtext-size;
      font-weight: $customer-page-subtext-weight;

      color: $p-color;
      opacity: 1;

      svg {
        margin-right: 5px;
      }

      p {
        margin-bottom: 0px;
      }

      .credit-card {
        margin-top: 4px;
        margin-inline-end: 10px;
      }
    }

    .return-detail-box {
      background: #ffffff 0% 0% no-repeat padding-box;

      .first-box {
        padding-top: 15px;
        margin-left: 1px;
        margin-right: 1px;

        .return-details {
          padding-inline-start: 15px;
          padding-inline-end: 16px;
        }
      }

      .credit-card {
        color: #5caa32;
        opacity: 1;
        font-size: 2rem;
      }
    }

    .return-text {
      text-align: start;
      font-size: $customer-page-subtext-size;
      font-weight: 400;

      color: $head-color;
      opacity: 1;

      .font-weight {
        font-weight: 600;
      }
    }

    .product-returns-details {
      padding: 39px 15px 37px 15px;

      .image-col {
        width: 100px;
        height: 100px;
        display: flex;
        justify-content: center;
      }

      .description-col {
        padding-inline-start: 10px;
        text-align: start;
      }
      .rectangle {
        width: 120px;
        height: 40px;
        background: #e2f0dc 0% 0% no-repeat padding-box;
        border-radius: 3px;
        opacity: 1;
        .rectangle-text {
          padding: 10px 0px 0px;
          text-align: center;
          font-size: 17px;
          color: #64a063;
          opacity: 1;
        }
      }
      .tag-box {
        background: #feed00 0% 0% no-repeat padding-box;
        width: 100px;
        height: 30px;
        margin-top: 5px;
        border-top-left-radius: 25px;
        border-bottom-left-radius: 25px;
        border-bottom-right-radius: 25px;
        opacity: 1;
        .tag-text {
          text-align: center;
          font-size: 15px;
          color: $head-color;
          border: black;
          opacity: 1;
        }
      }
    }
  }
  .border-right {
    border-right: 1px solid rgb(218, 216, 216);
  }
  .border-left {
    border-left: 1px solid rgb(218, 216, 216);
  }
}
