.banner-sec img {
    width: 100%;
}

.dss-page-mob {
    background-color: white;

    .club1 {
        // background-color: #fcf9f2;
    }

    .club2 {
        // background-color: #e9f5ff;
    }

    .bigbox{
        border-radius: 10px;
        img{
            border-radius: 10px !important;
        }
    }

    .shopbycat {
        padding-top: 15px;
        color: #000000;
    }
    .shopbycat h3 {
        font-family: Futura Heavy;
        text-align: center;
        padding: 0px;
        margin: 0px;
        font-size: 24px;
    }
    .ViewAllbtn a {
        background-color: black;
        padding-top: 0.65rem!important;
        color: white;
        padding-bottom: 0.65rem!important;
        padding-left: 1.5rem!important;
        padding-right: 1.5rem!important;
        border-radius: 4px;
        font-size: 1.125rem;
    }
    .shopbycat h1 {
     
        text-align: center;
        padding: 0px;
        margin: 0px;
        font-size: 22px;
        font-family: futura heavy;
    }

    .row {
        margin: 0px;
    }

    .top-row {
        background-color: #fcf9f2;
    }

    .product-cont {
        display: flex;
        flex-direction: row;
        max-width: 95%;
        margin-left: auto;
        margin-right: auto;
        overflow-x: auto;
        overflow-y: hidden;

        .image-cont {
            width: 160px;
            max-width: 160px;
            margin-inline-end: 10px;
        }
    }

    .pagesbanner {
        padding-top: 15px;
        padding-bottom: 30px;
    }

    .topofferimg {
        padding: 10px;
        // width: 175px;
        // -webkit-transform: rotate(362deg);
    }

    .circlecat {
        width: 110px;
        height: 110px;
        border-radius: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .catbox:hover .circlecat {
        border: 2px solid #FFDE00;
    }

    .circlecat-paint {
        height: 140px;
        width: 140px;
        // background-color: white;
        border-radius: 50%;
        // border: 2px solid #FFDE00;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .shopbybrand .swiper-slide {
        padding: 5px;
        border-radius: 100%;
        border: 1px solid #ccc;
        text-align: -webkit-center;
        align-content: center;
    }

    .shopbybrand img {
        padding: 10px;
        height: 90px;
    }

    .catbox:hover .circlecat-paint {
        border: 7px solid #FFDE00;
    }

    .catbox {
        padding: 5px !important;
        text-align: -webkit-center;
    }

    .catbox img {}

    .catbox .cattxt {
        text-align: center;
        color: #005CAC;
        font-weight: 600;
        font-size: 12px;
        margin-top: 5px;
    }

    .surfacepat .product-list-desktop .product-list-container {

        background-color: #fcfaf4;
    }

    .row.catrow {
        margin-top: 20px;
    }

    .six6 img {
        border: none;
    }

    .titlewrap {
        background-color: #fcfaf4;
        padding-top: 15px;
        padding-bottom: 5px;
    }

    .padding-10 {
        padding: 10px !important;
    }

    .offerbg {
        background-color: #f8e89a;
        // border-bottom: 140px solid #f8e89a;
        // border-left: 23px solid transparent;
        // border-right: 14px solid transparent;
        border-radius: 5px;
        margin: 0px;
    }

    .shopbycat img {
        border: 1px solid #9e9e9e52;
    }

    .shopbycat .col-3 {
        padding: 5px;
    }

    .sectiontitle-individualpage {
        margin-bottom: 0px;
        text-align: center;
        color: #000000;
        font-weight: 600;
        font-size: 25px;
    }

    .titlewrap.noclr {
        background-color: #ffffff;
    }

    .offertitles {
        text-align: center;
        font-family: 'Futura heavy';
        padding-top: 10px;
        font-size: 18px;
        text-transform: uppercase;
        line-height: 0.9;
        /* -webkit-text-stroke: 1px white; */
        -webkit-text-stroke: thin;
    }

    span.offerprice {
        font-family: 'Futura heavy';
        margin-top: 29px;
        color: #ff5722;
    }

    .mypages-container {
        margin-right: auto !important;
        margin-left: auto !important;
        padding-left: 10px !important;
        padding-right: 10px !important;
        width: 1300px;
    }

    @media (min-width: 1360px) {
        .container {
            width: 1300px;
        }
    }

    @media (min-width: 1280px) {
        .container {
            width: 1220px;
        }
    }

    @media (min-width: 1170px) {
        .container {
            width: 1024px;
        }
    }

    @media (min-width: 992px) {
        .container {
            width: 970px;
        }
    }

    @media (min-width: 768px) {
        .container {
            width: 750px;
        }
    }
}