.all-categories-main-container {
  height: fit-content;
  .mobile-search-div {
    height: 6vh;

    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
  .bootom-main {
    width: 100%;
    display: flex;
    margin-top: 15px;
    .all-categories-left-div {
      height: 73vh;
      overflow: scroll;
      width: 25%;
      margin-right: 0;
      border-right: 1px solid #ece8e8;
      background-color: #d4982b2b;
    }
    .all-categories-right-div {
      width: 75%;
    }
  }
}
