.my-account-page-logged-out-mobile {
  max-width: 768px;
  border: 2px solid #e1e1e3;

  a {
    color: #212529;
    text-decoration: none;
  }

  .cross-icon {
    position: absolute;
    top: 15px;
    display: flex;
    justify-content: flex-end;
    color: #404553;
    background-color: #fff;
    padding-top: 5px;

    svg {
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  .english {
    left: unset;
    right: 15px;
  }

  .arabic {
    right: unset;
    left: 15px;
  }

  .logo-main {
    background-color: #fff;
    display: flex;
    justify-content: center;
    border-bottom: 2px solid #e1e1e3;
    .logo-cont {
      display: flex;
      justify-content: center;
      background-color: white;
      width: 160px;
      height: 32px;
      margin-bottom: 35px;
      .logo-size {
        margin-top: 20px;
        margin-bottom: 0px;
        width: 100%;
        height: 100%;
      }
    }
  }
  .auth-account {
    padding-top: 10px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: white;
    border-bottom: 2px solid #e1e1e3;
    height: 130px;

    .div-circle {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      background-color: #c78e37;
      width: 60px;
      height: 60px;
    }
    .sign-in {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .sign-up {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .auth-label {
      padding-top: 4px;
      color: #474c5a;
      font-weight: 500;
      text-align: center;
    }
  }
  .help {
  }

  .bottom-nav-comp {
    background-color: white;
    margin-top: 10px;
  }
}
