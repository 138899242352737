@import "../mixins.scss";

.verify-phone-number-page {
  width: 100%;
  padding: 0px;
  margin: 0px;

  .verify-phone-page-footer {
    width: 100%;

    margin-top: 50px;
  }
}
