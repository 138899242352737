@import "../../../mixins.scss";
@import "../../../variables.scss";
.header-desktop {
  padding-top: 27px;
  padding-bottom: 27px;
  .heading {
    text-align: start;
    font-size: $customer-page-header-size;
    font-weight: $customer-page-header-weight;
    color: $head-color;
  }

  .subheader {
    text-align: start;
    font-size: $customer-page-subtext-size;
    font-weight: $customer-page-subtext-weight;
    color: $p-color;
    margin-bottom: 0;
  }
}
.buttpon-address {
  background-color: $form-btn-bg;
  color: rgb(255, 255, 255);
  padding: 13px 24px;
  font-size: $customer-page-subheading-size;
  font-weight: $customer-page-button-weight;
  text-transform: uppercase;
  border: 0px;
  border-radius: 3px;
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.11);
}
.address-desktop {
  margin-bottom: 20px;

  .rectangle-head {
    background: #fbfaf3 0% 0% no-repeat padding-box;
    padding: 12px 10px 10px 10px;
    border: 1px solid rgb(241, 243, 249);

    div {
      text-align: start;
      font-size: $customer-page-subheading-size;
      font-weight: $customer-page-subheading-weight;
      color: $head-color;
    }
  }

  .rectangle {
    background: #ffffff 0% 0% no-repeat padding-box;
    padding: 25px 19px 25px 12px;
    border-top: 1px solid rgb(241, 243, 249);

    p {
      text-align: start;
      font-size: $customer-address-heading-size;
      font-weight: $customer-page-header-weight;
      color: $head-color;
      margin-bottom: 10px;
    }

    .head-text {
      text-align: start;
      font-size: $customer-page-subtext-size;
      font-weight: $customer-page-subtext-weight;
      color: $p-color;
    }
  }

  .info-text {
    text-align: start;
    font-size: $customer-page-subtext-size;
    font-weight: $customer-page-subtext-weight;
    color: $head-color;
  }
  .btn-cont {
    display: inline-flex;
    flex-direction: column;
    .set-default {
      margin: auto;
      width: 80px;
      padding-top: 5px;
      padding-bottom: 5px;
      background-color: $form-btn-bg;
      color: white;
      font-size: 11px;
      font-weight: 600;
      border-radius: 5px;
      text-align: center;
    }
    .delete {
      font-weight: bold;
      padding-top: 5px;
      padding-bottom: 5px;
      font-size: 11px;
      width: 80px;
      color: grey;
      border: 1px solid grey;
      border-radius: 5px;
      text-align: center;
    }
  }
}
