@import "../../../mixins.scss";

.returns-mobile {
  $black-text: #4b4b50;
  $gray: #84848e;
  $shadow: 0px 2px 6px #00000024;
  height: 100%;
  position: absolute;
  background-color: #fbfbfa;
  width: 100%;

  color: $black-text;

  .empty-returns-state {
    height: 50vh;

    .image-container {
      @include for-mob-screen {
        width: 60%;
      }
    }
    .description {
      color: #404553;
      font-weight: bold;
      font-size: 16px;

      @include for-mob-screen {
        font-size: 13px;
      }
    }
  }

  .item-padding {
    padding-bottom: 4rem;
  }

  .content {
    .active-tab {
      border-bottom: 3px solid #c78e2b;
    }

    .tabs {
      background-color: #ffffff;
      height: 50px;
      box-shadow: $shadow;

      .tab {
        width: 50%;
      }

      .bordered-right {
        border-right: 3px solid #f3f3f4;
      }
    }
  }

  .bottom {
    position: fixed;
    bottom: 55px;
    .new-request-btn {
      width: 93%;
      font-size: 14px;
      font-weight: bold;
      background-color: #c78e2b;
      border: none;
      color: white;
      padding: 15px 10px;
    }
  }
}
