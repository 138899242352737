@import "../../mixins.scss";
@import "../../variables.scss";

.cart-page-mobile-main {
  position: relative;
  background-color: white;

  .data-change {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    margin-bottom: 10px;
    background: #fbfdf4;
    color: #9898a1;
    font-size: 12px;
    padding: 10px 15px;
    font-weight: 600;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;

    .dismiss {
      padding-left: 10px;
      color: #e6a309;
      padding-top: 15px;
      padding-bottom: 15px;
    }

    .dismiss-border {
      border-left: 1px solid #fae4b2;
    }
  }

  .cartempty-card-area-mobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 75vh;

    .image-container {
      @include for-mob-screen {
        width: 60%;
      }
    }

    .text-container {
      text-align: center;

      .cart-head-text {
        color: #404553;
        font-weight: bold;
        font-size: 16px;

        @include for-mob-screen {
          font-size: 13px;
        }
      }

      .cart-sub-text {
        color: #404553;
        font-size: 15px;

        @include for-mob-screen {
          font-size: 13px;
        }
      }
    }

    .cart-continueshopping-button {
      margin-top: 4px;
      background: #3866df 0% 0% no-repeat padding-box;
      border-radius: 4px;
      text-align: left;
      font-size: 14px;
      font-weight: bold;
      color: #ffffff;
      padding: 10px 20px 10px 20px;
      text-decoration: none;
      border: none;
    }
  }

  .wrapper {
    background-color: "f9f9f9cc";
    height: calc(100vh - 182px);

    .cart-page-card-list {
      height: auto;
      position: relative;
      background-color: white;

      // border-top: 4px solid #f9f9f9cc;
      // padding-bottom: 10px;
      .order-summary {
        background-color: #f8f8f8;

        .title {
          padding: 10px 15px;
          font-size: 14px !important;
          font-weight: bold;
        }

        .summary {
          background-color: white;

          .sub-total-row {
            padding: 10px 15px;

            p {
              font-size: 13px !important;
            }
          }

          .coupon-section {
            display: flex;
            flex-direction: column;
            height: auto;

            .label-for {
              font-weight: 500;
            }

            .coupon-input-and-label {
              display: flex;
              flex-direction: row;
              justify-content: space-around;
              align-items: center;
              margin-left: auto;
              margin-right: auto;
              // margin-top: 35px;
              margin-bottom: 10px;
              height: 40px;
              background-color: white;
              width: 80%;
              border-radius: 4px;
              border: 1px solid $border-color;
              font-weight: bold;
              color: white;
              cursor: pointer;

              .coupon-input {
                border: none;
                background-color: white;
                display: flex;
                justify-self: flex-start;
                width: 70%;
                font-size: 14px;
                font-weight: 400;

                white-space: initial;
                padding-left: 5px;

                &::selection {
                  border: none;
                }

                &:focus {
                  border: none;
                  outline: none;
                }

                &::placeholder {
                  font-weight: 400;
                  font-size: 14px;
                  white-space: initial;
                  padding-left: 5px;
                }
              }

              .coupon-apply-buton {
                background-color: #fff;
                color: #4863b8;
                width: 80px;
                font-weight: bold;
                font-size: 14px;
                border: none;
              }
            }

            .enter-coupon-container-row-1 {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;

              .subtotal-items {
                font-size: 13px;
                font-weight: 400;
                color: $gray;

                .totalItems {
                  font-weight: 400;
                }
              }

              .totalprice {
                font-size: 13px;
                font-weight: 400;
                color: #404553;
              }
            }

            .enter-coupon-container-row-2 {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;

              .Deliver-to {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;

                .select-where-to-deliver {
                  width: 50%;
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;
                  margin-left: 5px;

                  .selector {
                    display: flex;
                    width: 120px;
                    height: 40px;
                    border-radius: 4;

                    .PrivateNotchedOutline-root-3 {
                      border: 2px solid #f9f9f9cc;
                    }

                    .MuiSelect-select {
                      :focus {
                        background-color: unset;
                        background: unset;
                      }
                    }
                  }
                }
              }
            }

            .enter-coupon-container-row-3 {
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: space-between;

              .Total-price-label {
                font-size: 13px;
                font-weight: bold;
                text-transform: capitalize;
                color: #404553;

                .totalItems {
                  font-size: 13px;
                  color: $gray;
                  font-weight: 400;
                }
              }

              .totalprice-ti {
                font-size: 13px;
                color: #404553;
                font-weight: bold;
              }
            }

            .first-row-for-carers-and-policy {
              display: flex;
              flex-direction: row;
              justify-content: space-around;
            }

            .second-row-forterms-of-use-policy-rights {
              display: flex;
              flex-direction: row;
              justify-content: space-around;
            }

            .bottom-svgs {
              margin-top: 30px;
              margin-bottom: 20px;
              bottom: 0;
              display: flex;
              flex-direction: row;
              justify-content: space-evenly;
            }
          }
        }
      }
    }

    .enter-coupon-container {
      background-color: #f1f4fd;
      padding-bottom: 72px;
      display: flex;
      flex-direction: column;
      height: auto;

      .label-for {
        font-weight: 500;
      }

      .coupon-input-and-label {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        margin-left: auto;
        margin-right: auto;
        margin-top: 35px;
        height: 40px;
        background-color: white;
        width: 80%;
        border-radius: 4px;
        border: 1px solid $border-color;
        font-weight: bold;
        color: white;
        cursor: pointer;

        .coupon-input {
          border: none;
          background-color: white;
          display: flex;
          justify-self: flex-start;
          width: 70%;
          font-size: 16px;
          font-weight: 400;

          white-space: initial;
          padding-left: 5px;

          &::selection {
            border: none;
          }

          &:focus {
            border: none;
            outline: none;
          }

          &::placeholder {
            font-weight: 400;
            font-size: 16px;
            white-space: initial;
            padding-left: 5px;
          }
        }

        .coupon-apply-buton {
          background-color: #fff;
          color: #4863b8;
          width: 80px;
          font-weight: bold;
          font-size: 14px;
          border: none;
        }
      }

      .enter-coupon-container-row-1 {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .subtotal-items {
          font-size: 13px;
          font-weight: 400;
          color: $gray;

          .totalItems {
            font-weight: 400;
          }
        }

        .totalprice {
          font-size: 13px;
          font-weight: 400;
          color: #404553;
        }
      }

      .enter-coupon-container-row-2 {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .Deliver-to {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;

          .select-where-to-deliver {
            width: 50%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-left: 5px;

            .selector {
              display: flex;
              width: 120px;
              height: 40px;
              border-radius: 4;

              .PrivateNotchedOutline-root-3 {
                border: 2px solid #f9f9f9cc;
              }

              .MuiSelect-select {
                :focus {
                  background-color: unset;
                  background: unset;
                }
              }
            }
          }
        }
      }

      .enter-coupon-container-row-3 {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .Total-price-label {
          font-size: 13px;
          font-weight: bold;
          text-transform: capitalize;
          color: #404553;

          .totalItems {
            font-size: 13px;
            color: $gray;
            font-weight: 400;
          }
        }

        .totalprice-ti {
          font-size: 13px;
          color: #404553;
          font-weight: bold;
        }
      }

      .first-row-for-carers-and-policy {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
      }

      .second-row-forterms-of-use-policy-rights {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
      }

      .bottom-svgs {
        margin-top: 30px;
        margin-bottom: 20px;
        bottom: 0;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
      }
    }
  }
}

.button-main {
  width: 100%;
  position: fixed;
  z-index: 1;
  left: 0;
  bottom: 50px;
  background-color: #fff;

  .bottom-button {
    margin-right: 10px;
    margin-left: 10px;
    margin-top: 10px;
    margin: 10px;

    .buy-button {
      width: 98%;
      font-size: 0.95rem;
      background-color: $form-btn-bg;
      border: none;
      color: white;
      font-weight: 700;
      padding: 15px 10px;
    }
  }
}