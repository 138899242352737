@import "../mixins.scss";

.country-popup {
  padding-left: 20px;
  padding-right: 10px;
  padding-top: 10px;
  .close {
  }
  .heading {
    color: #8184a0;
    font-size: 22px;
    font-weight: bold;
  }

  .country_cont {
    padding: 20px 0px;
    border-bottom: 1px solid rgb(243, 244, 248);

    .country_name {
      color: #7e859b;
      font-size: 14px;
      margin-inline-start: 15px;
    }
    .active-country {
      color: #404553;
      font-weight: 600;
      font-size: 14px;
      margin-inline-start: 15px;
    }
  }
}
