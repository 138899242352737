@import "../mixins.scss";

.category-container {
  position: relative;
  margin-top: 25px;
  text-align: initial;

  ul {
    padding: 0px;
    padding-inline-start: 15px;
    margin: 0px;

    @include for-768-screen {
      padding: 0px;
      padding-inline-start: 5px;
    }

    li {
      list-style: none;

      a {
        display: inline-block;
        padding-bottom: 5px;
        text-decoration: none !important;
        font-size: 13px;
        font-weight: 400;
        color: #757474;
      }
    }
  }

  .individual-category-img-container {
    width: 50px;
    height: 50px;
    position: absolute;
    right: 5px;
    top: -15px;
    display: flex;
    justify-content: center;

    img {
      height: auto;
      width: 100%;
    }
  }

  .english {
    position: absolute;
    right: 15px;
    top: -15px;
    left: unset;
  }

  .arabic {
    position: absolute;
    right: unset;
    top: -15px;

    left: 15px;
  }

  .header-title {
    font-size: 13px;
    font-weight: 700;

    color: #3f3f3f;
    margin-bottom: 18px;
    padding-inline-start: 15px;
    margin-inline-end: 80px;

    @include for-768-screen {
      padding-inline-start: 5px;
    }
  }
}
