@import "../../mixins";
@import "../../variables";

.wishlist-mobile-card:last-child {
  border-bottom: 1px solid #D9D9D9;
}

.wishlist-mobile-card {
  padding: 20px 0px;
  background-color: white;
  $black-text: #4b4b50;
  $gray: #84848e;
  border-top: 1px solid #D9D9D9;

  .MuiInputBase-root {
    font-size: 13px;
  }

  .date-heading {
    font-size: 14px;
    color: #404553;
  }

  .form-control {
    height: 0px;
    padding: 0.7rem 0.75rem;
    font-size: 13px;
  }

  .striked {
    text-decoration: line-through;
  }

  .date-style {
    font-size: 13px;
  }

  .fs-size {
    font-size: 11px;
  }

  .wishlist-mobile-brand {
    text-align: start;
    color: $gray;
  }

  .wishlist-mobile-wrapper {
    .left {
      width: 20%;

      .image-container {
        width: 100%;
        height: 80px;
        overflow: hidden;

        img {
          width: auto;
        }
      }
    }

    .right {
      width: 75%;
      text-align: start;

      .title {
        font-weight: 400;
        font-size: 0.75rem;
        color: $black-text;
        line-height: 0.75rem;
        font-weight: 700;
      }

      .price-strike-through {
        font-weight: 400;
        font-size: 0.875rem;
        text-decoration: line-through;
      }

      .price {
        font-weight: 700;
        font-size: 1rem;
        color: #404553;
      }

      .dicount-price {
        font-weight: 400;
        font-size: 13px;
        color: #404553;
      }

      .quantity {
        color: $gray;
      }

      .store-name {
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
        color: #8f8f8f;

        .sold-by {
          font-weight: 700;
          font-size: 13px;
          color: $head-color;
        }
      }

      .end-margin {
        margin-inline-end: 14px;
      }
    }

    .bottom-row {
      .left-bottom {
        width: 23%;

        .quantiy-select {
          width: 100%;

          .control__control {
            outline: none;
            box-shadow: none;
            border-color: #70707033;

            .control__value-container {
              padding-top: 0px;
              padding-bottom: 0px;
            }

            .control__indicators {
              .control__indicator-separator {
                display: none;
              }

              .control__indicator {
                svg {
                  color: $gray;
                  width: 17px;
                  height: 17px;
                }
              }
            }
          }

          .control__menu-list {
            height: 150px;
          }
        }

        .cart-update-row {
          height: 27px;
          border-radius: 5px;
          display: flex;
          align-items: center;

          .plus-minus-btn {
            background-color: #f3f4f8;
            height: 100%;
            cursor: pointer;
          }

          .quantity {
            height: 100%;
            border: 1px solid #d9d9d9;
          }
        }
      }

      .small-text {
        font-size: 13px;
        color: gray;
        padding-inline-start: 3px;
      }

      .small-text-ar {
        font-size: 12px;
        color: gray;
        padding-inline-start: 3px;
      }

      .icon {
        color: grey;
      }

      .right-bottom {
        width: 77%;

        .left-button {
          width: fit-content;
          min-width: 83px;

          .inner-left-button {
            height: 27px;
            border: 0;
            border: 1px solid #d9d9d9;
            border-radius: 5px;
            margin-left: 10px;
          }
        }

        .right-button {
          width: fit-content;
          min-width: 83px;
          margin-left: 5px;

          .inner-right-button {
            height: 27px;
            border: 0;
            border: 1px solid #d9d9d9;
            border-radius: 5px;
          }
        }

        .right-button-ar {
          width: fit-content;
          min-width: 83px;
          margin-right: 5px;

          .inner-right-button {
            height: 27px;
            border: 0;
            border: 1px solid #d9d9d9;
            border-radius: 5px;
          }
        }
      }
    }
  }
}