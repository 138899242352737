@import "../../../mixins.scss";

.categories-side-panel-mobile {
  width: 100%;
  .float-left {
    float: left;
  }
  .float-right {
    float: right;
  }

  .text-cont {
    height: 60px;
    border-bottom: 1px solid #ece8e8;
    display: flex;
    justify-content: center;
    align-items: center;

    .text {
      font-size: 10px;
      font-weight: 700;
      width: 85%;
      letter-spacing: 0.2px;
      text-align: center;
    }
    .active-text {
      color: #545860 !important;
      font-weight: 900 !important;
    }
  }

  .active-style {
    background-color: white;
    border-bottom: none;
    border-left: 2px solid #545860;
  }
}
