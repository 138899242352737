@import "../../../variables.scss";
@import "../../../mixins.scss";

.order-header {
  .small-screen-none {
    @include for-mob-screen {
      display: none !important;
    }
  }

  .large-screen-none {
    @include for-desktop-screen {
      display: none !important;
    }
  }

  @include for-mob-screen {
    background: #fff;
    border-radius: 0px;
  }

  $section-title-font-size: 0.8rem;
  $order-number-font-size: 0.8rem;
  $order-address-font-size: 0.8rem;
  $order-mobile-font-size: 0.8rem;
  border-radius: 4px;
  box-shadow: rgb(173, 193, 207) 0px 0.2px 3px 0px;

  .title {
    font-size: $section-title-font-size;
    font-weight: 700;

    @include for-mob-screen {
      display: flex;
      align-items: center;
      margin-top: 15px;
    }
  }

  .wrapper {
    border-bottom: 1px solid rgb(234, 236, 241);
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    .order-number {
      font-size: $order-number-font-size;
      font-weight: 700;
    }

    .placed-on {
      font-size: 0.8rem;
      font-weight: 500;
    }
  }

  .first {
    border-bottom: 1px solid rgb(234, 236, 241);

    @include for-mob-screen {
      display: flex !important;
      flex-direction: column !important;
      justify-content: center !important;
      align-items: center !important;
    }
  }

  .second {
    padding: 0px !important;
    .left {
      border-inline-end: 1px solid rgb(234, 236, 241);

      @include for-mob-screen {
        border-inline-end: none #a7a6a6;
      }
    }

    .name {
      font-size: 0.9rem;

      @include for-mob-screen {
        font-size: 13px;
        color: #7e869b;
        font-weight: 500;
        margin-inline-start: 27px;
      }
    }

    .mobile-address-style {
      @include for-mob-screen {
        font-size: 13px !important;
        color: #7e869b !important;
        margin-left: 27px !important;
      }
    }

    .address {
      font-size: $order-address-font-size;
      font-weight: 500;
    }

    .right {
      @include for-mob-screen {
        margin-top: 10px;
        border-top: 1px solid rgb(234, 236, 241);
      }

      .mobile-number {
        font-size: 0.8rem;
        margin-inline-start: 24px;
        padding-inline-end: 5px;
        text-align: start;

        @include for-mob-screen {
          margin-inline-start: 20px;
        }
      }
      .verified-mark {
        font-size: 0.8rem;
      }
    }
  }

  .green-checkbox {
    color: $green;
    font-size: $order-mobile-font-size;
    margin-inline-end: 3px;
  }

  .caution {
    color: rgb(207, 132, 20);
    font-size: $order-mobile-font-size;
  }

  .review-order-btn {
    text-align: center;
    cursor: pointer;
    background: #f2f6ff 0% 0% no-repeat padding-box;
    color: #567de3;
    border: 1px solid #567de3;

    @include for-mob-screen {
      text-align: center;
      font-size: 13px;
      font-weight: 500;
    }
  }
  .cancle-order-btn {
    text-align: center;
    cursor: pointer;
    background: #f2f6ff 0% 0% no-repeat padding-box;
    color: #742013;
    border: 1px solid #742013;

    @include for-mob-screen {
      text-align: center;
      font-size: 13px;
      font-weight: 500;
    }
  }
}
