@import "../mixins.scss";

.verify-phone-desktop {
  width: 100%;
  background-color: #f7f7fa;
  max-width: 1440px;
  padding: 0px 45px;

  @include for-md-screen {
    padding: 0px 30px;
  }
  @include for-lg1-screen {
    padding: 0px 45px;
  }

  .verify-phone-row {
    .heading {
      width: 100%;

      .header-col {
        margin: 30px 0px 25px 0px;
        padding: 0px;

        .label {
          font-size: 2.04rem;
          font-weight: 700;
          color: $head-color;
        }
      }

      .phone-verify-col {
        background-color: rgb(255, 255, 255);
        padding: 25px;
        border-radius: 2px;
        border: 1px solid rgb(226, 229, 241);
        text-align: center;

        .otp-verification {
          .otp-header {
            margin: 25px 0px 12px;
            font-size: 1rem;
            font-weight: 700;
            color: #3f3f3f;
            text-align: center;

            @include for-md-screen {
              margin: 0px 0px 15px;
            }

            @include for-lg1-screen {
              margin: 25px 0px 12px;
            }
          }

          .otp-text {
            font-size: $customer-page-subheading-size;
            font-weight: $customer-page-subtext-weight;
            color: $head-color;
            text-align: center;
            padding: 0px 300px;

            @include for-md-screen {
              padding: 0px 100px;
            }

            @include for-lg1-screen {
              padding: 0px 300px;
            }
          }

          .otp-box {
            direction: ltr;
            margin-top: 40px;
            margin-bottom: 25px;
            .inputStyleClass {
              width: 47px !important;
              height: 47px !important;
              margin-inline-end: 14px;
              &:last-child {
                margin-inline-end: 0px;
              }
            }
          }

          .otp-verify-button {
            margin-bottom: 50px;

            .button-ltr {
              padding: 17.5px 40px;
              color: #ffffff;
              font-size: $customer-page-subheading-size;
              font-weight: 700;
              background-color: #d3af44;
              border: none;
              border-radius: 2px;
              min-width: 250px;
            }
            .button-rtl {
              padding: 17.5px 40px;
              color: #ffffff;
              font-size: 1rem;
              font-weight: 700;
              background-color: #d3af44;
              border: none;
              border-radius: 2px;
              min-width: 250px;
            }
          }
        }

        .link-row {
          border-top: 1px solid $border-color;
          padding-top: 20px;

          .change-number {
            color: $blue-header;
            text-decoration: underline;
            font-size: $customer-page-subheading-size;
            font-weight: $customer-page-subtext-weight;
          }

          .resend-otp {
            color: $head-color;
            font-size: $customer-page-subheading-size;
            font-weight: $customer-page-subtext-weight;
            vertical-align: baseline;

            a {
              text-decoration: underline;
              color: $blue-header;
            }
          }
        }
      }
    }
  }
}
