@import "../../../../variables.scss";

.payment-method {
  $font-size: 0.85rem;
  text-align: initial;

  .title {
    font-size: $font-size;
    font-weight: 700;
  }

  .logo-container {
    width: 25px;
    height: 25px;
    max-width: 25px;
    max-height: 25px;

    img {
      height: auto;
      width: 100%;
    }
  }
  .logo-container-visa {
    width: 45px;
    height: 25px;
    max-width: 45px;
    max-height: 25px;

    img {
      height: auto;
      width: 100%;
    }
  }
  .card-number {
    font-size: $font-size;
  }

  .method-name {
    color: $green;
    font-size: $font-size;
  }
}
