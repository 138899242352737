@import "../../mixins.scss";
.suggestfree{
  background-color: #28a74540;
  text-align: center;
  color: #28a745;
  font-weight: 600;
  font-size: 0.85rem;
  letter-spacing: 0.5px;
  padding-top: 2px;
  padding-bottom: 2px;
  margin-bottom: 10px;
}
.data-change {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: row;
  background: #fbfdf4;
  color: #9898a1;
  font-size: 9px;
  padding: 10px 15px;
  font-weight: 600;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  .dismiss {
    padding-left: 10px;
    color: #e6a309;
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .dismiss-border {
    border-left: 1px solid #fae4b2;
  }
}
.cart-sidebar-without-translation-desktop {
  position: fixed;
  top: 78px;
  bottom: 0;
  right: 0;
  width: 0;
  transform: translateX(100%);
  transition: 0.3s ease-out;
  font-size: 0.875rem;

  .panel {
    position: absolute;
    top: 78px;
    bottom: 0;
    left: 0;
    right: 0;
    background: white;
    color: #eee;
    overflow: hidden;
    height: auto;
    padding-top: 5px;
    padding-bottom: 5px;
    hr {
      margin: 0px;
    }
    .bottom-container {
      color: black;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      font-size: 0.875rem;
      height: 125px;
      .upper-container {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        font-weight: bold;
      }
      .bottom-buttons {
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding-left: 15px;
        padding-right: 15px;

        .sidebar-viewcart-button {
          background-color: #ffffff;
          border-radius: 4px;
          border: 1px solid #70707057;
          text-align: center;
          font-size: 0.875rem;
          color: #d3af44;
          width: 170px;
          height: 40px;
          margin-inline-end: 10px;
        }
        .sidebar-checkout-button {
          background: #d3af44 0% 0% no-repeat padding-box;
          border-radius: 4px;
          border: 0px;
          text-align: center;
          font-size: 0.875rem;
          color: #ffffff;
          width: 170px;
          height: 40px;
          margin-inline-start: 10px;
        }
      }
    }
  }
}

.cart-sidebar-with-translation-desktop {
  max-width: 346px;
  position: fixed;
  top: 0;
  bottom: 0;
  border-left: 1px solid #70707057;
  border-right: 1px solid #70707057;
  border-bottom: 1px solid #70707057;
  right: 0;
  width: 30em;
  background: #fff;
  transition: 0.3s ease-out;
  font-size: 0.875rem;
  transform: translateX(0%);
  box-shadow: #00000038 0px 5px 4px 0px;
  z-index: 100000;

  .panel {
    position: absolute;
    background: white;
    color: #eee;
    height: 100%;
    padding-bottom: 5px;
    hr {
      margin: 0px;
    }
    .rdtDays {
      margin-right: 10px !important;
    }
    .bottom-container {
      color: black;
      display: flex;
      flex-direction: column;
      font-size: 0.875rem;
      height: 125px;
      padding-top: 0.8rem;
      .upper-container {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        font-weight: bold;
        padding-bottom: 10px;
      }
      .bottom-buttons {
        display: flex;
        flex-direction: row;
        justify-content: center;
        .sidebar-viewcart-button {
          background-color: #ffffff;
          border-radius: 4px;
          border: 1px solid #70707057;
          text-align: center;
          font-size: 0.71rem;
          color: #d3af44;
          width: 150px;
          height: 35px;
        }
        .sidebar-checkout-button {
          background: #d3af44 0% 0% no-repeat padding-box;
          border-radius: 4px;
          border: 0px;
          text-align: center;
          font-size: 0.71rem;
          color: #ffffff;
          width: 150px;
          height: 35px;
          margin-inline-start: 10px;
        }
      }
    }
  }
}
