.social-links-and-terms {
  background-color: #f6f9ff;
  padding-bottom: 50px;
  .social-icons {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 80px;
    .inner-social {
      width: 70%;
      margin-left: 40px;
      margin-right: 40px;
      display: flex;
      justify-content: space-evenly;
    }
  }
  .terms-policies-all-rights {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #9a9ca7;
    opacity: 0.8;
    font-weight: 550;
    .terms-first-row {
      display: flex;
      flex-direction: row;
      width: 85%;
      justify-content: space-around;
      margin: 20px 0 10px;

      .terms-of-use {
        font-size: 12px;
      }
      .terms-of-sale {
        font-size: 12px;
      }
      .privacy-policy {
        font-size: 12px;
      }
    }
    .policy-second-row {
      display: flex;
      flex-direction: row;
      width: 60%;
      justify-content: space-around;
      margin: 10px 0 10px;
      .warranty-policy {
        font-size: 12px;
      }
      .return-policy {
        font-size: 12px;
      }
    }
    .version-3rd-row {
      display: flex;
      flex-direction: row;
      width: 70%;
      justify-content: space-around;
      margin: 10px 0 10px;
      opacity: 0.6;
      font-size: 12px;
      font-weight: normal;
    }
    .last-row {
      display: flex;
      flex-direction: row;
      width: 70%;
      justify-content: space-around;
      margin: 10px 0 10px;

      .rights-reserved {
        font-size: 12px;
      }
    }
  }
}
