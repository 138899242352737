@import "../mixins.scss";

.product-category-list-container {
  @include width-and-margin-bottom(95%, 0px);
  @include flex(row, wrap, space-between, center);
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;

  .swiper-container .swiper-wrapper .swiper-slide {
    height: auto;

    .product-category-container {
      height: auto;
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    border-radius: 50%;
    background: rgba(192, 143, 143, 0.4);
    height: 40px;
    width: 40px;
    color: black;
  }

  .swiper-button-next.swiper-button-disabled,
  .swiper-button-prev.swiper-button-disabled {
    display: none !important;
    cursor: auto;
    pointer-events: none;
  }

  .swiper-button-next:after,
  .swiper-button-prev:after {
    font-size: 1.5rem;
  }

  .swiper-pagination {
    display: none;
  }
}
