@import "../../../mixins.scss";
@import "../../../variables.scss";

@media (min-width: 993px) {
  .fs-lg-15 {
    font-size: 15px;
  }

  .fs-lg-14 {
    font-size: 14px;
  }

  .fs-lg-12 {
    font-size: 12px;
  }
}

.cursor-class {
  cursor: pointer;
}

@include for-md-screen {
  .fs-sm-10 {
    font-size: 10px;
  }

  .fs-sm-11 {
    font-size: 11px;
  }
}

.item-header-desktop {
  width: 100%;

  .small-screen-none {
    @include for-mob-screen {
      display: none !important;
    }
  }

  .background-white-sm {
    @include for-mob-screen {
      background: #fff;
    }
  }

  .header-div {
    .text-style {
      color: #858585;
      font-size: 16px;
      cursor: pointer;
      font-weight: 700;
      padding-bottom: 10px;

      &:hover {
        color: #404553;
      }

      margin-inline-start: 25px;

      @include for-md-screen {
        font-size: 14px;
      }

      @include for-lg1-screen {
        font-size: 14px;
      }

      @include for-lg2-screen {
        font-size: 14px;
      }
    }

    .active-tab {
      font-weight: 700;
      color: #d4982b;
      border-bottom: 3px solid #d4982b;
      font-size: 16px;
      cursor: pointer;
      margin-inline-start: 25px;
      padding-bottom: 10px;

      @include for-md-screen {
        font-size: 14px;
      }

      @include for-lg1-screen {
        font-size: 14px;
      }

      @include for-lg2-screen {
        font-size: 14px;
      }
    }
  }

  .clear-all-sm {
    background-color: rgb(235, 236, 240);
    color: rgb(64, 69, 83);
    border-radius: 2px;
    font-size: 12px;
    font-weight: 700;
    padding: 8px 26px 5px 10px;
    margin-top: 12px;

    text-transform: capitalize;
  }

  .filter-text-sm {
    margin-inline-start: 6px;
    background-color: rgb(235, 236, 240);
    color: rgb(64, 69, 83);
    border-radius: 2px;
    font-size: 12px;
    font-weight: 700;
    padding: 8px 10px;
  }

  .large-screen-none {
    @include for-desktop-screen {
      display: none !important;
    }
  }

  .top-header {
    .child-div-one {
      text-align: left;
      font-weight: bold;
      margin-top: auto;
      margin-bottom: auto;
    }

    .child-div-two {
      margin-top: auto;
      margin-bottom: auto;

      .child-text {
        text-align: left;
        font-weight: bold;
        color: #b2bbd2;
      }

      .dropdown-cont {
        box-shadow: 0.5px 0.5px $gray;

        @include for-md-screen {
          width: 120px;
        }
      }

      .Dropdown-control {
        cursor: pointer;
        border: 1px solid rgb(64, 69, 83);
        padding: 6px 53px 6px 10px;
      }

      .border-shadow {
        box-shadow: 0.75px 0.75px rgb(64, 69, 83);
      }

      @include for-md-screen {
        .dropdownPlaceholder {
          font-size: 8px;
          color: #404553;
          text-transform: uppercase;
          font-weight: bold;
        }

        .Dropdown-arrow {
          top: 10px;
        }
      }

      @include for-lg1-screen {
        .dropdownPlaceholder {
          font-size: 11px;
          color: #404553;
          text-transform: uppercase;
          font-weight: bold;
        }

        .Dropdown-arrow {
          top: 12px;
        }
      }

      @include for-lg2-screen {
        .dropdownPlaceholder {
          font-size: 11px;
          color: #404553;
          text-transform: uppercase;
          font-weight: bold;
        }

        .Dropdown-arrow {
          top: 12px;
        }
      }

      @include for-xl-screen {
        .dropdownPlaceholder {
          font-size: 12px;
          color: #404553;
          text-transform: uppercase;
          font-weight: bold;
        }

        .Dropdown-arrow {
          top: 12px;
        }
      }

      @include for-xxl-screen {
        .dropdownPlaceholder {
          font-size: 12px;
          color: #404553;
          text-transform: uppercase;
          font-weight: bold;
        }

        .Dropdown-arrow {
          top: 12px;
        }
      }
    }
  }

  .bottom-header {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;

    @include for-desktop-screen {
      margin-top: 50px;
      text-align: start;
      margin-inline-start: 8px;
    }

    @include for-mob-screen {
      padding: 0px 10px 20px 10px;
      border-top: 1px solid rgb(241, 243, 249);
    }

    .filter-text {
      text-align: left;
      font-weight: bold;
      color: #000000;
      opacity: 0.8;
    }

    .filtered {
      text-align: left;
      color: #000000;
      opacity: 1;
    }

    .filteredDiv {
      @include for-mob-screen {
        margin-top: 10px;
      }

      @include for-desktop-screen {
        margin-top: 4px;
        background-color: #fff;
        padding: 5px 15px;
        margin-left: 10px;
      }
    }

    .filteredDiv-ar {
      @include for-desktop-screen {
        margin-left: 0px;
        margin-right: 10px;
      }
    }

    .filterCloseButton {
      padding-left: 5px;
    }

    .filterCloseButton-ar {
      padding-left: 0px;
      padding-right: 5px;
    }

    .filter-clear-text {
      margin-left: 10px;
      text-align: left;
      font-weight: bold;
      color: #3b6adc;
      opacity: 1;
    }

    .filter-clear-text-ar {
      margin-left: 0px;
      margin-right: 10px;
    }
  }
}

.item-header-mobile {
  .categories-layout {
    display: flex;
    overflow: scroll;
    background-color: white;
    padding: 10px;
    border-bottom: 1px solid #cccccc6b;

    a:first-child {
      border: 1px solid #742013;
    }
  }

  .category-box {
    margin-left: 5px;
    margin-right: 5px;
    display: block;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 3px;
    background-color: #f0f2f7;
    white-space: nowrap;
  }

  .category-box p {
    margin: 0px;
    color: #404553;
    font-size: 14px!important;
  }

  .category-box:hover {
    text-decoration: none;
  }

  @include for-mob-screen {
    margin-left: auto;
    margin-right: auto;
  }

  .main-item-heading {
    @include for-mob-screen {
      padding: 0px 10px;
      color: #404553;
      font-size: 18px;
      font-weight: 650;
      text-align: center;
    }

    @include for-desktop-screen {
      display: none;
    }
  }

  .main-item-body {
    @include for-desktop-screen {
      display: none !important;
    }

    @include for-mob-screen {
      padding: 0px 10px;
      background: #fff;
      display: flex;
      flex-direction: row;
      border: 1px solid #cccccc6b;

      .filter-cont {
        padding: 12px 0px;
        width: 50%;

        .text {
          color: #404553;
          font-size: 11px;
          font-weight: 550;
        }

        .img-cont {
          margin-left: 10px;
          height: 20px;
          width: 20px;
        }
      }
    }
  }
}

.sort-by-modal {
  @include for-mob-screen {
    padding: 12px 0px;
    color: #7e859b;
    font-size: 14px;

    .active-text {
      color: #404553;
      font-weight: 600;
    }
  }
}

.modal-dialog {
  @include for-mob-screen {
    margin: 0px !important;
  }
}

.modal-content {
  @include for-mob-screen {
    border: none !important;
    border-radius: 0px !important;
    overflow-y: auto;
  }
}