@import "../../assets/styles/mixins.scss";
@import "../../assets/styles/variables.scss";

.mt-70 {
  margin-top: 180px;
}
.mt-50 {
  margin-top: 60px;
}

.modal-body {
  padding: 0;
}

.create-modal {
  border-radius: 4px;

  .header-row {
    background-color: #f1f6f8;
    padding: 10px;
    text-align: center;
  }

  .boday-row {
    background-color: white;

    .input-group-row {
      padding: 30px 30px 10px;

      .input-row {
        margin-bottom: 15px;

        .label {
          width: 30%;
          font-size: 1rem;
          font-weight: 700;
          color: $head-color;
          text-align: initial;
        }

        .value {
          width: 70%;
          font-size: 1rem;
          font-weight: 600;
          color: $p-color;
          text-align: initial;

          textarea {
            border: 1px solid $border-color;
            outline: none;
            box-shadow: none;
            border-radius: 3px;
            width: 100%;
            text-align: initial;
          }

          .filter {
            width: 200px;

            .control__control {
              border-radius: 4px;
              box-shadow: 0px 0px 1px #00000029;
              border: 1px solid $border-color;
              color: gray;

              .control__value-container {
                .control__single-value {
                  color: gray;
                }
              }

              .control__indicators {
                .control__indicator-separator {
                  margin: 0;
                }

                .control__indicator {
                  color: gray;
                }
              }
            }
          }
        }
      }

      .description-row {
        align-items: flex-start !important;
      }
    }

    .buttons-row {
      .cancel-button {
        width: 50%;
        height: 45px;
        padding: 10px;
        text-align: center;
        font-size: 1rem;
        font-weight: 400;
        color: $p-color;
        border: none;
        outline: none;
        box-shadow: none;
        border-top: 1px solid $border-color;
        background-color: white;
      }

      .create-button {
        width: 50%;
        height: 49px;
        padding: 10px;
        text-align: center;
        font-size: 1rem;
        font-weight: 700;
        color: $p-color;
        border: none;
        outline: none;
        box-shadow: none;
        border-top: 1px solid #c78e2b;
        background-color: #c78e2b;
        border-radius: 0px;
        color: white;
      }
    }
  }

  .error {
    color: red;
    font-size: 13px;
    margin-top: 3px;
  }
}
