@import "../mixins.scss";
.sale {
    max-width: 1440px;
    margin: auto;
.cat-11 {

.col-lg-3.my-1{
    margin-bottom: 40px!important;
    
}
.col-lg-12 h3 {
    font-family: 'Futura Heavy';
    font-size: 39px;
    padding-top: 45px;
    padding-bottom: 25px;

}

}
.cat-11-mob{
.col-lg-12 h4 {
    font-family: 'Futura Heavy';
    font-size: 25px;
    padding-top: 45px;
    padding-bottom: 25px;

}
}
    .product-list-row {
        margin-bottom: 50px;
        justify-content: center;
        padding: 0px 15px;

        .header {
            margin-bottom: 0px;
            font-weight: 700;
            color: #7d252a;
            opacity: 1;
            text-transform: capitalize;
            width: 100%;
            padding: 20px 0 0 0px;
            font-size: 27px;
            text-align: left;
        }
    }


    .title-row {
        display: flex;
        width: 100%;

        padding-top: 15px;

        span.uptostyle {
            font-family: Futura Heavy;
            color: #d9002a;
        }

        .title {
            display: block;
            font-family: 'Futura Heavy';
            font-size: 1.5rem;
            letter-spacing: 0.2px;
            text-align: left;
            width: 50%;

        }

        .viewalldiv {
            width: 50%;
            text-align: right;
        }

        .view-all-text {
            padding-top: 0.4rem;
            padding-bottom: 0.4rem;
            padding-left: 0.8rem;
            padding-right: 0.8rem;
            font-size: 0.916666rem;
            background-color: black;
            color: white;
            width: 30%;
        }
    }

    .product-cont {
        display: flex;
        flex-direction: row;
        max-width: 95%;
        margin-left: auto;
        margin-right: auto;
        overflow-x: auto;
        overflow-y: hidden;

        .image-cont {
            width: 160px;
            max-width: 160px;
            margin-inline-end: 10px;
        }
    }
}