@import "../mixins.scss";
@import "../variables.scss";
.add-address-page-mobile {
  width: 100%;
  height: 100%;

  .wrapper {
    position: relative;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;

    .map-style {
      width: 100%;
    }

    .confirm-button {
      position: fixed;
      left: 0px;
      right: 0px;
      bottom: 0px;
      padding: 12px 0px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .confirm-button-text {
        color: white;
        font-weight: bold;
        font-size: 14px;
      }
    }
  }

  .button-background-active {
    background-color: $form-btn-bg;
  }
  .button-background-inactive {
    background-color: rgba(231, 231, 241, 1);
  }
}
