@import "../mixins.scss";

.shipping-address-page-desktop {
  font-size: 16px;
  width: 100%;

  .map-row {
    width: 100%;
    max-width: 1440px;
    background-color: #f7f7fa;
    padding: 0px 45px;
    margin-top: 30px;
    margin-bottom: 30px;

    @include for-md-screen {
      padding: 0px 30px;
    }

    @include for-lg1-screen {
      padding: 0px 45px;
    }

    .map {
      width: 100%;
      box-shadow: 0px 0px 2px #00000029;

      .adress-col {
        padding: 10px 0px 10px 20px;
        background-color: white;

        .label {
          color: #3f3f3f;
          font-size: 0.81rem;
          font-weight: 700;
        }
      }

      .map-col {
        padding: 0px;
        height: 500px;
      }

      .confirm-location-col {
        padding: 10px 20px 10px 0px;
        background-color: white;

        .location-button {
          padding: 15px 30px;
          line-height: 15px;
          color: #ffffff;
          font-size: 1rem;
          font-weight: 700;
          border-radius: 3px;
        }
        .location-background-inactive {
          background-color: rgba(231, 231, 241, 1);
          border: 1px solid rgba(231, 231, 241, 1);
        }
        .location-background-active {
          background-color: #d3af44;
          border: 1px solid #d3af44;
        }
        .back-button {
          padding: 15px 30px;
          line-height: 15px;
          color: #454545;
          font-size: 1rem;
          font-weight: 700;
          box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
          background: #fff;
          border: none;
          border-radius: 3px;
        }
      }
    }
  }

  .shipping-address-page-footer {
    margin-top: 80px;
  }
}
