@import "../../../mixins.scss";

.add-address-body-mobile {
  max-width: 768px;
  background-color: white;
  padding-bottom: 10px;

  .error {
    color: red;
    font-size: 13px;
    margin-inline-start: 25px;
    margin-top: 3px;
    text-align: initial;
  }

  .location-txt {
    font-size: 14px;
    font-weight: 700;
    color: #858796;
    text-align: start;
  }
  .address-text {
    font-size: 15px;
    font-weight: 600;
    color: #34353a;
    padding-bottom: 10px;
    border-bottom: 1px solid #e0e0e0;
  }

  .map-img {
    width: 66px;
    height: 66px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
  }

  .edit-text {
    cursor: pointer;
    background-color: white;
    width: 100%;
    text-align: center;
    color: #5b65ac;
    font-size: 13px;
    font-weight: 500;
  }

  .additional-address-text {
    font-size: 12.3px;
    color: #97989d;
    text-align: start;
  }

  .additional-address-input {
    border: none;
    outline: none;
    background-color: transparent;
    width: 100%;
    font-size: 15px;
    font-weight: 530;
    border-bottom: 1.5px solid #e0e0e0 !important;
    &::placeholder {
      color: #97989d;
      font-weight: 600;
      font-size: 15px;
    }
    &:hover {
      outline: none;
    }
    &:focus {
      outline: none;
    }
  }

  .name-input {
    border: none;
    outline: none;
    background-color: transparent;
    width: 100%;
    font-size: 13.5px;
    font-weight: 600;
    border-bottom: 1px solid #e0e0e0 !important;
    color: #3a3d44;
    &::placeholder {
      color: #97989d;
    }
    &:hover {
      outline: none;
    }
    &:focus {
      outline: none;
    }
  }

  .label-text {
    color: #8f8f99;
    font-size: 12px;
    font-weight: 700;
    text-align: start;
  }
  .sp-bold {
    font-weight: bold;
    font-size: 14px;
  }

  .home-cont {
    border-radius: 14px;
    color: #646466;
    font-size: 12px;
    height: 26px;
    width: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #646466;
    margin-inline-end: 15px;
  }
  .active-cont {
    border: 1px solid blue !important;
  }

  .work-cont {
    border-radius: 14px;
    color: #4b4b4d;
    font-size: 13px;
    align-items: center;
    padding: 3px 10px 6px 10px;
    border: 1px solid #4b4b4d;
  }

  .save-cont {
    padding: 12px 0px;
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    background-color: #c78e2b;
    border-radius: 4px;

    .save-text {
      color: white;
      font-weight: bold;
      font-size: 14px;
    }
  }
}
