@import "../../mixins.scss";
@import "../../variables.scss";

.home-mobile {
  max-width: 768px !important;
  margin: auto;
  height: 100%;

  .bg-transparent {
    background: transparent;
  }

  .header {
    position: fixed;
    top: 0;
    width: 100%;
    padding: 0px 10px;
    z-index: 10;
    background: white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $border-color;
    height: 55px;

    .logo {
      display: inline-flex;
      width: 120px;
      height: 29px;
    }

    .search {
      color: $p-color;
      font-size: 18px;
      margin-left: 10px;
    }
  }

  .header-products-slider {
    margin-bottom: 15px;
    font-weight: 700;
    color: #7d252a;
    opacity: 1;
    text-transform: capitalize;
    width: 100%;
    text-align: center;
    padding: 20px 0 0 0px;
    font-size: 18px;
  }

  .body {
    position: relative;
    // top: 55px;
    // height: calc(100vh - 110px);
    overflow-y: auto;
    // padding-bottom: 70px;

    .cross-icon {
      margin-top: 6px;
      margin-right: 5px;
    }

    .getAppRow {
      display: flex;
      padding: 10px;
    }

    .appOpenBtn {
      background-color: #bba038;
      color: white;
      text-decoration: none;
      width: 60px;
      text-align: center;
      font-size: 13px;
      border-radius: 4px;
      padding-top: 6px;
      justify-content: flex-end;
    }

    .text p {
      font-size: 12px;
      margin: 0px;
    }

    .location-icon {
      color: $p-color;
      font-size: 20px;
      padding-left: 10px;
    }

    .deliver-text {
      color: $head-color;
      font-size: 13px;
      margin-left: 5px;

      .highlighted-text {
        font-weight: bold;
      }
    }

    .homepage-main-banner-swiper {
      height: 150px;
      margin-bottom: 10px;
    }

    .shop-text {
      color: $head-color;
      padding-inline-start: 10px;
      font-weight: 700;
      font-size: 15px;
    }

    .view-text {
      margin-inline-end: 10px;
      font-size: 10px;
      color: #bba038;
      padding: 4px 7px;
      font-weight: 800;
      border: 0.3px solid #bba038;
      border-radius: 3px;
    }

    .category-cont {
      margin-top: 10px;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
      grid-template-rows: repeat(auto-fill);
      gap: 10px 10px;
      padding-inline-start: 10px;
      padding-inline-end: 10px;

      @media screen and (min-width: 320px) and (max-width: 340px) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
      }

      @media screen and (min-width: 340px) and (max-width: 460px) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
      }

      @media screen and (min-width: 460px) and (max-width: 540px) {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      }

      @media screen and (min-width: 540px) and (max-width: 600px) {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
      }

      @media screen and (min-width: 600px) and (max-width: 7680px) {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
      }

      .img-cont {
        width: 100%;

        .img {
          width: 100%;
          height: auto;
          max-height: 120px;
          background-color: #d4982b;
          padding: 10px;
          border-radius: 2px;

          img {
            height: auto;
          }
        }
      }

      .name {
        color: #404553;
        font-size: 9px;
        font-weight: 620;
        text-align: center;
      }
    }

    .product-cont {
      display: flex;
      flex-direction: row;
      max-width: 95%;
      margin-left: auto;
      margin-right: auto;
      overflow-x: auto;
      overflow-y: hidden;
      .image-cont {
        width: 130px;
        max-width: 130px;
        margin-inline-end: 10px;
      }
    }
  }
}
