@import "../mixins";
@import "../variables";

.change-password-mobile-page {
  background-color: #fafafa;
  height: 90vh;

  .error {
    color: red;
    font-size: 13px;
    margin-left: 30px;
  }
  .inner-div {
    background-color: #fafafa;
    padding-top: 15px;

    .e-label {
      width: 90%;
      text-align: start;
      margin: auto;
      display: block;
      margin-top: 40px;
      opacity: 0.6;
      font-size: 1rem;
      font-weight: 500;
    }
    .pass-cont {
      width: 90%;
      margin: auto;
      border-bottom: 1px solid $lightGray;
      margin-top: 10px;

      .password {
        width: 100%;
        border: none;
        background-color: transparent;
        padding-bottom: 10px;
        color: $p-color;
        font-size: 1.3rem;
        &:hover {
          outline: none;
        }
        &:focus {
          outline: none;
        }
      }
      ::placeholder {
        font-weight: bold !important;
        color: #a5a5a5;
        font-size: 1rem;
      }
      .eye-icon {
        position: relative;
        right: 0px;
        bottom: 4px;
      }
    }
    .change-passsword-fields {
      .change-password-first-row {
      }
    }
    .change-password-mobile-end {
      position: fixed;
      bottom: 0;
      width: 100%;
      .change-password-mobile-button {
        background: 0% 0% no-repeat padding-box;
        background-color: $form-btn-bg;
        width: 100%;
        font-weight: bold;
        text-align: center;
        font-size: 1.125rem;
        color: #ffffff;
        padding: 20px 35px 20px;
        text-decoration: none;
        border: none;
      }
    }
  }
}
