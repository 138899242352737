@import "./mixins.scss";

.empty-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  .img-cont {
    width: 250px;
    height: 250px;
  }
  .empty-bold-text {
    color: #404553;
    font-weight: bold;
    font-size: 16px;

    @include for-mob-screen {
      font-size: 13px;
    }
  }
  .empty-text {
    color: #404553;
    font-size: 15px;

    @include for-mob-screen {
      font-size: 13px;
    }
  }

  .btn-cont {
    background-color: #3866df;
    width: 250px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    .btn-text {
      color: white;
      font-weight: bold;
      font-size: 14px;
    }
  }
}
