@import "../mixins.scss";
@import "../variables.scss";
.dispute-details-page-mobile-container {
  position: relative;
  overflow: auto;
  $black-text: #4b4b50;
  $gray: #84848e;
  $shadow: 0px 2px 6px #00000024;
  background-color: white;
  padding-bottom: 80px;

  .empty-disputes-page {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 75vh;

    .img-cont {
      @include for-mob-screen {
        width: 60%;
      }
    }

    .dispute-empty-head-text {
      text-align: center;
      color: #404553;
      font-weight: bold;
      font-size: 16px;

      @include for-mob-screen {
        font-size: 13px;
        width: 90%;
      }
    }

    .dispute-empty-sub-text {
      text-align: center;
      color: #404553;
      font-size: 15px;

      @include for-mob-screen {
        font-size: 13px;
        width: 90%;
      }
    }

    .file-a-dispute-button {
      background: $form-btn-bg 0% 0% no-repeat padding-box;
      border-radius: 4px;
      text-align: left;
      font-size: 15px;
      color: #ffffff;
      padding: 10px 20px 10px;
      text-decoration: none;
      border: none;
    }
  }
  .header {
    background-color: #f5f8ff;
    box-shadow: $shadow;

    .id-row {
      font-size: 13px;
      text-align: start;
      font-weight: 700;
    }

    .date-row {
      color: $gray;
    }
  }
  .dispute-order-description {
    box-shadow: $shadow;
    background-color: #ffffff;
  }
}
.bottom {
  position: fixed;
  left: 4%;
  bottom: 0;
  width: 92%;
  height: 50px;
  background-color: #c78e2b;
  font-size: 15px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  .file-new-dispute-btn {
    color: #fff;
  }
}
