@import "../mixins.scss";

.primary-address-col {
  border: 1px solid rgb(56, 102, 223);
  box-shadow: 0px 0px 0px 1px rgb(56, 102, 223),
    0px 3px 2px 0px rgba(0, 0, 0, 0.4);
  border-radius: 2px;
  background-color: #ffffff;
  position: relative;

  .header {
    border-bottom: 1px solid rgb(226, 229, 241);
    padding: 10px 15px 9px;
    background: rgb(252, 251, 244);
    font-weight: $customer-page-button-weight;
    font-size: $customer-page-subheading-size;
    color: $head-color;
  }
  .menu-options-en {
    background: rgb(226, 229, 241);
    height: 40px;
    width: 40px;
    position: absolute;
    top: 70px;
    right: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    .text-style {
      font-size: 9px;
      font-weight: 600;
    }
  }
  .menu-options-ar {
    background: rgb(226, 229, 241);
    height: 40px;
    width: 40px;
    position: absolute;
    top: 70px;
    left: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    .text-style {
      font-size: 9px;
      font-weight: 600;
    }
  }
}

.non-primary-address-col {
  border: 1px solid rgb(226, 229, 241);
  box-shadow: 0px 3px 2px 0px rgba(0, 0, 0, 0.04);
  border-radius: 2px;
  background-color: #ffffff;
  height: 190px;
  max-height: 190px;
  position: relative;

  .menu-options-en {
    background: rgb(226, 229, 241);
    height: 40px;
    width: 40px;
    position: absolute;
    top: 30px;
    right: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    .text-style {
      font-size: 9px;
      font-weight: 600;
    }
  }
  .menu-options-ar {
    background: rgb(226, 229, 241);
    height: 40px;
    width: 40px;
    position: absolute;
    top: 30px;
    left: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    .text-style {
      font-size: 9px;
      font-weight: 600;
    }
  }
  @media only screen and (min-width: 1118px) and (max-width: 1200px) {
    height: 172px;
    max-height: 172px;
  }
  @media only screen and (min-width: 1201px) and (max-width: 1264px) {
    height: 208px;
    max-height: 208px;
  }

  &:hover {
    border: 1px solid rgb(56, 102, 223);
    box-shadow: 0px 0px 0px 1px rgb(56, 102, 223),
      0px 3px 2px 0px rgba(0, 0, 0, 0.4);
    cursor: pointer;
  }
}

.customer-address {
  width: 100%;
  text-align: start;

  .content {
    padding: 13px 15px 16px;

    .details {
      .heading {
        .label {
          font-size: $customer-page-subheading-size;
          font-weight: $customer-page-subheading-weight;
          color: $head-color;
        }

        svg {
          margin-top: 1px;
        }
      }

      .menu {
        svg {
          font-size: 1.2rem;
          font-weight: 700;
        }
      }
    }

    .name {
      margin-top: 15px;

      .name-list {
        .label {
          width: 40%;
          font-size: $customer-page-subtext-size;
          font-weight: $customer-page-subtext-weight;
          color: $p-color;
        }

        .value {
          width: 60%;
          font-size: $customer-page-subtext-size;
          font-weight: 600;
          color: $head-color;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    .address {
      margin-top: 15px;
      height: auto;
      max-height: 54px;
      overflow: hidden;

      @media only screen and (min-width: 1118px) and (max-width: 1200px) {
        max-height: 36px;
      }

      .address-list {
        .label {
          width: 40%;
          font-size: $customer-page-subtext-size;
          font-weight: $customer-page-subtext-weight;
          color: $p-color;
        }

        .value {
          width: 60%;
          font-size: $customer-page-subtext-size;
          font-weight: 400;
          color: $head-color;
        }
      }
    }

    .phone-number {
      margin-top: 15px;

      .phone-list {
        .label {
          width: 40%;
          font-size: $customer-page-subtext-size;
          font-weight: $customer-page-subtext-weight;
          color: $p-color;
        }

        .value {
          width: 60%;
          font-size: $customer-page-subtext-size;
          font-weight: 400;
          color: $head-color;

          .number {
            margin-right: 6px;
          }

          svg {
            background-color: white;
            border-radius: 50%;
            color: #47bb1e;
            margin-top: 2px;
          }
        }
      }
    }
  }
}
