@import "../../../mixins.scss";

.item-category-desktop {
  .items-category-heading {
    font-size: 13px;
    font-weight: 700;
    color: #404553;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
  }
 .items-category-heading1 {
    font-size: 13px;
    font-weight: 700;
    color: #404553;
    width: 95%;
    margin-left: auto;
    background-color: white;
    margin-right: auto;
    border: 1px solid #ccc!important;
    padding: 2px;
  }

  .active {
    color: #d4982b !important;
    font-size: 14px;
  }

  .items-category-val-cont {
    width: 75%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    // justify-content: space-between;

    .items-category-val {
      font-size: 13px;
      font-weight: 500;
      &:hover {
        font-weight: 700;
        text-decoration: underline;
      }
      align-self: center;
      text-align: left;
      font-size: 12px;
      color: #404553;
    }
  }

  .icon-div {
    width: 26px;
  }
  .items-category-sub-cat-three-cont {
    width: 55%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    // justify-content: space-between;

    .items-category-val-two {
      font-size: 12px;
      &:hover {
        font-weight: 700;
      }
      align-self: center;
      text-align: left;
      font-size: 12px;
      color: rgb(138, 136, 136);
    }
  }
  .items-category-sub-cat-four-cont {
    width: 30%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    // justify-content: space-between;

    .items-category-val-three {
      font-size: 12px;
      &:hover {
        font-weight: 500;
      }
      align-self: center;
      text-align: left;
      font-size: 12px;
      color: rgb(150, 143, 143);
    }
  }
  .plus-minus-btn {
    background-color: white;
    border: 1px solid rgb(218, 220, 227);
    width: 16px;
    margin-right: 10px;
  }
  .plus-minus-btn-ar {
    margin-right: 0px;
    margin-left: 10px;
  }
}