@import "../../../mixins.scss";

.buttons-desktop {
  border: 1px solid $border-color;
  width: fit-content;

  .same-width {
    text-align: center;
    padding: 5px 17px;
  }

  .fs-15 {
    font-size: 12px;
  }

  button {
    width: 25px;
    padding: 5px 0px;
    border: none;
  }

  .border-start {
    border-inline-start: 1px solid $border-color !important;
  }

  .border-end {
    border-inline-end: 1px solid $border-color !important;
  }
}
