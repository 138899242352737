@import "../mixins";
@import "../variables";
.payment-add-new-card-mob {
  background-color: #fff;
  height: 100%;
  position: relative;
  .noon-and-cancel {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-top: 20px;
    .noon-at-payment {
      size: 30px;
    }
    .cancel {
      font-weight: 550;
      font-size: 1.2rem;
    }
  }
  .add-new-div {
    text-align: start;
    .add-new-label {
      font-size: 1.5rem;
      font-weight: 550;
    }
  }
  .add-new-payment-method {
    display: flex;
    flex-direction: column;
    .card-number-div {
      display: flex;
      flex-direction: column;
      text-align: start;
      .card-number-label {
        margin-top: 20px;
        color: #b0b1b6;
        font-weight: bold;
      }
      .card-number-input {
        margin-top: 5px;
        height: 50px;
        .card-input {
          width: 100%;
          margin: auto;
          border-top: none;
          border-left: none;
          border-right: none;
          background-color: transparent;
          margin-top: 10px;
          border-bottom: 1px solid $lightGray;
          padding-bottom: 10px;
          color: $p-color;
          font-size: 13px;
          &:hover {
            outline: none;
          }
          &:focus {
            outline: none;
          }
          &::placeholder {
            font-size: 1.3rem;
            padding-left: 10px;
            padding-bottom: 10px;
          }
        }
        ::placeholder {
          font-weight: bold !important;
          color: #a5a5a5;
          font-size: 0.9rem;
        }
      }
    }
    .expiry-and-security-no {
      display: flex;
      flex-direction: row;
      margin-top: 40px;
      justify-content: space-between;
      .left-expiry {
        text-align: start;
        .expiry-label {
          color: #b0b1b6;
          font-weight: bold;
        }
        .month-year {
          margin-top: 5px;
          height: 50px;
          .month-year-input {
            width: 100%;
            margin: auto;
            border-top: none;
            border-left: none;
            border-right: none;
            background-color: transparent;
            margin-top: 10px;
            border-bottom: 1px solid $lightGray;
            padding-bottom: 10px;
            color: $p-color;
            font-size: 13px;
            &:hover {
              outline: none;
            }
            &:focus {
              outline: none;
            }
            &::placeholder {
              font-size: 1.3rem;
              padding-left: 10px;
              padding-bottom: 10px;
            }
          }
        }
      }
      .right-security-no {
        text-align: start;
        .security-label {
          color: #b0b1b6;
          font-weight: bold;
        }
        .secure-code {
          margin-top: 5px;
          height: 50px;
          .secure-code-input {
            width: 100%;
            margin: auto;
            border-top: none;
            border-left: none;
            border-right: none;
            background-color: transparent;
            margin-top: 10px;
            border-bottom: 1px solid $lightGray;
            padding-bottom: 10px;
            color: $p-color;
            font-size: 13px;
            &:hover {
              outline: none;
            }
            &:focus {
              outline: none;
            }
            &::placeholder {
              font-size: 1.3rem;
              padding-left: 10px;
              padding-bottom: 10px;
            }
          }
        }
      }
    }
  }
  .add-card {
    margin-top: 50px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    background-color: #742013;
    height: 50px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    cursor: pointer;
  }
}
