@import "../mixins.scss";

.order-component {
  background-color: #f7f7f9;
  width: 100%;
  padding-top: 27px;
  font-size: 16px;

  .empty-cont {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    height: 600px;
    .img-cont {
      @include for-mob-screen {
        width: 60%;
      }
    }

    .empty-bold-text {
      color: #404553;
      font-weight: bold;
      font-size: 16px;

      @include for-mob-screen {
        font-size: 13px;
      }
    }
    .empty-text {
      color: #404553;
      font-size: 15px;

      @include for-mob-screen {
        font-size: 13px;
      }
    }
  }

  .small-screen-none {
    @include for-mob-screen {
      display: none !important;
    }
  }
  .large-screen-none {
    @include for-desktop-screen {
      display: none !important;
    }
  }

  @include for-mob-screen {
    margin: 0px;
    padding: 0px;
  }

  $head-color: rgb(64, 69, 83);
  $p-color: rgb(126, 133, 155);
  $border-color: rgb(226, 229, 241);
  $delivered-color: rgb(64, 169, 25);
  $blue-header: rgb(58, 102, 216);
  $review-bg: rgb(242, 245, 252);

  .order-row {
    width: 100%;

    .right-section {
      width: 100%;

      .image-container {
        width: 100%;
      }

      .order-list {
        width: 100%;
        margin-top: 27px;

        @include for-mob-screen {
          margin-top: 0px;
        }

        .header-container {
          @include for-mob-screen {
            display: none !important;
          }
          .heading-container {
            text-align: start;
            width: 50%;

            .heading {
              font-size: $customer-page-header-size;
              font-weight: $customer-page-header-weight;
              color: $head-color;
              margin-bottom: 5px;
            }

            p {
              color: $p-color;
              font-size: $customer-page-subtext-size;
              font-weight: $customer-page-subtext-weight;
            }
          }

          .filter-container {
            width: 50%;

            .label {
              font-size: $customer-page-subtext-size;
              font-weight: $customer-page-subtext-weight;
              color: $head-color;
            }

            .filter-search {
              margin-inline-start: 15px;
              background-color: #ffffff;
              border-radius: 3px;
              padding: 0;
              width: 200px;
              min-width: 200px;
            }
          }
        }

        .order-container {
          background-color: #ffffff;
          box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
          border: 1px solid $border-color;
          border-radius: 0px 0px 3px 3px;
          margin-top: 27px;

          .order-heading-container {
            padding: 10px 15px;
            background-color: #f0f3fa;
            border-radius: 3px 3px 0px 0px;

            @include for-mob-screen {
              background-color: #fff;
              border-bottom: 1px solid #f5f5f5;
            }

            .forward-icon {
              cursor: pointer;
            }

            .order-id-container {
              .order-id {
                font-size: $customer-page-subheading-size;
                font-weight: $customer-page-header-weight;
                color: $blue-header;

                @include for-mob-screen {
                  color: #404553;
                  display: flex;
                  flex-direction: column;
                }
              }

              .order-date {
                padding-inline-start: 10px;
                font-size: $customer-page-subtext-size;
                font-weight: $customer-page-subtext-weight;
                color: $p-color;

                @include for-mob-screen {
                  padding-inline-start: 0px;
                }
              }
            }
          }

          .more {
            font-size: 1.5rem;
            font-weight: 700;
            color: $head-color;

            @include for-mob-screen {
              color: #7284c4;
              font-size: 15px;
              font-weight: 500;
              display: flex;
              align-items: center;
            }
          }

          .orders {
            max-width: 98%;
            width: 98%;
            overflow: auto;

            padding: 20px 10px 30px 10px;

            .order {
              min-width: 25.2%;

              .image-container {
                width: 70px;
                height: 70px;
              }

              @include for-mob-screen {
                min-width: 75%;
              }

              .description-container {
                padding-inline-start: 10px;
                text-align: initial;

                .description {
                  font-size: $customer-page-subtext-size;
                  font-weight: $customer-page-subtext-weight;
                  color: $head-color;
                  margin-bottom: 3px !important;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  display: -webkit-box;
                  -webkit-line-clamp: 2;
                  -webkit-box-orient: vertical;
                  height: auto;
                  max-height: 40px;
                  min-height: 40px;

                  @include for-mob-screen {
                    color: #41464a;
                    font-size: 14px;
                  }
                }

                .delivered {
                  font-size: $customer-page-subheading-size;
                  font-weight: $customer-page-subheading-weight;
                  margin-bottom: 0px !important;

                  @include for-mob-screen {
                    font-weight: 700;
                  }
                }

                .green {
                  color: $delivered-color;
                }

                .black {
                  color: $head-color;
                }
              }
            }
          }

          .border-line {
            height: 0px;
            border: 1px solid rgba(112, 112, 112, 0.2);
            margin: 0px 10px;

            @include for-mob-screen {
              border: none;
            }
          }

          .review-container {
            padding: 10px;

            .review {
              width: fit-content;
              font-size: $customer-page-subtext-size;
              font-weight: $customer-page-subtext-weight;
              border-radius: 4px;
              background-color: $review-bg;
              padding: 5px 5px;
              color: $blue-header;
              border: none;

              svg {
                margin-inline-start: 6px;
                width: 13px;
                height: 13px;
                margin-inline-end: 6px;

                @include for-mob-screen {
                  margin-right: 6px;
                }
              }
            }
          }
        }
      }
    }
  }
}

.order-component-mobile {
  .mobile-header {
    @include for-desktop-screen {
      display: none !important;
    }

    @include for-mob-screen {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      height: 60px;
      background: #fff;
      padding-left: 10px;
      padding-right: 10px;

      .text-style {
        font-size: 15px;
        color: rgb(126, 133, 155);
        font-weight: 500;
      }
      .dropdown-cont {
      }

      .Dropdown-control-ltr {
        border: 1px solid #f4f4f4;
        border-radius: 5px;
        padding: 7px 0px 7px 14px;
      }
      .Dropdown-control-rtl {
        border: 1px solid #f4f4f4;
        border-radius: 5px;
        padding: 7px 0px 7px 14px;
      }

      .dropdownPlaceholder {
        font-size: 12px;
        color: $blue-header;
        font-weight: 700;
      }

      .Dropdown-arrow-ltr {
        top: 13px;
        right: 14px;
      }
      .Dropdown-arrow-rtl {
        top: 13px;
        left: 14px;
      }
    }
  }
}
