.product-detail-card {
  background-color: white;
  $black-text: #404553;
  $gray: rgb(126, 133, 155);

  .brand {
    color: $gray;
    text-align: start;
    font-size: 0.9rem;
    font-weight: 400;
  }

  .fs-size {
    font-size: 11px;
  }
  .wrapper {
    text-align: start;
    .left {
      width: 75%;

      .title {
        max-width: 100%;
        font-weight: 400;
        max-height: 60px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        height: auto;
        color: $gray;
        font-size: 0.9rem;
      }

      .price {
        font-weight: 700;
        font-size: 0.9rem;
        color: $black-text;
      }

      .quantity {
        color: $gray;
        font-size: 0.9rem;
        font-weight: 400;
      }

      .store-name {
        font-size: 0.9rem;
        color: $gray;
        font-weight: 400;

        .sold-by {
          color: $black-text;
          font-weight: 700;
          margin-inline-start: 5px;
        }
      }
    }

    .right {
      width: 20%;
      display: flex;
      justify-content: center;
      .image-container {
        width: 80px;
        height: 80px;

        img {
          width: auto;
          height: 100%;
        }
      }
    }
  }
}
