@import "../mixins.scss";
.dispute-page-mobile-main-container {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  text-align: start;
  .empty-disputes-page {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 75vh;

    .img-cont {
      @include for-mob-screen {
        width: 60%;
      }
    }

    .dispute-empty-head-text {
      text-align: center;
      color: #404553;
      font-weight: bold;
      font-size: 16px;

      @include for-mob-screen {
        font-size: 13px;
        width: 90%;
      }
    }

    .dispute-empty-sub-text {
      text-align: center;
      color: #404553;
      font-size: 15px;

      @include for-mob-screen {
        font-size: 13px;
        width: 90%;
      }
    }

    .file-a-dispute-button {
      background: $form-btn-bg 0% 0% no-repeat padding-box;
      border-radius: 4px;
      text-align: left;
      font-size: 15px;
      color: #ffffff;
      padding: 10px 20px 10px;
      text-decoration: none;
      border: none;
    }
  }
  .dispute-messages {
    .chat-row {
      width: 100%;
      margin-top: 17px;
      background: #ebebeb 0% 0% no-repeat padding-box;
      box-shadow: 0px 0px 2px #00000029;
      border-radius: 8px;
      height: 488px;
      min-height: 488px;
      position: relative;

      .header {
        margin-bottom: 17px;
        font-size: 1rem;
        font-weight: 700;
        color: #404553;
        text-align: start;
      }

      .messages {
        height: 350px;
        max-height: 350px;
        overflow-y: auto;
      }

      .editor-row {
        position: absolute;
        bottom: 17px;
        left: 0;
        right: 0;
        padding: 0px 10px;

        .send-button {
          width: 70px;
          padding: 10px 15px;
          background: #1074a8 0% 0% no-repeat padding-box;
          border-radius: 4px;
          color: white;
          font-size: 1rem;
          font-weight: 700;
          margin-inline-start: 10px;
          border: none;
          outline: none;
          height: 44px;
          box-shadow: none;
        }

        .editor {
          width: calc(100% - 80px);
          background-color: white;
          color: grey;
          height: 50px;
          min-height: 50px;
          max-height: 50px;
          box-shadow: 0px 0px 3px #00000029;
          border-radius: 8px;
          border: none;
          outline: none;
          padding: 5px 10px;
        }
      }
    }
  }
}
