@import "../../../mixins.scss";

.cart-wishlist-container-desktop {
  height: auto;
  padding-top: 10px;
  padding-left: 6px;
  padding-right: 10px;
  .wish {
    opacity: 1;
    font-weight: 700;
    font-style: normal;
    font-size: 14px;
    line-height: 25px;
    color: rgb(51, 51, 51);
  }
  .count {
    font-size: 13px;
    font-weight: normal;
    color: $p-color;
    padding-left: 15px;
  }
}
