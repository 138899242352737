@import "../mixins";
@import "../variables";

.login-mob-option-1 {
  max-width: 768px !important;
  width: 100%;
  background-color: white;
  height: 100vh;
  overflow-y: auto;

  .wrapper {
    width: 80%;
    margin: auto;
    padding-top: 10px;
    padding-bottom: 10px;

    .logo-and-icon {
      margin-top: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .logo-cont {
        width: 170px;
        height: 33px;

        .logo-size {
          margin-left: auto;
          margin-right: auto;
          width: 100%;
          height: 100%;

          img:hover {
            opacity: 0.7;
          }
        }
      }
      .cross-icon {
        color: #404553;
        font-size: 16px;
        display: flex;
        justify-content: flex-start;

        svg {
          font-size: 1.5rem;
          width: 1.5rem;
          height: 1.5rem;
        }
      }
    }

    .create-account-title {
      text-align: start;
      width: 100%;
      color: #404553;
      font-size: 1.4rem;
      font-weight: 800;
      margin-top: 40px;
    }
    .e-label {
      width: 100%;
      text-align: start;
      margin: auto;
      display: block;
      margin-top: 40px;
      opacity: 0.6;
      font-size: 0.875rem;
      font-weight: 400;
    }
    .email {
      .e-input {
        width: 100%;
        margin: auto;
        border-top: none;
        border-left: none;
        border-right: none;
        background-color: transparent;
        margin-top: 10px;
        border-bottom: 1px solid $lightGray;
        padding: 5px;
        padding-bottom: 10px;
        color: #404553 !important;
        font-size: 0.9rem !important;
        font-weight: 400 !important;

        &:focus {
          outline: none;
        }
      }
      ::placeholder {
        font-weight: bold !important;
        color: #a5a5a5;
        font-size: 0.9rem;
      }
    }

    .pass-cont {
      width: 100%;
      margin: auto;
      border-bottom: 1px solid $lightGray;
      margin-top: 10px;

      .password {
        width: 100%;
        border: none;
        background-color: transparent;
        padding: 5px;
        padding-bottom: 10px;
        color: #404553 !important;
        font-size: 0.9rem !important;
        font-weight: 400 !important;
        &:hover {
          outline: none;
        }
        &:focus {
          outline: none;
        }
      }
      ::placeholder {
        font-weight: bold !important;
        color: #a5a5a5;
        font-size: 0.9rem;
      }
      .eye-icon {
        position: relative;
        right: 0px;
        bottom: 0px;
      }
    }

    .forget-password {
      margin-top: 50px;
      text-align: start;
      width: 100%;
      color: #404553;
      font-size: 1rem;
      font-weight: 400;
    }

    .sign-in {
      margin-top: 35px;
      background-color: $form-btn-bg;
      width: 100%;
      height: 50px;
      font-weight: bold;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      cursor: pointer;
      border-radius: 4px;
    }

    .signup {
      width: 100%;
      margin-top: 15px;
      text-align: start;
      text-transform: capitalize;
      font-size: 14px;
      align-items: center;
      color: $p-color;

      .signup-text {
        font-weight: bold;
        font-size: 15px;
        margin-left: 5px;
        cursor: pointer;
      }
    }
  }
}
