@import "../../../mixins.scss";

.category-cont .img-style.p-2.mr-auto.ml-auto {
  // background-color: #d4982b;
}
.main-img.mt-3 {
  display: none;
}
.all-categories-right-div{
  background-color: #fcfaf4;
}
.categories-right-panel {
  width: 100%;
  float: right;
  background-color: #fcfaf4;

  .main-img {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    height: 80px;
    margin-top: 0px!important;
  }

  .data-cont {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    .option-name {
      display: block;
      font-weight: 600;
      letter-spacing: 0.5px;
      width: 70%;
      max-width: 70%;
      font-size: 14px!important;
    }
    .no-width {
      width: 0px;
    }
    .width-fit-content {
      width: fit-content;
    }
.viewalldiv{
  width: 30%;
  max-width: 30%;
  display: block;
}
    .view-all {
      padding-top: 0.4rem;
      padding-bottom: 0.4rem;
      padding-left: 0.5rem;
      padding-right: 0.5rem;
      font-size: 0.716666rem;
      background-color: black;
      color: white;
      border-radius: 2px;
      font-weight: 600;
      letter-spacing: 1px;
      text-transform: uppercase;
    }

    .category-cont {
      overflow-y: auto;

      .img-style {
      
      }

      .name-style {
        color: #5c5f67;
        font-size: 9px;
        font-weight: 620;
        text-align: center;
        display: none;
      }
    }
  }
}
