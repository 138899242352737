.swiper-container {

 
}
.catpages-mob{
margin: 10px!important;
border-radius: 10px!important;
}
.banner-sec img {
    width: 100%;
}
.home-appliances-mob {
    background-color: white;

    .row {
        margin: 0px;
    }
    
    .top-row {
        background-color: #f8f8f8;
    }
   
    .product-cont {
        display: flex;
        flex-direction: row;
        max-width: 95%;
        margin-left: auto;
        margin-right: auto;
        overflow-x: auto;
        overflow-y: hidden;

        .image-cont {
            width: 160px;
            max-width: 160px;
            margin-inline-end: 10px;
        }
    }

    .pagesbanner {
        padding-top: 15px;
        padding-bottom: 30px;
    }

    .topofferimg {
        padding: 10px;
        // width: 175px;
        // -webkit-transform: rotate(362deg);
    }

    .offers.catbox {
        padding: 10px;
    }

    .surfacepat .product-list-desktop .product-list-container {

        background-color: #fcfaf4;
    }

    .catrow {
        display: flex;

    }

    .six6 img {
        border: none;
    }

    .titlewrap {
        padding-top: 15px;
        padding-bottom: 5px;
        padding-left: 12px!important;
    }
    .emptyspacer15{
        height: 15px;
    }
    


    
.category-content{
    background-color: #f8f8f8;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    
    p{
        font-size: 14px!important;
        line-height: unset;
        margin-top: unset;
        text-align: justify;
    }
    h2{
        font-size: 22px;
        font-weight: 600;
    }
    h3{
        font-size: 20px;
    }
}
    .padding-10 {
        padding: 10px !important;
    }

    .offerbg {
        background-color: #f8e89a;
        // border-bottom: 140px solid #f8e89a;
        // border-left: 23px solid transparent;
        // border-right: 14px solid transparent;
        border-radius: 5px;
        margin: 0px;
    }
.shopbycat img{
    border: 1px solid #9e9e9e52;
}
.sbc{
    background-color: #d0e6f9;
    border-radius: 100%;
    padding: 10px;
}
.cleancat{
background-color: #d1fbc29f;
border-radius: 10px;
}
.sbb{
}
.shopbycat  .col-3 {
    padding: 5px;
}
    .sectiontitle-individualpage {
        margin-bottom: 0px;
        text-align: center;
        color: #000000;
        font-weight: 600;
        font-size: 25px;
    }

    .titlewrap.noclr {
        background-color: #ffffff;
    }

    .offertitles {
        text-align: center;
        font-family: 'Futura heavy';
        padding-top: 10px;
        font-size: 18px;
        text-transform: uppercase;
        line-height: 0.9;
        /* -webkit-text-stroke: 1px white; */
        -webkit-text-stroke: thin;
    }

    span.offerprice {
        font-family: 'Futura heavy';
        margin-top: 29px;
        color: #ff5722;
    }

    .mypages-container {
        margin-right: auto !important;
        margin-left: auto !important;
        padding-left: 10px !important;
        padding-right: 10px !important;
        width: 1300px;
    }

    @media (min-width: 1360px) {
        .container {
            width: 1300px;
        }
    }

    @media (min-width: 1280px) {
        .container {
            width: 1220px;
        }
    }

    @media (min-width: 1170px) {
        .container {
            width: 1024px;
        }
    }

    @media (min-width: 992px) {
        .container {
            width: 970px;
        }
    }

    @media (min-width: 768px) {
        .container {
            width: 750px;
        }
    }
}