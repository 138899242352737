@import "../../mixins.scss";

.shipping-footer-desktop {
  width: 100%;

  .shipping-footer {
    width: 100%;
    max-width: 1440px;
    padding: 0px 45px;

    @include for-md-screen {
      padding: 0px 30px;
    }

    @include for-lg1-screen {
      padding: 0px 45px;
    }

    .security-text {
      .label {
        font-size: $customer-page-subtext-size;
        font-weight: $customer-page-subtext-weight;
        color: $p-color;
        text-align: center;
      }
    }

    .footer {
      padding: 20px 0px;
      margin-top: 20px;
      border-top: 1px solid $border-color;

      @include for-md-screen {
        padding: 20px 0px;
      }

      @include for-lg1-screen {
        padding: 20px 0px;
      }

      .copy-right {
        font-size: $customer-page-subheading-size;
        font-weight: 400;
        color: $p-color;
      }

      .statement {
        font-size: $customer-page-subheading-size;
        font-weight: 400;
        color: $p-color;

        .term-of-use {
          margin-right: 20px;
        }

        .term-of-sale {
          margin-right: 20px;
        }
      }
    }
  }
}
