@import "../../../mixins.scss";
@import "../../../variables.scss";

.item-status-desktop {
  width: 100%;
  border: 1px solid #CCc;
  padding: 10px;
  box-shadow: 0px 0px 8px 1px #cccccca6;
  border-radius: 5px;
.freeunder{
  display: none!important;
}
.warrantyoffer{
  display: none!important;
}
  @include for-mob-screen {
    .small-screen-none {
      display: none !important;
    }

    .border-bottom {
      padding-bottom: 14px;
      border-bottom: 1px solid rgb(240, 242, 248);
    }
  }

  @include for-desktop-screen {
    .large-screen-none {
      display: none !important;
    }
  }

  .offer-text {
    font-size: 13px;
    font-weight: 700;
    color: black;
 
  }
  span.inf{
    font-weight: 500;
    font-size: 13px;
    letter-spacing: 0.5px;
  }

  .mb-10px {
    margin-bottom: 10px;
  }

  .mb-15px {
    margin-bottom: 15px;
  }

  .mb-20px {
    margin-bottom: 15px;
  }

  .mb-25px {
    margin-bottom: 15px;
  }

  .vendure-text {
    color: #404553;
    font-weight: bold;
    font-size: 12px;
    margin-bottom: 4px;

    .anchor-style {
      color: #2e51d3;
      text-decoration: underline !important;
    }
  }
  .review-text {
    color: #404553;
    font-size: 13.5px;
  }

  .calculated-text {
    color: #7e859b;
    font-size: 13px;
  }
  .returns-detail-text {
    color: #7e859b;
    font-size: 12px;

    @include for-mob-screen {
      color: #404553;
    }
  }

  .fw-bold {
    font-weight: bold;
  }
}
