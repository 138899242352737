@import "../mixins";
@import "../variables";

.user-login {
 
  .horizental-line-with-text.d-flex.justify-content-between.align-items-center.mx-auto {
    display: none!important;
}

.login-social-buttons.pointer.mx-auto {
    display: none;
}
  .user-login-logo {
    text-align: center;
    margin-top: 32px;
    margin-bottom: 24px;
  }

  fs-headers-13 {
    font-size: 13px;
  }

  .blue {
    color: blue;
  }

  .user-login-form-container {
    width: 397px;
    text-align: center;

    .user-welcome-text {
      font-size: 18px;
      margin-bottom: 5px;

      @include welcome_tex($p-color, 1);
    }

    .user-text-bold {
      font-size: 20px;
      font-weight: bold;

      @include welcome_tex($head-color, 1);
      margin-bottom: 20px;
    }

    .user-no-account-text {
      color: $p-color;
      margin-bottom: 30px;
      text-decoration: none;

      a {
        color: $blue-header;

        &:hover {
          text-decoration: none;
        }
      }
    }

    .user-login-form {
      text-align: center;
      width: 395px;

      .icon-position-rtl {
        position: absolute !important;
        top: 12px !important;
        right: 95% !important;
        opacity: 0.34;
      }
      .icon-position-ltr {
        position: absolute;
        top: 12px;
        opacity: 0.34;
        right: 15px;
      }

      .form-group {
        @include form-group(40px);
        input {
          height: 35px;
          ::placeholder {
            text-align: start;
          }
        }
      }

      .form-control {
        @include form-control-reset;
      }

      .user-forget-password-text {
        text-decoration: none;
        font-size: 12px;
        font-weight: 500;
        opacity: 1;

        .forget-text {
          color: $blue-header;
          text-decoration: none;
        }

        .modal-container {
          position: fixed;
          background-color: rgba(0, 0, 0, 0.4);
          z-index: 999999;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .user-login-form-button {
        font-size: 13px;
        font-weight: bold;
        @include form_btn;
        margin-top: 32px;
        margin-bottom: 36px;
      }
    }
  }

  .horizental-line-with-text {
    width: 391px;
    font-weight: 500;
    color: $head-color;
    margin-bottom: 29px;

    .line-before-or {
      @include line-with-text(181px, 0px);
    }

    .line-after-or {
      @include line-with-text(181px, 0px);
    }
  }

  .login-social-buttons {
    width: 364px;

    .login-with-facebook {
      margin-bottom: 20px;
      font-size: 13px;
      font-weight: bold;
      @include social_btn($facebook_btn_bg, white);

      img {
        position: absolute;
        left: 14px;
        top: 11px;
      }
    }

    .login-with-google {
      font-size: 13px;
      font-weight: bold;
      margin-bottom: 20px;
      border: 1px solid #70707059;
      @include social_btn(#fefefe, $head-color);

      img {
        position: absolute;
        left: 16px;
        top: 15px;
      }
    }
  }
}
