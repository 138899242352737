@import "../mixins.scss";
.kid-fashion-banner-row.mx-auto.last-bannernew.row {
  display: none;
}
.top-category-header span {
  position: relative;
  z-index: 1;
}


// .modal-backdrop{
//   z-index: 99999 !important;
// }

.offers-modal {
  z-index: 99999 !important;
  
  .modal-dialog {
    margin-top: 70px;
  }

  .modal-dialog-centered {
    margin: auto !important;
  }

  .modal-content {
    border: none;
    background-color: transparent;
  }
}
.home-page-desktop {
  max-width: 100%;
  background-color: #f8f8f8;

  .kid-fashion-banner-row.mx-auto.row:last-child img {
    height: 100px;
  }
  .kid-fashion-banner-row.mx-auto.row:last-child {
    height: 100px;
  }
  .new-arrivals .product-price-container {
    display: none!important;
}
.new-arrivals .product-rating-row{
  display: none!important;
}
  .content-wrapper {
    margin: auto;
    width: 100%;
    max-width: $max-page-width;
    .top-category-header {
      font-weight: 700;
      display: block;
      width: 100%;
      font-size: 32px;
      text-align: center;
      color: #7d252a;
    }
    .homepage-main-banner-swiper {
      // height: 300px;
    }

    .swiper-container {
      margin-left: -20px !important;
      margin-right: -20px !important;
    }

    .product-category-list-row {
      margin-bottom: 50px;
    }

    .working-row {
      margin: 50px 0;
      display: none;
    }

    .product-list-row {
      margin-bottom: 50px;
      max-width: 1440px;
      justify-content: center;

      @include for-768-screen {
        margin-bottom: 0px;
      }

      .header {
        margin-bottom: 0px;
        font-weight: 700;
        color: #7d252a;
        opacity: 1;
        text-transform: capitalize;
        width: 100%;
        padding: 20px 0 0 0px;
        font-size: 27px;
        text-align: left;
      }
    }

    .kid-fashion-banner-row {
      margin-bottom: 50px;
      max-width: 1440px;
      height: 300px;
      margin-top: 70px;
      .kid-fashion-banner-container {
        width: 100%;
      }
    }
    .two-banners-row {
      margin-bottom: 50px;
      max-width: 1440px;
      height: 300px;
      margin-top: 70px;

      .two-banners-container {
        width: 100%;
        .banner-inner {
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-template-rows: 1fr;
          column-gap: 10px;
        }
      }
    }
  }
}
