.wishlist-mobile-page-main {
  .empty-wishlist-image {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
    width: 250px;
    height: 220px;
  }
  .inner-main {
    background-color: white;
    .first-container {
      top: 0;
      height: 50px;
      font-size: 16px;
      font-weight: bold;
      padding-left: 30px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      background-color: #fafafa;
      .total-items {
        font-size: 14px;
        font-weight: normal;
      }
    }
  }
  .wishlist-List-card {
    background-color: #fff;
    padding-bottom: 55px;
  }
}
