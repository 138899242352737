@import "../mixins.scss";

.customer-payment-desktop {
  $btn-bg: #3866df;
  $btn-color: #ffffff;
  $bg-white: #ffffff;
  .payment-header {
    padding-top: 27px;
    padding-bottom: 27px;

    h3 {
      color: $head-color;
      font-weight: $customer-page-header-weight;
      font-size: $customer-page-header-size;
      margin-bottom: 5px;
    }
    .gray {
      color: $p-color;
      font-size: $customer-page-subtext-size;
      font-weight: $customer-page-subtext-weight;
      margin-bottom: 0px;
    }
  }
  .payment-card {
    width: 380px;
    border-radius: 8px;
    background-color: $bg-white;
    font-size: $customer-page-subheading-size;

    margin-right: 35%;
    margin-left: auto;

    .card-info {
      padding: 24px;
      border-bottom: 1px solid $border-color;

      .card-number-detail {
        .card-number {
          font-size: 1.1rem;
          font-weight: 800;
          color: $head-color;
        }
      }

      .card-expirey-detail {
        margin-top: 46px;
        .card-expirey-date {
          .label {
            margin-right: 10px;
            color: $head-color;
          }

          .date {
            color: $head-color;
            font-weight: 700;
            font-size: 0.8rem;
          }
        }

        .default-card {
          .label-text {
            color: $p-color;
            margin-right: 10px;
            padding-top: 2px;
          }
        }
      }
    }

    .delete-card {
      margin-top: 19px;
      padding-bottom: 21px;

      .delete-button {
        font-weight: 500;
        color: #bf312d;
        border: none;
        background-color: $bg-white;

        svg {
          margin-top: -4px;
          width: 22px;
          height: 22px;
          margin-right: 5px;
        }
      }
    }
  }
}
