@import "../../../variables.scss";
@import "../../../mixins.scss";

.order-summary {
  $title-font-size: 0.85rem;
  $subtitle-font-size: 0.75rem;
  text-align: initial;

  .small-screen-none {
    @include for-mob-screen {
      display: none !important;
    }
  }

  .large-screen-none {
    @include for-desktop-screen {
      display: none !important;
    }
  }

  .title {
    font-size: $title-font-size;
  }

  .subtitle {
    font-size: $subtitle-font-size;
    font-weight: 400;
  }

  .order-total-price {
    font-size: $subtitle-font-size;
  }

  .shipping-fee {
    color: $blue;
    font-weight: 700;
  }
}
