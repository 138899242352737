@import "../../../mixins.scss";
@import "../../../variables.scss";

.image-gallery-mob {
  width: 100%;
  .heading {

    @include for-mob-screen {
    color: #000000;
    font-size: 18px;
    font-family: 'Futura Heavy';
    letter-spacing: 0.3px;
    }
  }
  .heading-ar {
    text-align: right;

    @include for-mob-screen {
    color: #000000;
    font-size: 18px;
    font-family: 'Futura Heavy';
    letter-spacing: 0.3px;
    }
  }
  .mb-1.innerhtml.video-div {
    padding: 0px!important;
  }
  .video-div {
    padding: 25px;
    padding-top: 0px;
  }

  .video-div > iframe {
    width: 100% !important;
    height: 350px !important;
  }
}
