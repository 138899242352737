@import "../mixins.scss";

.wallet-empty-card-area {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  height: 50vh;

  .image-container {
    @include for-mob-screen {
      width: 60%;
    }
  }

  .cart-text {
    color: #404553;
    font-weight: 900;
    font-size: 16px;

    @include for-mob-screen {
      font-size: 13px;
    }
  }
}
.wallet-page-mob-main-container {
  .balance {
    display: flex;
    justify-content: center;
    font-size: 18px;
    font-weight: bolder;
  }
  .wallet-card-lsit-inpage {
  }
}
