@import "../../variables.scss";

.customer-wallet {
  $white-bg: #fcfcf4;
  $table-head-bg: #fcfcf4;
  $table-head-text: #36af00;
  $faded-text: rgb(126, 133, 155);
  .start-margin {
    margin-inline-start: 48px;
  }
  .start {
    text-align: start;
  }
  .title {
    font-weight: 600;
    font-size: 1.1rem;
    color: $faded-black;
  }
  .below-title {
    font-size: 14px !important;
  }

  .wallet-table {
    border: 1px solid #00000024;
    border-bottom: none;
    margin: none;

    .date {
      font-size: 0.85rem;
    }

    .type {
      font-size: 0.9rem;
    }

    .description {
      .main {
        font-size: 0.9rem;
      }

      .sub {
        font-size: 0.85rem;
      }

      .last {
        font-size: 0.8rem;
      }
    }

    .amount {
      font-size: 0.9rem;
    }

    .balance {
      font-size: 0.9rem;
    }

    .table {
      margin-bottom: unset !important;
    }
  }

  .redeem {
    box-shadow: rgb(173, 193, 207) 0px 1px 5px 0px;
    padding: 15px;
    background-color: $white-bg;
  }

  .table-head {
    padding: 10px;
    background-color: $table-head-bg;

    &.upper {
      color: $table-head-text;
    }
  }

  tbody tr td {
    background: #fff;
  }

  .green {
    color: $table-head-text;
    font-weight: 500;
  }

  .red {
    color: red;
    font-weight: 500;
  }

  .gray {
    color: $faded-text;
  }

  .active-tab {
    border-bottom: 3px solid #3866df;
    color: #3866df;
  }
}
