@import "../../mixins";
@import "../../variables";
.edit-profile-mobile {
  height: 85vh;
  a {
    color: rgb(64, 69, 83);
    text-decoration: none;
  }
  .edit-page-values {
    margin-left: 6px;
    margin-right: 6px;
    padding: 15px;
    background: white;

    .title {
      color: rgb(126, 133, 155);
      font-size: 13px;
      font-weight: 500;
      text-align: start;
      margin-top: 15px;
    }

    .input {
      border: none;
      outline: none;
      background: transparent;
      color: rgb(64, 69, 83);
      font-size: 14px;
      font-weight: 600;
      line-height: 2.2;
      width: 100%;
      margin-bottom: 25px;
      margin-top: 10px;
      padding-bottom: 5px;
      border-bottom: 1px solid rgb(226, 229, 241);
      &:hover {
        border: none;
        outline: none;
        border-bottom: 1px solid rgb(226, 229, 241);
      }
      &:focus {
        border: none;
        outline: none;
        border-bottom: 1px solid rgb(226, 229, 241);
      }
    }

    .lang-cont {
      margin-top: 10px;
      margin-bottom: 25px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      border-bottom: 1px solid rgb(226, 229, 241);
      padding-bottom: 0px;

      .input-lang {
        border: none;
        outline: none;
        background: transparent;
        color: rgb(126, 133, 155);
        font-size: 14px;
      }
    }
    .options-cont {
      border: 1px solid rgb(226, 229, 241);
      border-radius: 3px;
      margin-top: -22px;
      background: rgb(241, 243, 250);
      padding: 10px 10px;
    }
  }

  .save-profile-mobile-end {
    position: fixed;
    bottom: 0;
    width: 100%;
    .save-profile-mobile-button {
      background: 0% 0% no-repeat padding-box;
      background-color: $form-btn-bg;
      width: 100%;
      font-weight: bold;
      text-align: center;
      font-size: 1.125rem;
      text-transform: uppercase;
      color: #ffffff;
      padding: 20px 35px 20px;
      text-decoration: none;
      border: none;
    }
  }
}
