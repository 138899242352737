@import "../../../mixins.scss";
.wishlist-list-container-desktop {
  height: auto;
  padding-right: 12px;
  padding-bottom: -10px;
  .wishlist-card-container-desktop {
    &:first-child {
      border-top: 0px;
    }
  }
}
