.building{
.shopbycat{
    background-color: #d4982b;
    border-radius: 25px;
    padding-top: 15px;
    padding-bottom: 15px;
}
.shopbycat h3{
    font-weight: 600;
    text-align: center;
    padding: 0px;
    margin: 0px;
    font-size: 30px;
}
.cat-boxes{
    height: 500px;
    background-color: #333;
margin-top: 15px;
}
.catbox{
padding: 25px!important;
}
.catbox img{
    }
    .catbox    .cattxt {
        text-align: center;
        color: #ffffff;
        font-weight: 600;
        font-size: 30px;
        display: none;
    }
 
.row.catrow{
background-color: #333;
padding-left:70px;
padding-right:70px;
margin-top: 20px;
}
}