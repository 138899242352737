@import "../../../mixins.scss";
@import "../../../variables.scss";

.bb-home-mob {
  max-width: 768px !important;
  margin: auto;
  min-height: 56px;
  // height: 100%;
  .emptyspacermob{
height: 15px;
  }
  .freeshipmob p {
    font-size: 14px!important;
    color: black;
}
.freeshipmob {
  background-color: #d4982b2b;
}
  .img-cont {
    text-align: -webkit-center;
  }
.imgofr {
  background-color: #d9002a;
  height: 65px;
  width: 65px;
  border-radius: 50px;
  margin-bottom: 25px;
}
.imgofr h3 {
  font-size: 10px;
  text-align: center;
  padding-top: 20px;
  color: white;
  padding-bottom: 17px;
  font-weight: 600;
  letter-spacing: 1px;
  animation: blinker 1s step-start infinite;
  text-transform: uppercase;
}
a.nav-link.store-menu {
  
  letter-spacing: 1px;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 600;
    color: #000!important;
    cursor: pointer;
  }
.imgofr span {
  display: block;
  font-size: 11px;
  color: black !important;
  font-weight: 600;
  text-align: center;
  font-family: 'Futura Heavy';
  letter-spacing: 0px;
}
.swiper-wrapper{
  align-items: center;
}
  h2.section-title {
  font-size: 23px;
  font-weight: 700;
}
.img-cont .img img {
  padding: 13px;
}
.swiper-container-multirow .swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets {
  bottom: -5px;
}
.brands-row img{
height: 70vw;
border: 1px solid #9e9e9e52;
}
.section-underline:after {
  bottom: 0px;
}
  .best-sellers {
    background-color: cornsilk;
  }
  .bg-transparent {
    background: transparent;
  }
  .catsbot .ViewAllbtn a {
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
    padding-left: 0.8rem;
    padding-right: 0.8rem;
    font-size: 0.916666rem;
}
.ViewAllbtn {
  font-weight: 400;
}
.ViewAllbtn a {
  padding: 0.625rem 1.25rem;
}
.catsbot-row img {
  border: 1px solid #9e9e9e52;
}
.catsbot-row{
  display: flex;
  overflow-x: scroll;
  a {
    display: block;
    margin-left: 5px;
    margin-right: 5px;
  }
}
  .brands-row {
    display: flex;
    overflow-x: scroll;

    a {
      display: block;
      margin-left: 5px;
      margin-right: 5px;
    }
  }

  .header {
    position: fixed;
    top: 0;
    width: 100%;
    padding: 0px 10px;
    z-index: 10;
    background: white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 10px solid $secon-dary;
    height: 65px;

    .logo {
      display: inline-flex;
      // width: 120px;
      height: 29px;
    }

    .search {
      color: #7d252a;
      font-size: 22px;
      margin-left: 10px;
      margin-right: 10px;
    }

    .cart-col {
      .cart-counter {
        position: absolute;
        top: 6px;
        width: 18px;
        height: 18px;
        border-radius: 100%;
        line-height: 1;
        background-color: #742013;
        color: white;
        font-size: 10px;
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .header-products-slider {
    margin-bottom: 15px;
    font-weight: 700;
    color: #7d252a;
    opacity: 1;
    text-transform: capitalize;
    width: 100%;
    text-align: center;
    padding: 20px 0 0 0px;
    font-size: 18px;
  }

  .body {
    position: relative;
    top: 55px;
    height: calc(100vh - 110px);
    overflow-y: auto;
    padding-bottom: 70px;

    .cross-icon {
      margin-top: 6px;
      margin-right: 5px;
    }

    .getAppRow {
      display: flex;
      padding: 10px;
    }

    .appOpenBtn {
      margin-top: 5px;
      background-color: #bba038;
      color: white;
      text-decoration: none;
      width: 60px;
      text-align: center;
      font-size: 13px;
      border-radius: 4px;
      padding-top: 6px;
      justify-content: flex-end;
      height: 35px;
    }

    .text p {
      font-size: 12px;
      margin: 0px;
    }

    .location-icon {
      color: $p-color;
      font-size: 20px;
      padding-left: 10px;
    }

    .deliver-text {
      color: $head-color;
      font-size: 13px;
      margin-left: 5px;

      .highlighted-text {
        font-weight: bold;
      }
    }

    .homepage-main-banner-swiper {
      // height: 150px;
  
    }

    .img-cat {

    }

    .shop-text {
      color: #7d252a;
      padding-inline-start: 10px;
      font-weight: 700;
      font-size: 1.4333rem;
    }

    .view-text {
      margin-inline-end: 10px;
      font-size: 0.816666rem;
      color: #7d252a;
      font-weight: bold;
      border: 1px solid #7d252a;
      border-radius: 2px;
      padding: 6.5px 5px;
    }

    .swiper-button-prev, .swiper-button-next {
      display: none;
    }

    .category-cont {
      margin-top: 10px;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
      grid-template-rows: repeat(auto-fill);
      gap: 10px 10px;
      padding-inline-start: 10px;
      padding-inline-end: 10px;

      @media screen and (min-width: 320px) and (max-width: 340px) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
      }

      @media screen and (min-width: 340px) and (max-width: 460px) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
      }

      @media screen and (min-width: 460px) and (max-width: 540px) {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      }

      @media screen and (min-width: 540px) and (max-width: 600px) {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
      }

      @media screen and (min-width: 600px) and (max-width: 7680px) {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
      }

      .img-cont {
        width: 100%;

        .img {
          width: 100%;
          height: auto;
          max-height: 120px;
          background-color: #d4982b;
          padding: 10px;
          border-radius: 50%;

          img {
            height: auto;
          }
        }
      }

      .name {
        color: #404553;
        font-size: 9px;
        font-weight: 620;
        text-align: center;
      }
    }

    .product-cont {
      display: flex;
      flex-direction: row;
      max-width: 95%;
      margin-left: auto;
      margin-right: auto;
      overflow-x: auto;
      overflow-y: hidden;
      .image-cont {
        width: 160px;
        max-width: 160px;
        margin-inline-end: 10px;
      }
    }
  }
}
