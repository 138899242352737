$form-btn-bg: #742013;
$secon-dary: #d4982b;

$facebook_btn_bg: #4166b1;

$black: black;

$lightblack: #131313;

$gray: gray;

$lightGray: lightGray;

$green: #36af00;

$blue: #3866de;

$white: white;

$faded-black: rgb(64, 69, 83);

$faded-black-2: #404553;

$head-color: rgb(64, 69, 83);
$p-color: rgb(126, 133, 155);
$border-color: rgb(226, 229, 241);
$blue-header: rgb(56, 102, 223);

$product-box-padding: 12px;
$header-content-width: 100%;
$all-categories-dropdown-width: 278px;

$max-page-width: 1440px;
$page-content-padding: 20px;
$customer-pages-bg-color: rgb(247, 247, 250);
$customer-page-header-size: 1.15rem;
$customer-page-header-weight: 700;
$customer-page-subtext-size: 0.775rem;
$customer-page-subtext-weight: 400;
$customer-page-subheading-size: 0.8rem;
$customer-page-subheading-weight: 600;
$customer-page-button-weight: 700;
$customer-address-heading-size: 1rem;
$header-heading-size: 0.94791875rem;
