.upper-navigation-main {
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 55px;
  width: 100%;
  .backbtn-noon-svg {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .leftarrow {
      margin-inline-start: 20px;
    }
    .noonblack-svg {
      width: 130px;
      height: 29px;
      margin-inline-start: 20px;
    }
  }
  .cancel-span {
    font-size: 0.9rem;
    font-weight: 400;
    padding-inline-end: 20px;
  }
  .search {
    color: #7d252a;
    font-size: 22px;
    padding-inline-end: 20px;
  }
}
