@import "variables.scss";
@import "mixins.scss";

* {
  box-sizing: border-box;
  font-family: "Futura", sans-serif;
}

.change-direction-rtl {
  direction: rtl;
  * {
    font-family: "Cairo" !important;
  }
}

#root {
  position: relative;

  .active-overlay {
    
    /*
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    background: #000;
    opacity: 0.4;
    z-index: 2;
    */
  }

  .no-scroll {
    overflow-y: hidden;
  }

  .App {
    height: 100%;
  }
  height: 100%;
}
@for $i from 1 through 100 {
  @if $i % 5 == 0 {
    .w-#{$i} {
      width: percentage($number: $i/100) !important;
    }
  }
}
@for $i from 100 through 700 {
  @if $i % 100 == 0 {
    .fw-#{$i} {
      font-weight: $i;
    }
  }
}

@media only screen and (min-width: 768px) {
  body {
    font-size: 12px;
  }
}

@media only screen and (min-width: 992px) {
  body {
    font-size: 14px;
  }
}

@media only screen and (min-width: 1440px) {
  body {
    font-size: 14px;
  }
}

body {
  background-color: #ffffff !important;

  .all-page-container {
    max-width: $max-page-width !important;
    padding: 0 $page-content-padding;
  }

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;


  .popover {
    max-width: 100%;
    width: 37%;
    border: none !important;
    border-top: 1px solid lightgray !important;
    border-radius: 0px !important;
    border-top-right-radius: 2px !important;
    border-top-left-radius: 2px !important;
    box-shadow: 0px 3px 6px #00000029;
    padding: 0px !important;
    height: 100px;

    .arrow {
      transform: translate(0px, 0px) !important;
    }

    .popover-body {
      padding: 0px !important;
      .search-popover {
        padding: 0px !important;

        .search-popover-row {
          padding: 0px !important;
          margin: 0px !important;
        }
      }
    }
  }
}
.no-scroll {
  overflow-y: hidden;
}
.font-family-arial {
  font-family: Arial, Helvetica, sans-serif;
}

.spinner-style {
  height: 100%;
  width: 100%;
  display: -webkit-flexbox;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  justify-content: center;
}

.hide-scroll-y {
  overflow-y: hidden;
}

.centered-spinner {
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 99999;
}
