@import "../mixins.scss";

.select-shipping-page-desktop {
  .select-shipping-address-footer {
    width: 100%;
    position: fixed;
    bottom: 0px;

    @include for-md-screen {
      position: static;
      bottom: 0px;
      margin-top: 60px;
    }

    @include for-lg1-screen {
      position: static;
      bottom: 0px;
      margin-top: 60px;
    }

    @include for-lg2-screen {
      position: fixed;
      bottom: 0px;
    }
  }
}
