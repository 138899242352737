.dsspage {
    background-color: white;

    .emptyspacer15 {
        height: 15px;
    }

    .swiper-wrapper {
        gap: 15px;
    }

    .product-card-container-desktop {
        border: 1px solid #ccc;
        padding-top: 10px;
    }

    .product-list-desktop .product-list-container {
        background-color: unset;
    }

    .circlecat {

        // height: 200px;
        // width: 200px;
        border-radius: 100%;
        padding-left: 10px;
        padding-right: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
    }


    .circlecat-paint {
        height: 300px;
        width: 300px;

    }

    .shopbybrand .swiper-slide {
        padding: 15px;
        border-radius: 100%;
        border: 1px solid #ccc;
        text-align: -webkit-center;
        align-content: center;
    }

    .shopbybrand img {
        padding: 20px;
    }
    .catbox a {
        text-decoration: none;
    }

    .club1 {

    }

    .club2 {
        text-align: -webkit-center;
    }

    .bigbox{
        border-radius: 10px;
        img{
            border-radius: 10px !important;
        }
    }

    .product-card-container-desktop {
        border-radius: 10px;
    }

    .surfacepat .product-list-desktop {
        margin: auto;
    }

    .shopbycat {
        padding-top: 15px;
        color: #404553;
    }

    .shopbycat h3 {
        font-family: Futura Heavy;
        text-align: center;
        padding: 0px;
        margin: 0px;
        font-size: 30px;
    }

    .cat-boxes {
        height: 500px;
        background-color: #333;
        margin-top: 15px;
    }

    .catbox {
        text-align: -webkit-center;
    }
    .catbox:hover {
        cursor: pointer;
        box-shadow: 0 0 1rem 0 #c0ccda;
        transition: all .3s ease-in;
    }


    .catbox img {}

    .catbox .cattxt {
        text-align: center;
        color: #005CAC;
        font-weight: 600;
        font-size: 20px;
        margin-top: 15px;

    }

    .row.catrow {
        padding-left: 70px;
        padding-right: 70px;
        margin-top: 25px;
    }

    .row.catrow2 {
        padding-left: 70px;
        padding-right: 70px;
        margin-top: 15px;
    
    }

    .section {
        max-width: 1440px;
        margin: auto
    }

    .brand-swipe-item {
        display: block;
        border: 1px solid gray;
        border-radius: 50%;
        text-align: center;
    }
}