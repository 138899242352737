@import "../../../../mixins.scss";

.shipment-item {
  $brand-font-size: 0.85rem;
  $product-title-font-size: 0.85rem;
  $product-sold-by-font-size: 0.85rem;
  $product-quantity-font-size: 0.7rem;
  $image-max-height: 140px;
  border-bottom: 1px solid $border-color;

  .brand {
    font-size: $brand-font-size;
  }

  .title {
    font-size: $product-title-font-size;
    font-weight: 400;
    color: $gray;
  }
  .name {
    font-size: $product-title-font-size;
    font-weight: 500;
    color: #404553;
  }

  .start-date {
    padding-inline-end: 10px;
    font-size: 0.9rem;
    font-weight: 400;
    color: $gray;

    .date {
      font-size: 0.8rem;
      font-weight: 500;
      color: green;
    }
  }

  .end-date {
    padding-inline-end: 10px;
    font-size: 0.9rem;
    font-weight: 400;
    color: $gray;

    .date {
      font-size: 0.8rem;
      font-weight: 500;
      color: green;
    }
  }

  .sold-by {
    position: absolute;
    bottom: 0;
    font-size: $product-sold-by-font-size;
  }

  .details {
    font-size: $product-sold-by-font-size;

    .text-right {
      .price {
        font-size: $product-title-font-size;
      }
      .quantity {
        font-size: $product-quantity-font-size;
      }
    }
  }

  .image-container {
    border-radius: 0px 0px 0px 4px;
  }

  @include for-md-screen {
    .image-container {
      width: 20%;
      max-width: 20%;
      overflow: hidden;
      height: $image-max-height;

      img {
        background-size: contain;
      }
    }

    .details {
      width: 80%;
    }
  }

  @include for-lg1-screen {
    .image-container {
      width: 15%;
      max-width: 15%;
      overflow: hidden;
      height: $image-max-height;

      img {
        background-size: contain;
      }
    }

    .details {
      width: 85%;
    }
  }

  @include for-lg2-screen {
    .image-container {
      width: 15%;
      max-width: 15%;
      overflow: hidden;
      height: $image-max-height;

      img {
        background-size: contain;
      }
    }

    .details {
      width: 85%;
    }
  }

  @include for-xl-screen {
    .image-container {
      width: 11%;
      max-width: 11%;
      overflow: hidden;
      height: $image-max-height;

      img {
        background-size: contain;
      }
    }

    .details {
      width: 89%;
    }
  }

  @include for-xxl-screen {
    .image-container {
      width: 10%;
      max-width: 10%;
      overflow: hidden;
      height: $image-max-height;

      img {
        background-size: contain;
      }
    }

    .details {
      width: 90%;
    }
  }
}
