.my-account-page-logged-in-mobile {
  background-color: #f6f9ff;
  height: 100%;
  a {
    color: #212529;
    text-decoration: none;
  }
  .hala-user {
    background-color: #f6f9ff;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 12%;
    border-bottom: 2px solid #e1e1e3;
    .noon-image-my-account {
      margin-left: 20px;
    }

    .name-email {
      display: flex;
      flex-direction: column;
      margin-inline-start: 20px;
      text-align: start;

      .name {
        font-size: 17px;
        font-weight: bold;
        color: #414852;
        opacity: 1;
      }
      .user-email {
        font-size: 13px;
        color: #595c65;
        opacity: 1;
      }
    }
  }
  .logged-in-menu {
    padding-top: 13px;
    padding-bottom: 10px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    background-color: white;
    border-bottom: 2px solid #e1e1e3;

    .div-circle {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      background-color: #feef00;
      width: 70px;
      height: 70px;
    }
    .align-center-all {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .orders {
    }
    .returns {
    }
    .noon-credits {
    }
    .wishlist {
    }
    .auth-label {
      padding-top: 4px;
      color: #474c5a;
      font-weight: 500;
      font-size: 13px;
      font-weight: bold;
    }
  }
  .scrollable-my-account {
    background-color: #f6f9ff;
    height: 100%;
    .app-experience {
      background-color: #f6f9ff;
      .app-experience-label {
        background-color: #f6f9ff;
        opacity: 0.8;
        color: #767d9f;
        font-weight: bold;
        font-size: 1.25rem;
        margin-left: 15px;
        height: 70px;
        display: flex;
        align-items: center;
      }
      .inner-main {
        background-color: #fff;
        .inner-items {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-left: 15px;
          margin-right: 15px;
          background-color: white;
          height: 90px;
          border-bottom: 1px solid #e1e1e3;

          .left-pan-app-exp {
            display: flex;
            width: 500px;
            height: inherit;
            justify-content: right;
            align-items: center;
            .left-icon-app-exp {
              margin-top: 10px;
              margin-bottom: 10px;
            }
            .title-app-exp {
              display: flex;
              flex-direction: column;
              .u-title {
                color: #474c59;
                font-weight: bold;
                font-size: 1.5rem;
              }
              .extended-info {
                font-weight: 1rem;
              }
            }
          }
          .right-pan-forward-icon-app-exp {
            display: flex;
          }
        }
      }
    }
    .my-account-list {
      background-color: #f6f9ff;
    }
    .account-setting-list {
      background-color: #f6f9ff;
    }
    .my-account-reachOut-list {
      background-color: #f6f9ff;
    }
    .sout-main {
      background-color: #f6f9ff;

      .signout {
        background-color: #f6f9ff;

        display: flex;

        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        height: 60px;
        margin-left: 15px;
        margin-right: 15px;

        border-bottom: 2px solid #e1e1e3;

        .s-out-icon {
        }
        .signout-label {
          margin-inline-start: 30px;
          font-size: 14px;
          color: #686b74;
          font-weight: 500;
        }
      }
    }
  }
  .bottom-nav-comp {
    background-color: white;
    margin-top: 10px;
    border-top: 2px solid #e1e1e3;
  }
}
