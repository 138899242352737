
.home-appliances{
    background-color: white;
    .pagesbanner{
        padding-top: 15px;
        padding-bottom: 30px;
    }
    .sbc{
        background-color: #d0e6f9;
        border-radius: 100%;
        padding: 10px;
    }
    .cleancat{
        background-color: #d1fbc29f;
        border-radius: 10px;
    }
    .topofferimg{
width: 175px;  
    -webkit-transform: rotate(362deg);
}
.offers.catbox{
padding: 15px;
}
.six6.catsecpic img {
    border: 1px solid #9e9e9e52;
}
.surfacepat1 .product-list-container{
background-color: unset!important;
}


.category-content{
    background-color: #f8f8f8;
    padding-left: 20px;
    padding-right: 20px;
    padding-top:20px;
    
    p{
        font-size: 14px!important;
        line-height: unset;
        margin-top: unset;
        text-align: justify;
    }
    h2{
        font-size: 22px;
        font-weight: 600;
    }
    h3{
        font-size: 20px;
    }
}
    .catrow{
        display: flex;

    }
    .six6 img {
      border: none;
    }
    .catbox {
        padding: 0px;
    }
    .titlewrap{
    background-color: #ffffff !important;
    padding-top: 15px;
    padding-bottom: 5px;
    }
    .offerbg{
        background-color: #ffffff;
    border-bottom: 140px solid #f8e89a;
    border-left: 23px solid transparent;
    border-right: 14px solid transparent;
    height: 0;
    border-radius: 50px;
    margin-top: 30px!important;
    margin: 15px;
    transform: rotate(2deg);
    -webkit-transform: rotate(358deg);
    -moz-transform: rotate(2deg);
    text-align: right;
    }
.sectiontitle-individualpage{
    margin-bottom: 0px;
    text-align: center;
    color: #000000;
    font-weight: 600;
}
.titlewrap.noclr{
    background-color: #ffffff;
}
.offertitles{
    text-align: left;
    float: left;
    width: 170px;
    font-family: 'Futura heavy';
    margin-top: 29px;
    font-size: 35px;
    margin-left: 8PX;
    text-transform: uppercase;
    -webkit-transform: rotate(362deg);
    line-height: 0.9;
    /* -webkit-text-stroke: 1px white; */
    -webkit-text-stroke: thin;
}
span.offerprice {
    font-family: 'Futura heavy';
    margin-top: 29px;
    color: #ff5722;
}
    .mypages-container {
        margin-right: auto!important;
        margin-left: auto!important;
        padding-left: 10px!important;
        padding-right: 10px!important;
        width: 1300px;
    }

    @media (min-width: 1360px){
.container {
    width: 1300px;
}}

@media (min-width: 1280px){
.container {
    width: 1220px;
}}

@media (min-width: 1170px){
.container {
    width: 1024px;
}}

@media (min-width: 992px){
.container {
    width: 970px;
}}
@media (min-width: 768px){
.container {
    width: 750px;
}}
}

.img-img-fluid1 {
    padding-right: 40px;
}

.img-img-fluid2 {
    padding-Left: 70px;
    padding-right: 40px;
}
.megadeal{
    padding-bottom: 30px;
    background-size: cover;
    background-image: url("../../../assets/images/offers-landing-pages/bi.png");

}
.img1 {
    padding-left: 175px;
    margin-block-start: 175px;
    height: 275px
}
.img2 {
    padding-left: 175px;
    margin-block-start: 10px;
    height: 275px
}
.img3 {
    padding-left: 175px;
    margin-block-start: 10px;
    height: 275px
}

.grid-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr); 
    gap: 10px; 
  }
  
  .grid-item {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .grid-item img {
    width: 100%;
    height: auto;
  }

.img-fluid {
    height: 260px; 
}
.custom-image {
    width: 100%;
    height: auto;
    max-height: 300px;
}

.row.justify-content-center > .col-md {
    padding-right: 0;
    padding-left: 0;
  }
.img-fluid {
    height: auto;
}

.banner{
    height: 900px;
    padding-bottom: 20px ;
    background-size: cover;
    background-image: url("../../../assets/images/offers-landing-pages/b1.png");

}
.banner .row.justify-content-center [class^='col-'] {
    padding: 0;
}
// .productborder {
//    border-style: groove;
//    border-radius: 8px;
//}
  



