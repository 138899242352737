@import "../mixins";
.emptyspacer{
  height: 30px;
}

.product-category-list-container.d-xl-flex.mx-auto .swiper-wrapper{
      gap: 10px;
}
.product-category-container {
  cursor: pointer;

  .product-category-image-container {
    height: auto;
    // margin-bottom: 10px;
    overflow: hidden;
    padding: 5px 15px;
    background: #DADADA;
    border-radius: 10px;
    // background-color: #DADADA;
    // padding: 5px;
    // border-radius: 5px;
    // padding: 10px;

    @include for-md-screen {

   
    }

    @include for-lg1-screen {
      // width: 136px;
   
  
    }
    @include for-lg2-screen {
      // width: 146px;

  
    }
    @include for-xl-screen {
      // width: 156px;
   
  
    }
    @include for-xxl-screen {
      // width: 166px;
   
  
    }
    width: unset;
    height: 197px;

    img {
      width: 100%;
    }

    a {
      text-decoration: none;
    }
  }

  .product-category-type-container {
    width: 100%;

    .product-category-type {
      white-space: nowrap;
      text-align: center;
      font-size: 0.8125rem;
      font-weight: 700;
      color: $head-color;
      text-transform: uppercase;
    }
  }
}
