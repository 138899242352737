@import "../../mixins.scss";

.customer-sidebar {
  font-size: 17px;
  $font-color: rgb(64, 69, 83);
  $icon-dimension: 28px;

  .top {
    border-bottom: 1px solid rgb(241, 243, 249);
    padding-bottom: 24px;
    text-align: start;
    .greeting,
    .signout {
      color: $font-color !important;

      a {
        color: $font-color !important;
      }
    }

    .greeting {
      font-size: 0.9em;
    }

    .signout {
      font-size: 0.65em;
    }
  }

  .sidebar-menu-item {
    cursor: pointer;
    font-size: 0.75em;
    color: rgb(64, 69, 83);
    width: 206px;

    @include for-md-screen {
      width: 176px;
    }
    @include for-lg1-screen {
      width: 206px;
    }

    a {
      text-decoration: none;
      color: unset;
    }
    .inner-item {
      padding: 12px 15px;
    }

    .icon {
      border-radius: 100%;
      background-color: rgb(247, 247, 250);
      font-size: 1.4em;
      margin-inline-end: 8px;

      height: $icon-dimension;
      width: $icon-dimension;
      max-width: $icon-dimension;
      max-height: $icon-dimension;

      svg {
        max-width: $icon-dimension;
        max-height: $icon-dimension;
      }
    }

    &.selected {
      background-color: white;
      box-shadow: 0px 2px 10px 0px rgba(173, 193, 207, 0.25);
    }

    &.unselected {
      color: rgb(126, 133, 155);

      .icon {
        background-color: white;
      }
    }

    &:hover {
      background-color: white;
    }
  }
}
