@import "../../mixins.scss";

.review-mobile {
  width: 100%;

  .review-container {
    background-color: white;

    .review-header {
      text-align: start;
      padding: 15px 20px;
      background-color: #f0f3fa;
      color: $head-color;
      font-size: 1rem;
      font-weight: 600;
    }

    .review-body {
      text-align: start;
      .item-header-text {
        padding: 10px 20px;
        font-size: 1rem;
        font-weight: 500;
        color: $head-color;
      }
      .item-container {
        margin: 0 20px;
        width: calc(100% - 40px);
        border: 1px solid $border-color;
        padding: 10px 5px;

        .image-container {
          width: 80px;
          height: 80px;
        }

        .description-container {
          width: calc(100% - 80px);
          padding-inline-start: 10px;

          .text {
            margin-bottom: 4px;
            text-transform: capitalize;
            font-size: 1rem;
            font-weight: 400;
            color: $head-color;
          }

          .delivered-by {
            font-size: 1rem;
            font-weight: 400;
            color: rgb(58, 174, 3);
          }
        }
      }

      .store-info-row {
        width: calc(100% - 40px);
        margin: 10px 20px;
        padding-bottom: 10px;
        border-bottom: 1px solid $border-color;

        .store-info {
          .text {
            font-size: 1rem;
            font-weight: 400;
            color: $head-color;
          }

          .store-icon {
            margin: 0 10px;
            width: 30px;
            height: 30px;
            background-color: #f0f3f8;
            border-radius: 100%;
          }

          .store-name {
            font-size: 0.9rem;
            font-weight: 600;
            color: $head-color;
          }
        }
      }

      .store-review-row {
        padding: 10px 20px;

        .text {
          font-size: 1rem;
          font-weight: 600;
          color: $head-color;
          text-align: center;
          margin-top: 10px;
        }

        .rating {
          width: fit-content;
          margin-top: 5px;

          .MuiRating-root {
            margin-top: -5px;
            margin-left: -10px;

            .MuiRating-decimal {
              margin-top: 5px;
              margin-left: 10px;
            }
          }
        }
      }

      .feedback-row {
        padding: 10px 20px;

        .feedback-col {
          padding-right: 10px;

          .feedback-header-row {
            .header-text {
              font-size: 0.9rem;
              font-weight: 600;
              color: $head-color;
              margin-bottom: 5px;
            }
          }
          textarea {
            border: 2px solid $border-color;
            border-radius: 2px;
            outline: none;
            box-shadow: none;
            padding: 3px 10px;
          }
        }
      }

      .radio-buttons-group-row {
        padding: 5px 10px;

        .toggle-button {
          margin-bottom: 20px;

          .text {
            margin-left: 10px;
            font-size: 1rem;
            font-weight: 400;
            color: $head-color;
          }
        }
        .item-row {
          width: 70%;
          border-bottom: 1px solid $border-color;
          margin-bottom: 10px;

          .radio-text {
            margin-left: 10px;
            font-size: 1rem;
            font-weight: 600;
            color: $head-color;
            margin-bottom: 8px;
          }
          .radio-button {
            margin-bottom: 10px;
            margin-left: 15px;

            .circle {
              width: 15px;
              height: 15px;
              border: 1px solid $border-color;
              border-radius: 100%;
              margin-right: 7px;
            }
            .label {
              margin-bottom: -2px;
            }
          }
        }
        .submit-button-row {
          .submit-button {
            background-color: $form-btn-bg;
            color: white;
            font-size: 1rem;
            font-weight: 600;
            border: none;
            outline: none;
            box-shadow: none;
            border-radius: 4px;
            text-align: center;
            width: 230px;
            height: 44px;
          }
          padding-bottom: 15px;
        }
      }
    }
  }
}
