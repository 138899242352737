@import "../../../mixins.scss";
@import "../../../variables.scss";

.items-sidebar-desktop {
  width: 100%;


  .small-screen-none {
    @include for-mob-screen {
      display: none;
    }
  }

  .large-screen-none {
    @include for-desktop-screen {
      display: none !important;
    }
  }

  .sidebar-items-cont {
    margin-left: 0;
    margin-right: auto;
    margin-top: 55px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 16px;
    margin-bottom: 20px;
    @include for-mob-screen {
      margin-top: 0px;
      margin-bottom: 0px;
      height: 70px;
      border-bottom: 1px solid rgb(243, 244, 248);
    }
  }

  .sidebar-heading {
    text-align: left;
    font-size: 1.1rem;
    font-weight: bold;
    color: #404553;
    opacity: 1;
  }

  .clear-style {
    font-size: 11px;
    color: rgb(56, 102, 223);
    padding: 0px 10px;
  }

  .sidebar-checkbox-cont {
    display: flex;
    width: 100%;

    margin-left: auto;
    margin-right: auto;
  }

  @include for-mob-screen {
    .number-input {
      border: 1px solid #e4f7db;

      font-size: 13px;
      width: 40%;
      height: 41px;
      padding-left: 10px;
      &:hover {
        border: none;
      }
    }
  }
  @include for-md-screen {
    .number-input {
      border: 1px solid #e4f7db;

      font-size: 11px;
      width: 30%;
      height: 41px;
      padding-left: 10px;
      &:hover {
        border: none;
      }
    }
  }
  @include for-lg1-screen {
    .number-input {
      border: 1px solid #e4f7db;
      font-size: 11px;
      width: 30%;
      height: 41px;
      padding-left: 10px;
      &:hover {
        border: none;
      }
    }
  }

  @include for-lg2-screen {
    .number-input {
      border: 1px solid #e4f7db;
      font-size: 11px;
      width: 30%;
      height: 41px;
      padding-left: 10px;
      &:hover {
        border: none;
      }
    }
  }

  @include for-xl-screen {
    .number-input {
      border: 1px solid #e4f7db;
      font-size: 12px;
      width: 28%;
      height: 41px;
      padding-left: 10px;
      &:hover {
        border: none;
      }
    }
  }
  @include for-xxl-screen {
    .number-input {
      border: 1px solid #e4f7db;
      font-size: 12px;
      width: 28%;
      height: 41px;
      padding-left: 10px;
      &:hover {
        border: none;
      }
    }
  }
  .number-cont {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .go-style {
    text-align: left;
    font-size: 12px;
    font-weight: bold;
    color: #3866df;
    opacity: 1;
  }

  .text-style {
    font-size: 12px;
    color: #7e859b;
  }
  .range-slider-cont {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  input[type="range"] {
    -webkit-appearance: none;
    width: 100%;
  }

  input[type="range"]:focus {
    outline: none;
  }

  input[type="range"]::-webkit-slider-runnable-track {
    width: 100%;
    height: 8.4px;
    cursor: pointer;
    background: $lightGray;
    border-radius: 10px;
  }

  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    border: none;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background: $blue;
    cursor: pointer;
    margin-top: -4px;
  }

  input[type="range"]:focus::-webkit-slider-runnable-track {
    background: $lightGray;
  }

  input[type="range"]::-moz-range-track {
    width: 100%;
    height: 8.4px;
    cursor: pointer;
    background: $lightGray;
    border-radius: 10px;
  }

  input[type="range"]::-moz-range-thumb {
    border: none;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background: $blue;

    margin-top: -4px;
    cursor: pointer;
  }

  input[type="range"]::-ms-track {
    width: 100%;
    height: 8.4px;
    cursor: pointer;
    background: $lightGray;
    border-radius: 10px;
  }

  input[type="range"]::-ms-thumb {
    border: none;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background: $blue;

    margin-top: -4px;
    cursor: pointer;
  }

  .range-slider-style {
    width: 100%;
  }

  .rating-range {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .rating-value {
    text-align: left;
    font-size: 13px;

    color: #3866df;
  }
  .rating-range-value {
    text-align: left;
    font-size: 11px;
    color: #b2b8ca;
    opacity: 1;
  }
}

.modal-dialog {
  @include for-mob-screen {
    margin: 0px;
    height: 100%;
  }
}

.modal-content {
  @include for-mob-screen {
    border: none;
    border-radius: 0px;
    height: 100%;
  }
}

.modal-header-mob {
  @include for-mob-screen {
    height: 55px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;
    border-bottom: 1px solid rgb(243, 244, 248);
  }
}
