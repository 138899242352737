@import "../../../../mixins.scss";

.shipment-item-mobile {
  display: flex;
  flex-direction: row;
  padding: 20px 8px 20px 8px;
  border-bottom: 1px solid #f3f3f5;

  .order-detail {
    display: flex;
    flex-direction: column;
    text-align: start;
    width: 70%;
    .brand {
      font-size: 13px;
    }
  }
  .title {
    margin-top: 10px;
    color: #404553;
    font-size: 12px;
    font-weight: 600;
  }

  .price {
    margin-top: 10px;
    color: #404553;
    font-size: 17px;
  }

  .qty {
    margin-top: 7px;
    color: #a7a8ad;
    font-size: 11px;
    font-weight: 500;
  }

  .sold-text {
    margin-top: 10px;
    color: #404553;
    font-size: 12px;
  }

  .image-container {
    align-self: center;
    width: 70px;
    height: 70px;
    margin-inline-start: auto;
  }
}
