@import "../../mixins.scss";

.customer-profile {
  $head-color: rgb(64, 69, 83);
  $p-color: rgb(126, 133, 155);
  $border-color: rgb(226, 229, 241);
  text-align: start;
  padding-top: 27px;
  .bottom-red {
    border-bottom: 1px solid red !important;
  }
  .error {
    color: red;
    font-size: 13px;
    margin-top: -50px;
    margin-bottom: 50px;
  }
  h3 {
    color: $head-color;
    font-size: $customer-page-header-size;
    font-weight: $customer-page-header-weight;
    margin-bottom: 5px;
  }

  .gray-text {
    font-size: $customer-page-subtext-size;
    color: $p-color;
  }

  .profile-card {
    box-shadow: 0px 0px 4px #00000029;
    border-radius: 4px;
    margin-bottom: 15px;

    .header {
      text-align: start;
      background-color: #f1f3fa;
      padding: 10px 15px;

      h4 {
        margin-bottom: 0px;
        font-size: $customer-page-subheading-size;
        font-weight: $customer-page-subheading-weight;
        color: $head-color;
      }
    }

    .profile-body {
      background-color: #ffffff;
      padding: 15px;

      .profile-field {
        margin-bottom: 20px;
        .first-name {
          text-align: start;
          label {
            margin-bottom: 0px;
            color: $p-color;
            font-size: $customer-page-subtext-size;
            font-weight: $customer-page-subtext-weight;
          }

          input {
            border-bottom: 1px solid $border-color;
            outline: none;
            border-top: none;
            border-left: none;
            border-right: none;
            padding: 0px;
            height: 21px;
            &::placeholder {
              font-size: 12px;
            }
            &:disabled {
              background-color: white;
            }
          }
        }

        .last-name {
          text-align: start;
          label {
            margin-bottom: 0px;
            color: $p-color;
            font-size: $customer-page-subtext-size;
            font-weight: $customer-page-subtext-weight;
          }

          input {
            border-bottom: 1px solid $border-color;
            outline: none;
            border-top: none;
            border-left: none;
            border-right: none;
            padding: 0px;
            height: 21px;
            &::placeholder {
              font-size: 12px;
            }
            &:disabled {
              background-color: white;
            }
          }
        }

        .prefered-language {
          label {
            margin-bottom: 0px;
            color: $p-color;
            font-size: $customer-page-subtext-size;
            font-weight: $customer-page-subtext-weight;
            position: absolute;
            top: -7px;
            z-index: 1;
          }
        }

        .email {
          text-align: start;
          label {
            margin-bottom: 0px;
            color: $p-color;
            font-size: $customer-page-subtext-size;
            font-weight: $customer-page-subtext-weight;
          }

          input {
            border-bottom: 1px solid $border-color;
            outline: none;
            border-top: none;
            border-left: none;
            border-right: none;
            padding: 0px;
            height: 21px;
            &::placeholder {
              font-size: 12px;
            }
            &:disabled {
              background-color: white;
            }
          }
        }

        .password {
          text-align: start;
          label {
            margin-bottom: 0px;
            color: $p-color;
            font-size: $customer-page-subtext-size;
            font-weight: $customer-page-subtext-weight;
          }

          input {
            border-bottom: 1px solid $border-color;
            outline: none;
            border-top: none;
            border-left: none;
            border-right: none;
            padding: 0px;
            height: 21px;
            &::placeholder {
              font-size: 12px;
            }
            &:disabled {
              background-color: white;
            }
          }
        }
      }

      .button {
        margin-top: 10px;

        button {
          background: #ffffff;
          border: 1px solid $border-color;
          border-radius: 4px;
          padding: 13px 24px;
          color: $head-color;
          font-size: $customer-page-subheading-size;
          font-weight: $customer-page-button-weight;
          text-transform: uppercase;
          text-align: center;
          width: 210px;
        }
      }
    }
  }

  .general-information {
    margin-top: 27px;
  }
}
