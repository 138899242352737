
.home-appliances{
    background-color: white;
    .pagesbanner{
        padding-top: 15px;
        padding-bottom: 30px;
    }
    .sbc{
        background-color: #d0e6f9;
        border-radius: 100%;
        padding: 10px;
    }
    .topofferimg{
width: 175px;  
    -webkit-transform: rotate(362deg);
}
.offers.catbox{
padding: 15px;
}
.six6.catsecpic img {
    border: 1px solid #9e9e9e52;
}
.surfacepat1 .product-list-container{
background-color: unset!important;
}
.category-content{
    background-color: #f8f8f8;
    padding-left: 20px;
    padding-right: 20px;
    padding-top:20px;
    
    p{
        font-size: 14px!important;
        line-height: unset;
        margin-top: unset;
        text-align: justify;
    }
    h2{
        font-size: 22px;
        font-weight: 600;
    }
    h3{
        font-size: 20px;
    }
}
    .catrow{
        display: flex;

    }
    .six6 img {
      border: none;
    }
    .catbox {
        padding: 0px;
    }
    .titlewrap{
    background-color: unset!important;
    padding-top: 15px;
    padding-bottom: 5px;
    }
    .offerbg{
        background-color: #ffffff;
    border-bottom: 140px solid #f8e89a;
    border-left: 23px solid transparent;
    border-right: 14px solid transparent;
    height: 0;
    border-radius: 50px;
    margin-top: 30px!important;
    margin: 15px;
    transform: rotate(2deg);
    -webkit-transform: rotate(358deg);
    -moz-transform: rotate(2deg);
    text-align: right;
    }
.sectiontitle-individualpage{
    margin-bottom: 0px;
    text-align: center;
    color: #000000;
    font-weight: 600;
}
.titlewrap.noclr{
    background-color: #ffffff;
}
.offertitles{
    text-align: left;
    float: left;
    width: 170px;
    font-family: 'Futura heavy';
    margin-top: 29px;
    font-size: 35px;
    margin-left: 8PX;
    text-transform: uppercase;
    -webkit-transform: rotate(362deg);
    line-height: 0.9;
    /* -webkit-text-stroke: 1px white; */
    -webkit-text-stroke: thin;
}
span.offerprice {
    font-family: 'Futura heavy';
    margin-top: 29px;
    color: #ff5722;
}
    .mypages-container {
        margin-right: auto!important;
        margin-left: auto!important;
        padding-left: 10px!important;
        padding-right: 10px!important;
        width: 1300px;
    }

    @media (min-width: 1360px){
.container {
    width: 1300px;
}}

@media (min-width: 1280px){
.container {
    width: 1220px;
}}

@media (min-width: 1170px){
.container {
    width: 1024px;
}}

@media (min-width: 992px){
.container {
    width: 970px;
}}
@media (min-width: 768px){
.container {
    width: 750px;
}}
}
.image-1 {
  padding-left: 50px;
}
.image-2 {
    padding-right: 50px;
}
.image-3 {
    padding-left: 50px;
}
.image-4 {
    padding-right: 50px;
}
.image-5 {
    padding-left: 50px;
}
.image-6 {
    padding-right: 50px;
}
.custom-image-1 {
    padding-left: 40px;
    padding-right: 5px;
}
  .row1freeinfo{
    display: flex;
  }
.custom-image-2 {
    padding-right: 40px;
    padding-left: 5px;
}
.elecsale{
    margin-top: 20px!important;

}
.banner{
    height: 400px;
    padding-bottom: 40px ;
}

 .product-card-mob{
    border-radius: 7px;
    padding-bottom: 7px;
    padding-left: 5px;
    border: 1px solid #777777;
    padding-right: 5px;
    padding-top: 5px;
}