.contact-us-main {
  .heading {
    margin-top: 50px;
    font-size: 23px;
    font-weight: bold;
  }
  .body {
    font-size: 14px;
  }
  .form {
    margin-top: 60px;
    width: 35%;
    .form-heading {
      color: #7c242c;

      text-decoration: underline;
      margin-bottom: 20px;

      font-size: 22px;
      font-weight: bold;
    }
    .input-cont {
      width: 47%;
      .label {
        font-size: 14px;
        color: #7c242c;
        font-weight: 550;
        text-align: start;
      }
      input {
        outline: none;
        border: 2px solid black;
        padding: 0px 10px;
        height: 40px;
        font-size: 14px;
        width: 100%;
        border-radius: 10px;
        background-color: transparent;
      }
    }
    .message-cont {
      margin-top: 15px;
      width: 100%;
      .label {
        font-size: 14px;
        color: #7c242c;
        font-weight: 550;
        text-align: start;
      }
      textarea {
        outline: none;
        border-left: 5px solid #7c242c;
        border-right: 5px solid #7c242c;
        background-color: transparent;
        border-top: 3px solid #7c242c;
        border-bottom: 3px solid #7c242c;
        padding: 10px;
        width: 100%;
        height: 150px;
        font-size: 14px;
        border-radius: 8px;
      }
    }
    .submit-button {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 20px;
      margin-bottom: 20px;
      font-weight: bold;
      font-size: 17px;
      height: 40px;
      border-radius: 8px;
      width: 80%;
      color: white;
      background: #7c242c;
    }
  }
}
