@import "../../../mixins.scss";

.customer-address-mobile {
  max-width: 768px;
  background-color: #fafafa !important;

  .header {
    padding: 10px 20px;

    .img {
      margin-left: 30px;
      width: 45%;
      height: 12%;
    }
  }
}
