@import "./variables.scss";

.pointer {
  cursor: pointer;
}

.custom-card {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 6px #00000024;
  border: 1px solid #7070704d;
  border-radius: 8px;
  opacity: 1;
}

.custom-card-2 {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 6px #00000024;
  border-radius: 4px;
  opacity: 1;
}

.vertical-separator {
  border-bottom: 1px solid #f3f3f5;
}

.gray-text {
  color: $gray;
}
.mobile-address-style {
  margin-inline-start: 24px;
  text-align: start;
}
.gray-text-2 {
  color: #7e869b;
}

.vertical-align-middle {
  vertical-align: middle;
}

.faded-black-2-text {
  text-align: start;
  color: $faded-black-2;
}

.product-box-padding {
  padding: $product-box-padding;
}
