@import "../../../mixins.scss";
@import "../../../variables.scss";

.image-gallery {
  width: 100%;
  .heading {
    font-size: 25px;
    font-weight: 600;
    margin-top: 30px;
    border-top: 1px solid #ccc;
    padding-top: 10px;

  }

 .mt-1.mb-2 {
  text-align: center;
}
.mb-1.innerhtml.video-div {
  text-align: center;
}
}
