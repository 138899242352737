@import "../mixins.scss";

.paymment-empty-mob-page {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 65vh;

  .img-cont {
    @include for-mob-screen {
      width: 60%;
    }
  }

  .text-uper {
    text-align: center;
    color: #404553;
    font-weight: bold;
    font-size: 16px;

    @include for-mob-screen {
      font-size: 13px;
      width: 90%;
    }
  }
  .lower-text {
    text-align: center;
    color: #404553;
    font-size: 15px;

    @include for-mob-screen {
      font-size: 13px;
      width: 90%;
    }
  }

  .bottomNavigation {
    width: 100%;
    position: absolute;
    bottom: 0;
    max-width: 768px;
    overflow: hidden;
  }
}
