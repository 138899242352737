@import "../mixins.scss";
@import "../variables.scss";

  .emptyspace{
    margin-top: 30px;
  }
 .header {
    margin-bottom: 15px;
    font-weight: 700;
    color: #7d252a;
    opacity: 1;
    text-transform: capitalize;
    width: 100%;
    text-align: center;
    padding: 20px 0 0 0px;
    font-size: 23px;
}