@import "../../assets/styles/mixins.scss";

.forget-modal {
  background-color: white;
  border-radius: 8px;
  width: 500px;

  .header-row {
    position: relative;
    padding: 15px;

    .back-col {
      svg {
        font-size: 1rem;
        font-weight: 400;
      }

      .back-text {
        margin-left: 5px;
        font-size: 1rem;
        font-weight: 500;
      }
    }

    .close-col {
      position: absolute;
      top: 5px;
      right: 5px;

      svg {
        font-size: 2rem;
      }
    }
  }

  .boday-row {
    padding: 10px 30px 30px;
    border-bottom: 2px solid $border-color;

    .title-row {
      font-size: 1.7rem;
      font-weight: 700;
      color: $head-color;
      text-align: center;
      margin-bottom: 10px;
    }

    .guide-text-row {
      font-size: 1rem;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.6);
      text-align: center;
      width: 90%;
      margin: 0 auto;
    }

    .input-row {
      margin-bottom: 45px;

      .label {
        font-size: 1rem;
        font-weight: 400;
        text-transform: capitalize;
        color: $p-color;
        width: fit-content;
        margin-top: 40px;
      }

      input {
        border: none;
        box-shadow: none;
        outline: none;
        border-bottom: 2px solid $border-color;
        padding: 5px;
        width: 100%;
      }
    }
  }

  .button-row {
    .submit-button {
      margin: 20px 0px;
      padding: 0;
      width: fit-content;
      border: none;
      box-shadow: none;
      background-color: white;
      color: #3c6adc;
      text-transform: uppercase;
      font-size: 1.4rem;
      font-weight: 500;
    }
  }
}
