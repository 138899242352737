@import "../../mixins.scss";

.orderslist-page {
  $head-color: rgb(64, 69, 83);
  $p-color: rgb(126, 133, 155);
  $border-color: rgb(226, 229, 241);

  .back-col {
    margin-top: 20px;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    .icon {
      font-size: 2rem;
      font-weight: 400;
      color: $head-color;

      svg {
        font-size: 1rem;
      }
    }

    .text {
      margin-inline-start: 10px;
      display: flex;
      align-self: center;
      font-size: 0.9rem;
      font-weight: 400;
      color: $head-color;
    }
  }

  .oreder-container {
    background-color: white;
    margin-bottom: 10px;
    border: 1px solid $border-color;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);

    a {
      text-decoration: none;
      color: unset;
    }

    .review-button {
      display: flex;
      align-self: flex-start;
      align-items: center;
      background-color: rgb(241, 244, 251);
      color: rgb(97, 133, 228);
      border: none;
      box-shadow: none;
      outline: none;
      border-radius: 4px;
      margin: 5px;
      font-size: 0.77rem;
    }
  }
}
