@font-face {
  font-family: "Futura";
  src: local("Futura"), url(../fonts/SFUIDisplay-Light.ttf) format("truetype");
}
@font-face {
  font-family: "Futura Heavy";
  src: local("Futura Heavy"),
    url(../fonts/SFUIDisplay-Bold.ttf) format("truetype");
}
@font-face {
  font-family: "Futura Medium";
  src: local("Futura Medium"),
    url(../fonts/futura\ medium\ bt.ttf) format("truetype");
}
* {
  box-sizing: border-box;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
}
