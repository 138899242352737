.return-item-mobile {
  $black-text: #4b4b50;
  $gray: #84848e;
  $shadow: 0px 2px 6px #00000024;
  box-shadow: $shadow;
  background-color: #ffffff;

  .header {
    background-color: #f5f8ff;
    box-shadow: $shadow;
    text-align: start;
    font-size: 13px;

    .id-row {
      font-weight: 700;
    }

    .date-row {
      color: $gray;
    }
  }

  .pickup-address {
    box-shadow: $shadow;
    background-color: #ffffff;
    text-align: start;
    font-size: 13px;

    .title {
      font-weight: 700;
      color: $black-text;
    }

    .name {
    }

    .address {
      color: $gray;
    }
  }

  .return-details {
      font-size: 12px;
    text-align: start;
    .status {
      font-weight: 600;
    }
    .reason {
      font-weight: 600;
    }
  }
}
