@import "../mixins.scss";

.address-form-page {
  background-color: #f7f7fa;

  .address-form-row {
    margin-bottom: 100px;
    width: 100%;
    max-width: 1440px;
    padding: 20px 45px 0px;

    @include for-md-screen {
      padding: 20px 30px 0px;
    }

    @include for-lg1-screen {
      padding: 20px 45px 0px;
    }
  }

  .add-address-page-footer {
    width: 100%;
  }
}
