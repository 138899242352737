@import "../mixins";

.product-list-desktop {
  width: 100%;
  max-width: 1440px;

  .product-list-container {
    width: 100%;
    // background-color: #ffffff;
    padding: 10px 10px 10px 0px;
    margin: auto;
    .swiper-container {
      width: 100%;
      .swiper-wrapper {
        .swiper-slide {
          width: 220px !important;
          // padding-left: 10px;
        }
      }
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    border-radius: 50%;
    background: rgb(0, 0, 0);
    height: 40px;
    width: 40px;
    color: white;
    margin-right: 20px;
  }
  .swiper-button-next.swiper-button-disabled,
  .swiper-button-prev.swiper-button-disabled {
    display: none !important;
    cursor: auto;
    pointer-events: none;
  }
  .swiper-button-next:after,
  .swiper-button-prev:after {
    font-size: 1.5rem;
  }

  .swiper-pagination {
    display: none;
  }
}
