@import "../../../mixins.scss";
@import "../../../variables.scss";

.profile-map-footer-desktop {
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 80px;

  padding: 20px 0px;
  margin-top: 20px;

  @include for-md-screen {
    padding: 20px 0px;
  }

  @include for-lg1-screen {
    padding: 20px 0px;
  }

  .copy-right {
    font-size: $customer-page-subheading-size;
    font-weight: 400;
    color: $p-color;
  }

  .statement {
    font-size: $customer-page-subheading-size;
    font-weight: 400;
    color: $p-color;

    .term-of-use {
      margin-right: 20px;
    }

    .term-of-sale {
      margin-right: 20px;
    }
  }
}
