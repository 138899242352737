@import "../variables.scss";

.checkout-mobile-main {
  background-color: #ecedf2;
  padding-top: 10px;
  padding-bottom: 80px;
  text-align: start;

  .shipping-row {
    margin: 20px;

    .title {
      font-size: 14px;
    }

    .react-switch-bg {
      .react-switch-handle {
        width: 17px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
      }
    }

    h2 {
      font-size: 1.875rem;
      font-weight: 700;
      color: $head-color;
    }

    .pay-with-alisuq-wallet {
      font-weight: 700;
      color: $head-color;
      margin-inline-start: 8px;

      .available-balance {
        color: #404553;
        font-size: 0.875rem;
        font-weight: 400;
      }
    }

    button {
      padding-top: 15px;
      padding-bottom: 15px;
      width: 48.5%;
      background-color: white;
      box-shadow: 0px 0px 2px #0000004d;
      border: 1px solid #742013;
      font-size: 1.109875rem;
      font-weight: 700;
      color: $head-color;

      svg {
        font-size: 1.109875rem;
        font-weight: 700;
        width: 27px;
        height: 21px;
        color: #38ae04;
        margin-inline-end: 8px;
      }
    }

    .collect-button {
      cursor: pointer;

      .title {
        color: #212529;
        font-size: 1rem !important;
        margin: 0px;
      }

      .text {
        opacity: 0.6;
      }
    }

    .card-button {
      svg.card-icon {
        width: 30px;
        height: 30px;
      }
    }

    .cash-button {
      svg.cash-icon {
        width: 30px;
        height: 30px;
      }
    }

    .gray-button {
      border: none;
      // opacity: 0.6;

      svg {
        font-weight: 700;
        color: #000000;
        margin-inline-end: 8px;
      }
    }

    .order-button {
      border: none;
      // background-color: $form-btn-bg;
      color: white;
      height: 57px;
      min-height: 57px;
      max-height: 57px;
    }

    .active-btn {
      background-color: $form-btn-bg;
    }

    .inactive-btn {
      background-color: #cbcdcf;
    }

    label {
      color: #80879f;
      font-size: 1rem;
      font-weight: 700;
    }

    input {
      background: green;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      outline: none;
      background-color: #e7effd;
      padding: 14px 10px;
      box-shadow: 0px 0px 2px #0000004d;

      &::placeholder {
        height: 20px;
        line-height: 20px;
        padding-top: 5px;
      }
    }

    .with-border {
      border: 1px solid $border-color;
      box-shadow: none;

      &::placeholder {
        text-align: center;
      }
    }
  }

  .pickup-addresses {
    margin: 20px;
    background-color: white;
    padding: 15px;

    .active {
      border: 1px solid #742013 !important;
    }

    .pickup-address {
      cursor: pointer;
      border: 1px solid $border-color;
      padding: 10px 15px;
      display: flex;
      align-items: center;

      p {
        margin: 0px;
        margin-left: 10px;
      }
    }
  }

  .addresses-section {
    // margin: 20px;

    .address-label {
      font-size: 14px;
      font-weight: 700;
      color: #404553;
    }

    .address-card {
      border-bottom: 1px solid rgba(0, 0, 0, .125);
      // border-radius: 5px;
      cursor: pointer;

      .user_name {
        font-size: 13px !important;
        line-height: 17px;
        text-transform: capitalize;
      }

      .info {
        font-size: 13px;
        line-height: 17px;
      }

      .view-edit-btn {
        border: 1px solid rgba(0, 0, 0, .125);
        border-radius: 3px;
        padding: 0px 10px;
        text-align: center;
      }
    }

    .addresses-card {
      border: none;
      border-radius: 0px;

      .card-body {
        .address-card {
          // border-bottom: 1px solid rgba(0, 0, 0, .125);
          // border-radius: 5px;
          cursor: pointer;

          .user_name {
            font-size: 13px !important;
            font-weight: bold;
            line-height: 17px;
            text-transform: capitalize;
          }

          .info {
            font-size: 13px;
            line-height: 17px;
          }

          .view-edit-btn {
            border: 1px solid rgba(0, 0, 0, .125);
            border-radius: 3px;
            padding: 0px 10px;
            width: 96px;
            text-align: center;

            p {
              font-size: 11px;
            }
          }
        }

        .add-address-btn {
          // background-color: #c78e2b;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 7px;
          cursor: pointer;

          p {
            color: black;
            text-align: center;
            font-weight: bold;
          }
        }
      }
    }

    .addresses-popup {
      padding: 0px !important;
      border-radius: 5px;
      overflow: hidden;

      .address-card:last-child {
        border: none;
      }

      .address-card {
        padding-left: 15px;
        padding-right: 15px;
        border-bottom: 1px solid #dee2e6;
        cursor: pointer;

        .user_name {
          font-size: 14px !important;
          line-height: 17px;
          text-transform: capitalize;
        }

        .info {
          font-size: 13px;
          line-height: 17px;
        }

        .view-edit-btn {
          border: 1px solid rgba(0, 0, 0, .125);
          border-radius: 3px;
          padding: 0px 10px;
          width: 96px;
          text-align: center;

          p {
            font-size: 11px;
          }
        }
      }
    }
  }

  .add-address-btn {
    // margin: 20px;
    background-color: #742013;
    border-radius: 5px;
    justify-content: space-between;
    padding: 7px;
    cursor: pointer;
    text-align: center;

    p {
      color: white;
      font-weight: bold;
      text-align: center;
    }
  }

  .data-change {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    margin-bottom: 20px;
    background: #fbfdf4;
    color: #9898a1;
    font-size: 12px;
    padding: 10px 15px;
    font-weight: 600;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;

    .dismiss {
      padding-left: 10px;
      color: #e6a309;
      padding-top: 15px;
      padding-bottom: 15px;
    }

    .dismiss-border {
      border-left: 1px solid #fae4b2;
    }
  }

  .checkout-inner-main {
    .order-summary-label {
      font-size: 0.9rem;
      font-weight: 400;
      color: $gray;
    }

    .os-total-label {
      font-size: 0.9rem;
      font-weight: 700;
      color: #404553;
    }

    .os-total-vat-label {
      margin-inline-start: 5px;
      font-size: 0.9rem;
      font-weight: 400;
      color: $gray;
    }

    .first-container {
      // display: flex;
      // flex-direction: column;
      background-color: white;
      margin: 20px;
      padding: 15px;
      // background-color: #fff;
      // padding-inline-start: 20px;
      // padding-inline-end: 20px;

      .fc-first-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .ship-to {
          font-weight: bold;
          font-size: 0.9rem;
          color: #404553;
        }

        .change-address {
          font-size: 0.9rem;
          color: #7792d4;
          font-weight: 500;
        }
      }

      .fc-second-row {
        display: flex;
        flex-direction: column;

        .fc-sr-inner {
          display: flex;
          flex-direction: row;
          margin-bottom: 8px;

          .inner-icon {
            width: 20px;
          }

          .home-label {
            padding-inline-start: 10px;
            font-weight: bold;
            font-size: 0.9rem;
            color: #404553;
          }
        }

        .name {
          font-size: 0.9rem;
          color: #404553;
          font-weight: 700;
          margin-bottom: 5px;
        }

        .home-address {
          font-size: 0.9rem;
          font-weight: 400;
          color: $gray;
        }

        .phone-number {
          display: flex;
          flex-direction: row;
          margin-top: 5px;
          font-weight: 400;
          font-style: italic;
          color: $gray;
          margin-bottom: 5px;

          .phone {
            margin-inline-end: 8px;
          }
        }
      }
    }

    .active {
      border: 1px solid #d4982b;
    }

    .inactive {
      border: 1px solid #cbcdcf;
    }

    .second-container-payment-method {}

    .order-summary-checkout-page {
      background-color: #fff;
      display: flex;
      flex-direction: column;
      padding-left: 20px;
      padding-right: 20px;
      padding-top: 15px;
      padding-bottom: 15px;

      .order-summary-title {
        text-align: start;
        margin: auto;
        width: 100%;
        font-size: 1rem;
        font-weight: 700;
        color: #404553;
      }

      .os-upper {
        margin-top: 10px;

        .green {
          color: #447c2c;
        }

        .os-first-row {
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          .subtotal {}

          .subtotal-ammount {
            color: #404553;

            .free-text {
              color: #2a8703;
            }
          }
        }

        .os-second-row {
          margin-top: 8px;

          display: flex;
          flex-direction: row;
          justify-content: space-between;
          padding-bottom: 8px;
          border-bottom: 2px solid #dfe0e1;

          .shipping-fee {}

          .subtotal-ammount {
            color: #404553;

            .free-text {
              color: #2a8703;
            }
          }
        }
      }

      .os-bottom {
        margin-top: 10px;

        .os-third-row {
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          .subtotal {}

          .subtotal-ammount {
            .free-text {
              color: #2a8703;
            }
          }
        }

        .os-fourth-row {
          margin-top: 8px;

          display: flex;
          flex-direction: row;
          justify-content: space-between;

          .shipping-fee {}

          .subtotal-ammount {
            color: #404553;

            .free-text {
              color: #2a8703;
            }
          }
        }
      }
    }

    .review-your-order {
      margin-top: 20px;
      padding-top: 15px;
      padding-bottom: 15px;
      background-color: white;
      text-align: start;

      .review-order-label {
        margin: auto;
        width: 100%;
        font-size: 1rem;
        font-weight: 700;
      }

      .active-text {
        color: #38ae04;
        font-family: "futura heavy";
        font-size: 1rem;
      }

      .plain-text {
        font-family: "futura heavy";
        font-size: 1rem;
      }
    }
  }
}

.higher-container {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  left: 0;
  bottom: 55px;
  height: 65px;
  right: 0;

  .place-order {
    background-color: white;
    width: 100%;
    height: 80%;
    margin-top: auto;
    margin-bottom: auto;

    .place-order-button {
      margin-left: auto;
      margin-right: auto;
      width: 95%;
      height: 100%;
      font-weight: bold;
      font-size: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      cursor: pointer;
    }

    .active-btn {
      background-color: $form-btn-bg;
    }

    .inactive-btn {
      background-color: #cbcdcf;
    }
  }
}