.b2b-home-mob {
    .trending-products {
        background-color: white;
        border-radius: 5px;
        padding: 10px;
        margin: 0px 10px;

        .heading {
            font-size: 14px;
            font-weight: bold;
        }

        a {
            color: black;
        }

        img {
            border-radius: 5px;
        }
    }
    .section2 {
        margin: 0px 10px !important;
        
        .left {
            padding-right: 5px !important;
            overflow: hidden;

            .top {
                border-radius: 5px 5px 0px 0px;
                background-color: #62B5E5;
                padding: 30px 10px;

                .title {
                    font-weight: bold !important;
                    color: white;
                    font-size: 15px !important;
                }
                .date {
                    font-size: 12px !important;
                    color: white;
                }
                .go-btn {
                    display: block;
                    background-color: white;
                    padding: 2px 8px;
                    width: fit-content;
                    border-radius: 15px;
                }
            }

            .bottom-left {
                border-radius: 0px 0px 5px 5px;
                padding: 35px 0px;
                background-color: #508ac8;

                img {
                    border-radius: 5px;
                }
            }
        }

        .right {
            padding-left: 5px !important;

            .bottom-right {
                margin-top: 10px;
            }

            .top, .bottom-right {
                border-radius: 5px;
                background-color: white;
                padding: 5px 0px;

                .heading {
                    font-size: 14px;
                    font-weight: bold;
                    padding: 0px 10px;
                }

                .img-div {
                    margin-top: 5px;
                    padding-left: 10px !important;
                    padding-right: 10px !important;
                    text-align: center;

                    img {
                        border-radius: 5px;
                    }

                    p {
                        font-weight: bold;
                    }
                }
            }
        }
    }
    .product-cont {
        display: flex;
        flex-direction: row;
        max-width: 95%;
        margin-left: auto;
        margin-right: auto;
        overflow-x: auto;
        overflow-y: hidden;

        .image-cont {
            width: 160px;
            max-width: 160px;
            margin-inline-end: 10px;
        }
    }
}