@import "../mixins.scss";
@import "../variables.scss";

.websiteheaderdesktop2025 {

  .freshiptxt {
    font-size: 14px !important;
    letter-spacing: 1px;
  }
  
  span.offr {
    background-color: #d4982b;
    color: black;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 4px;
    padding-bottom: 5px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    font-size: 12px;
    margin-left: 0px;
    letter-spacing: 1px;
  
  }
  
  span.offr-ar {
    background-color: #d4982b;
    color: black;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 4px;
    padding-bottom: 5px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    font-size: 12px;
    margin-left: 0px;
    letter-spacing: 1px;
  
  }
  
  .offranim {
    animation: blinker 1s step-start infinite;
  }
  
  @keyframes blinker {
    50% {
      opacity: 0;
    }
  }
  
  span.off50 {
    background-color: #003366;
    color: white;
    padding-left: 5px;
    padding-right: 2px;
    padding-top: 5px;
    padding-bottom: 6px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    font-size: 10px;
    letter-spacing: 1px;
  }
  
  span.off50-ar {
    background-color: #003366;
    color: white;
    padding-left: 5px;
    padding-right: 2px;
    padding-top: 5px;
    padding-bottom: 6px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    font-size: 10px;
    letter-spacing: 1px;
  }
  
  .offrr a,
  .offrr-ar a {
    text-decoration: none !important;
  }
  
  .offrr {
    margin-left: 15px;
  }
  
  .offrr-ar {
    margin-right: 15px;
  }
  
  .phone-number svg {
    transform: unset !important;
  }
  
  .w16 {
    width: 16px;
  }
  
  .website-header-desktop-latest {
    font-size: 13px;
    width: 100%;
    top: 0;
    z-index: 99999;
    background-color: #ffffff;
    $contactColor: rgb(160, 160, 160);
    $navigationColor: rgb(145, 148, 153);
    $navigationLinkColor: rgb(70, 73, 83);
  
    .email a {
      color: white;
      margin-left: 5px;
    }
  
    @include for-mob-screen {
      display: none;
    }
  
    .col-lg-9.brandsLogosSection img {
      height: unset !important;
      background-color: white;
      box-shadow: 0px 0px 1px 0px #ccc;
      margin: 5px;
      width: 160px;
      border-radius: 8px;
      transition: transform 1s;
    }
  
    .col-lg-9.brandsLogosSection img:hover {
      -ms-transform: scale(1.1);
      -webkit-transform: scale(1.1);
      transform: scale(1.1);
      border: 1px solid #003366;
    }
  
    .col-lg-9.brandsLogosSection .col-lg-2 {
      max-width: 13.33%;
    }
  
    .mt-mb-atuo {
      margin-top: auto;
      margin-bottom: auto;
    }
  
    .top-row {
      background-color: #003366;
      color: white;
      height: 40px;
  
      .top-nav {
        max-width: 1440px;
        align-items: center;
        padding-top: 0.5rem;
  
        .contact-info-col {
          text-align: left;
          align-items: center;
  
          .phone-number,
          .phone-number>a {
            text-align: left;
            font-size: 14px;
            color: white;
            margin-inline-end: 15px;
            margin-left: 5px;
          }
  
          .email {
            text-align: left;
            font-size: 14px;
            color: white;
            padding: 0px !important;
            letter-spacing: 0.5px;
          }
        }
  
        .navigation-col {
          text-align: end;
          font-weight: 500;
          align-items: center;
  
          .navigation-link {
            font-size: 14px;
            margin: 10px 20px;
            text-decoration: none;
            color: white;
          }
  
          .home-nav {}
  
          .shop-nav {}
  
          .become-seller-nav {
            margin-right: 0px;
          }
  
          .cart-col {
            margin-left: 40px;
          }
        }
      }
    }
  
    .navigation-header {
      margin-top: unset !important;
      width: 100%;
  
      .bottom-nav {
        width: 100%;
        padding: 0px !important;
        align-items: center;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        margin-bottom: unset !important;
        height: 63px;
  
        .header-parts {
          display: flex;
          align-items: center;
          flex-direction: row;
          max-height: inherit;
  
          .lang {
            display: block;
            margin-right: 15px;
            margin-left: 15px;
            cursor: pointer;
            color: #000;
            font-family: 'Cairo';
            font-weight: 600;
            text-decoration: none;
          }
        }
  
        .leftheader {
          justify-content: flex-start;
          margin-left: -10px;
        }
  
        .centerheader {
          flex: 1 1 0%;
        }
  
        .mainnav .nav-link {
          color: #000000;
          font-family: futura heavy;
          letter-spacing: 0.5px;
          text-transform: uppercase;
          font-size: 15px;
        }
  
        .rightheader {
          justify-content: flex-end;
          margin-right: -10px;
        }
  
        a.site-header-logo img {
          width: 200px;
        }
  
        .rhd-icons-block {
          position: relative;
        }
  
        .rhd-link {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 40px;
          line-height: 1;
          padding-right: 10px;
          padding-left: 10px;
        }
  
        .rhd-icon {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 0;
        }
  
        .logo-and-search-col {
  
  
          .logo-container {
            cursor: pointer;
            margin-top: 12px;
            margin-bottom: 12px;
  
            @include for-md-screen {
              width: 150px;
  
              img {
                width: 100%;
              }
            }
  
            @include for-lg1-screen {
              width: 180px;
  
              img {
                width: 100%;
              }
            }
  
            @include for-lg2-screen {
              width: 180px;
  
              img {
                width: 100%;
              }
            }
  
            @include for-xl-screen {
              width: 220px;
  
              img {
                width: 100%;
              }
            }
  
            @include for-xxl-screen {
              width: 320px;
  
              img {
                width: 100%;
              }
            }
          }
  
          .input-group {
            border: 1px solid #ccc;
            padding: 1px;
            opacity: 1;
            border-collapse: collapse;
            position: relative;
            margin-left: 20px;
            margin-right: 20px;
            border-radius: 5px;
  
            ::placeholder {
              font-size: 12px;
              color: #003366;
            }
  
            input.form-control::placeholder {
              font-size: 13px !important;
              color: #000000b3;
              font-weight: 500;
            }
  
            &:focus-within {
              box-shadow: 1px 1px #c78e2b, 1px -1px #c78e2b, -1px -1px #c78e2b,
                -1px 1px #c78e2b;
            }
  
            .form-control {
              color: #003366;
              border-radius: 25px;
              display: flex;
              justify-content: center;
              align-items: center;
              border: none !important;
              box-shadow: none !important;
              height: 34px;
              padding: 12px;
  
              @include for-768-screen {
                padding: 0px 5px;
                height: 30px;
              }
            }
  
            .search-form {
              display: flex;
              // width: 100%;
              width: 550px;
  
              @include for-lg1-screen {
                width: 400px;
              }
  
              @include for-md-screen {
                width: 370px;
              }
            }
  
            .input-group-text {
              height: 100%;
              border: none !important;
              border-radius: 0px !important;
              border-inline-start: 2px solid #e2e5f1 !important;
              background-color: white !important;
              // padding-top: 0px !important;
              // padding-bottom: 0px !important;
              padding: 0px !important;
            }
  
            .search-btn {
              font-size: 1rem;
              cursor: pointer;
              color: $head-color;
              height: 40px;
              width: 70px;
              display: block;
              background-color: #003366;
              border: none !important;
              border-top-right-radius: 5px !important;
              border-bottom-right-radius: 5px !important;
              position: absolute;
              color: white;
  
              svg {
                margin-bottom: 5px;
              }
            }
  
            .search-btn-ar {
              font-size: 1rem;
              cursor: pointer;
              color: $head-color;
              height: 40px;
              width: 70px;
              display: block;
              background-color: #003366;
              border: none !important;
              border-top-left-radius: 5px !important;
              border-bottom-left-radius: 5px !important;
              position: absolute;
              color: white;
  
              svg {
                margin-bottom: 5px;
              }
            }
  
            .search-icon {
              font-size: 1rem;
              cursor: pointer;
              color: $head-color;
              height: 35px;
              width: 66px;
              display: block;
              background-color: #d4982b !important;
              border: none !important;
              border-top-right-radius: 5px !important;
              border-bottom-right-radius: 5px !important;
              color: white;
  
              svg {
                color: white;
                vertical-align: -webkit-baseline-middle;
              }
            }
          }
        }
  
        .navigation-col {
  
          .language-col {
            font-family: Cairo, sans-serif;
            color: $head-color;
            cursor: pointer;
          }
  
          .center {
            text-align: center;
          }
  
          .region-col {
            color: $head-color;
            cursor: pointer;
            margin-inline-start: 55px;
  
            .flag-col {
              img {
                border-radius: 3px;
              }
            }
  
            .dropdown-col {
              position: relative;
              width: 75px;
              min-width: 75px;
  
              .label {
                margin-top: 2px;
                font-size: 0.625rem;
                color: $head-color;
                font-weight: 500;
                width: 45px;
                min-width: 45px;
                max-width: 45px;
  
                .country-label {
                  font-size: 13px;
                  font-weight: 700;
                  color: $head-color;
                  margin-top: 0px;
                  line-height: 1rem;
                }
              }
  
              .dropdown {
                svg {
                  font-size: 1.6rem;
                  font-weight: 700;
                  color: $head-color;
                }
              }
  
              .country-list {
                width: 130px;
                height: auto !important;
                background: #fff;
                position: absolute;
                top: 110%;
                right: -20%;
                z-index: 2;
                border-radius: 4px;
                box-shadow: 0px 0px 6px #00000029;
                font-size: 13px;
  
                @include for-md-screen {
                  width: 120px;
                }
  
                .country-container {
                  display: flex;
                  flex-direction: column;
                  justify-content: space-between;
  
                  padding: 0px 5px;
  
                  .country-row {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;
                    position: relative;
                    margin-bottom: 10px;
  
                    &:first-child {
                      margin-top: 10px;
                    }
  
                    img {
                      width: 37px;
                      height: 27px;
                      border-radius: 6px;
  
                      @include for-768-screen {
                        width: 23px;
                        height: 15px;
                        border-radius: 3px;
                        margin-left: 5px;
                      }
                    }
  
                    span {
                      font-size: 12px;
                      font-weight: 500;
                      color: $head-color;
                      margin-left: 10px;
  
                      @include for-768-screen {
                        font-size: 13px;
                        font-weight: 400;
                      }
                    }
  
                    input[type="radio"] {
                      width: 20px;
                      height: 20px;
                    }
                  }
                }
              }
            }
          }
  
          .signin-col {
            text-decoration: none;
            position: relative;
            padding-right: 5px;
            padding-left: 5px;
  
            .navigation-link {
              color: $head-color;
              text-decoration: none;
            }
  
            .sign-in {
              display: inline-block;
              font-size: 13px;
              font-weight: 500;
              margin-top: 3px;
  
              .my-account-label-div {
                display: flex;
                flex-direction: row;
  
                p {
                  margin-bottom: 0 !important;
                  text-align: start;
                  font-weight: 400;
                  font-size: 14px !important;
                  width: auto;
                  max-width: 81px;
                  margin-top: 0px;
                }
  
                span {
                  font-weight: bold;
                  text-align: end;
                }
  
                .account-drop-svg {
                  svg {
  
                    width: 25.6px;
                    height: 25.6px;
                  }
                }
              }
            }
  
            .signin-dropdown-menu {
              position: absolute;
              z-index: 999999999;
              width: 144px;
              box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
              top: 120%;
              right: -10px;
  
              .signin-dropdown {
                .item-row {
                  .item-icon {
                    width: 28px;
                    height: 28px;
                    background-color: #f7f7fa;
                    border-radius: 100%;
  
                    svg {
                      color: #404553;
                      width: 20px;
                      height: 20px;
                      max-width: 20px;
                      max-height: 20px;
                    }
                  }
                }
              }
            }
          }
  
          .cart-col {
  
            position: relative;
  
  
            .cart-counter {
              position: absolute;
              top: -7px;
              right: -9px;
              width: 18px;
              height: 18px;
              border-radius: 100%;
              line-height: 1;
              background-color: rgb(116, 32, 19);
              color: rgb(255, 255, 255);
              font-size: 10px;
              font-weight: 600;
              display: flex;
              -webkit-box-align: center;
              align-items: center;
              -webkit-box-pack: center;
              justify-content: center;
            }
  
            .total-count {
              position: relative;
              width: 20px;
              text-align: center;
              border-radius: 75%;
              display: inline-block;
              border: 1px solid black;
              margin-inline-end: 5px;
              top: -12px;
              left: -18px;
              background: red;
              color: white;
              font-weight: 600;
              font-size: 0.8rem;
            }
  
            .navigation-link {
              color: $head-color;
            }
  
            .price {
              display: inline-block;
              font-size: 13px;
              font-weight: 500;
              margin-top: 3px;
              margin-inline-end: 10px;
            }
  
            svg {
              color: $head-color;
              margin-inline-end: 10px;
              width: 20px;
              height: 20px;
              font-weight: lighter;
            }
          }
        }
      }
    }
  
    .categories-container {
      width: 100%;
      // background-color: #d4982b;
      height: 50px;
      border: 1px solid #74201317;
      align-items: center;
  
      .wrapper {
        width: $header-content-width;
        max-width: $max-page-width;
  
        .left {
          width: 150px;
          min-width: 150px;
          cursor: pointer;
          background-color: white;
          border-radius: 42px;
          height: 38px;
          padding-left: 3px;
          padding-right: 3px;
          align-self: center;
  
          .text {
            color: #333;
            font-size: 14px;
            font-family: 'futura'!important;
            font-weight: 600;
            letter-spacing: 0.7px;
            padding: 7px;
          }
  
          .dropdown-arrow {
            position: relative;
            height: 32px;
            width: 32px;
            background-color: #003366;
            color: white;
            border-radius: 50%;
            text-align-last: center;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 0;
  
            svg {
              font-size: 28px;
            }
          }
        }
  
        .right {
          width: calc(100% - #{$all-categories-dropdown-width+40px});
          max-width: calc(100% - #{$all-categories-dropdown-width+40px});
          margin-top: auto;
          margin-bottom: auto;
          overflow: hidden;
          display: flex;
          align-items: center;
          flex-direction: row;
          margin-right: 10px;
  
          &::-webkit-scrollbar {
            height: 0;
            display: none;
          }
  
          -ms-overflow-style: none;
          scrollbar-width: none;
  
          .category-name {
            height: 100%;
            white-space: nowrap;
            letter-spacing: 0.5px;
            width: fit-content;
            text-transform: uppercase;
            font-size: 12px;
            font-weight: 700;
            color: #000000;
            cursor: pointer;
            padding: 10px 10px;
  
            &:hover {
              background: white;
              box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
              border-radius: 100px;
              padding-top: 5px;
              padding-bottom: 5px;
            }
          }
        }
      }
    }
  
    .brandSearchWrapper {
      padding: 20px;
    }
  
    .brandSearchMain {
      width: 320px;
      height: 36px;
      line-height: 36px;
      border-radius: 2px;
      border: solid 1px rgba(151, 151, 151, 0.4);
      position: relative;
    }
  
    .SearchIcon {
      background: url(https://www.nykaa.com/media/wysiwyg/HeaderIcons/menusprite.png) -65px -36px no-repeat;
      display: inline-block;
      height: 20px;
      line-height: 20px;
      width: 25px;
      vertical-align: text-top;
      position: absolute;
      top: 8px;
      left: 5px;
    }
  
    input#brandSearchBox[type="text"] {
      outline: 0;
      padding: 0 0 0 5px;
      border: none;
      text-transform: capitalize;
      display: inline-block;
      vertical-align: middle;
      height: 34px;
      line-height: 34px;
      width: 268px;
      position: absolute;
      right: 10px;
      top: 0;
      bottom: 0;
    }
  
    .divider {
      margin: 0px;
    }
  
    .brandsSection {
      height: 380px;
      overflow-y: scroll;
      padding: 20px;
      padding-top: 18px;
      padding-bottom: 0px;
    }
  
    .brandsLogosSection {
      height: 458px;
      overflow-y: scroll;
      background-color: #fcfaf4;
      padding: 18px;
    }
  
    .get-app-dropdown {
      position: absolute;
      z-index: 1000;
      right: 0;
      width: 300px;
      height: 350px;
      background-color: white;
      border: 2px solid #7d252a;
      border-radius: 20px;
      text-align: center;
      top: 30px;
    }
  
    .getapp {
      background-color: #7d252a;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      height: 100px;
      width: 298px;
      margin-top: -12px;
    }
  
    .getapp p {
      position: relative;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: white;
      font-size: 22px;
      letter-spacing: 0.3px;
      font-weight: 700;
    }
  
    .getappslinks {
      display: block;
      font-size: 17px;
      color: #7d252a;
      font-weight: 700;
      letter-spacing: 0.5;
      margin-bottom: 20px;
      margin-top: 50px;
    }
  
    span.getappslinks img {
      margin-right: 10px;
    }
  
    .qrcode {
      margin-bottom: 20px;
    }
  }
}