.dispute-order-description-container-component {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  .dispute-labels {
    display: flex;
    justify-content: flex-start;
    width: 33%;
    font-weight: 600;
    font-size: 1rem;
  }
  .dispute-text {
    display: flex;
    justify-content: flex-start;
    font-weight: normal;
    font-size: 0.9rem;
    width: 67%;
    padding-left: 5px;
  }
  .dispute-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    .disp-first-row {
      span {
        font-size: 13px;
      }
      display: flex;
      flex-direction: row;
      width: 100%;
      .disp-num-span {
      }
      .disp-num {
      }
    }
    .disp-second-row {
      display: flex;
      flex-direction: row;
      width: 100%;
      span {
        font-size: 13px;
      }

      .store-label {
      }
      .disp-num {
      }
    }
    .disp-third-row {
      span {
        font-size: 13px;
      }
      display: flex;
      flex-direction: row;
      width: 100%;
      text-align: start;
    }
  }

  .order_customer-deatils-container {
    margin-top: 10px;
    width: 100%;
    .ocd-first-row {
      span {
        font-size: 13px;
      }
      display: flex;
      flex-direction: row;
      width: 100%;
    }
    .ocd-second-row {
      span {
        font-size: 13px;
      }
      display: flex;
      flex-direction: row;
      width: 100%;
    }
    .ocd-third-row {
      span {
        font-size: 13px;
      }
      display: flex;
      flex-direction: row;
      width: 100%;

      .status-btn {
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        margin-left: 5px;
        background-color: #e1f3f2;
        color: #19af83;
        border: none;
        width: 90px;
        height: 30px;
        font-weight: bold;
      }
    }
  }
}
