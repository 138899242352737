@import "../mixins.scss";
@import "../variables.scss";
.item-and-reason-for-refund-main-mobile {
  overflow: unset !important;
  background-color: white;
  padding-bottom: 20px;
  text-align: start;
  .list-of-items-refunds {
    display: flex;
    flex-direction: row;
    .item {
      width: 100%;
      display: flex;
      align-items: center;
      padding-right: 10px;
      padding-left: 10px;
      .description-container {
        width: 80%;
        margin-inline-start: 10px;
        .description {
          color: #404553;
        }
        .order-id {
          color: grey;
        }
      }
      .right-arrow {
        direction: ltr;
        display: flex;
        align-items: center;
        margin-inline-end: 10px;
      }
    }
  }
  .reason-dropdown {
    margin-bottom: auto;
    margin-top: 40px;
    .filter {
      margin-inline-end: 10px;
      margin-inline-start: 10px;
    }
    label {
      margin-bottom: 0px;
      color: $p-color;
      font-size: $customer-page-subtext-size;
      font-weight: $customer-page-subtext-weight;
      position: absolute;
      top: -7px;
      left: 0;
      z-index: 1;
    }
  }
  .select-a-claim-type-container {
    margin-top: 40px;
    margin-right: 10px;
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    .select-claim-title {
      .inner-text {
        font-size: 1.25rem;
        font-weight: 600;
      }
    }
    .claim-type-card {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      height: 80px;
      border: 0.5px solid grey;
      align-items: center;
      .claim-logo {
        width: 15%;
        display: flex;
        justify-content: center;
      }
      .claim-desc {
        width: 70%;
        display: flex;
        flex-direction: column;
        text-align: start;
        .upper {
          font-weight: bold;
        }
      }
      .select-checkbox {
        width: 10%;
      }
    }
  }
  .third-row {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    .desc-label {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding-inline-end: 35px;
      width: 35%;
    }
    .write-desc-box {
      display: flex;
      justify-content: flex-start;
      width: 65%;
      .write-desc-boxinput {
        width: auto;
        height: 100px;
        padding: 10px 10px;
        text-overflow: inherit;
        border: 1px solid grey;
        outline: none;
        border-radius: 2px;
      }
    }
  }
  .inner-second {
    width: 60%;
    border-radius: 10px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    .create-button {
      height: 40px;
      background-color: #2f75a6;
      width: 100%;
      background: rgb(207, 148, 53);
      border-radius: 4px;
      text-align: center;
      font-weight: bold;
      font-size: 16px;
      color: #ffffff;
      padding: 10px 35px 10px;
      text-decoration: none;
      border: none;
    }
  }
}
