@import "../../../mixins.scss";

// .order-desktop:last-child {
//   border: none;
// }

.order-desktop {
  text-align: initial;
  border: 1px solid gainsboro;
  margin-bottom: 20px;

  .sku {
    margin-top: -0.2rem;
    margin-bottom: 0.2rem;
    color: #7e859b;
    font-size: 0.78rem;
  }

  .br-2 {
    border-radius: 2px;
  }

  .date-style {
    font-size: 13px !important;
    margin-left: 5px;
  }

  .MuiInputBase-root {
    font-size: 13px;
  }
  .date-heading {
    font-size: 14px;
    color: #404553;
  }
  .form-control {
    height: 0px;
    padding: 0.7rem 0.75rem;
    font-size: 13px;
  }

  // .cart-item {
  //   border: 1px solid rgb(220, 220, 220);
  // }

  .order-row {
    .image-container {
      width: 140px;
      max-width: 140px;
      height: 140px;
      max-height: 140px;
      overflow: hidden;
      padding: 12px;

      .image {
        max-width: 100%;
        max-height: 100%;
      }
    }
    .padding-dir {
      padding-inline-end: 15px;
    }
    .title {
      font-size: 1.25rem;
      font-weight: 700;
      color: $head-color;
    }

    h2 {
      font-size: 1.875rem;
      font-weight: 700;
      color: $head-color;
    }

    h3 {
      font-size: 1.125rem;
      font-weight: 700;
      color: $head-color;
    }

    h4 {
      font-size: 0.875rem;
      font-weight: 700;
      color: $head-color;
    }

    .small-text {
      font-size: 0.875rem;
      color: $p-color;
      margin-right: 10px;
    }

    .bold-text {
      font-size: 0.94rem;
      font-weight: 700;
      color: $head-color;
    }

    .margin-bottom-6 {
      margin-bottom: 30px;
    }

    .margin-left-10 {
      margin-left: 96px;
    }

    button {
      padding: 0px;
      border: none;

      svg {
        color: $p-color;
      }
    }
  }
}
