.middle-section {
    position: relative;

    .view-all-middle-sec {
        position: absolute;
        top: 0;
        right: 0;
    }

    .view-all-middle-sec-ar {
        right: auto;
        left: 0;
    }

    .nav-tabs .nav-link {
        color: black;
        font-weight: 500;
    }

    .homeofferimg {
        height: 100%;
        width: 100%;
        border-radius: 5px;

    }

    .nav-link.active {}

    .col-lg-6.mia {
        padding-left: 15px;
        padding-right: 15px;
        margin-bottom: 20px;
    }

    .col-lg-6.mib {
        padding-left: 10px;
        padding-right: 10px;
    }

    .col-lg-6.mic {
        padding-left: 10px;
        padding-right: 10px;
        margin-bottom: 20px;
    }

    .col-lg-6.mid {
        padding-left: 10px;
        padding-right: 10px;
        margin-bottom: 20px;
    }

    .col-lg-6.mie {
        padding-left: 10px;
        padding-right: 10px;
        margin-bottom: 20px;
    }

    .col-lg-6.mif {
        padding-left: 10px;
        padding-right: 10px;
        margin-bottom: 20px;
    }

    .col-lg-6.mib img {
        margin-bottom: 15px;
    }
}