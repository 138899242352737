@import "../../../mixins.scss";

.mobile-search-cont {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  border-radius: 5px;
  border: 1px solid #ccc;

  .input-field {
    border: none;
    outline: none;
    background-color: transparent;
    font-weight: 500;
    color: #000000;
    font-size: 1rem !important;
    line-height: 0;
    @include for-mob-s0 {
      font-size: 10px;
      width: 100%;
    }

    &::placeholder {
    }
    &:hover {
      outline: none;
    }
    &:focus {
      outline: none;
    }
  }
}
