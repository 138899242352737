@import "../../../mixins.scss";
@import "../../../variables.scss";

.product-card-mob {
  border-radius: 1px;
  width: auto;
  height: auto;
  max-width: 100%;
  padding-bottom: 5px;
  position: relative;
  border: 1px solid #e5e5e5!important;

  .discount-box {
    margin-left: 5px;
    background-color: #212529;
    height: 20px;
    width: 60px;
    text-align: center;
    color: white;
    border-radius: 11px;
    margin-top: 5px;
    position: absolute;
  }

  .discount-box-ar {
    margin-right: 10px;
    background-color: #212529;
    height: 20px;
    width: 60px;
    text-align: center;
    color: white;
    border-radius: 11px;
    margin-top: 10px;
  }

  .d-flex.flex-direction-row.align-items-center.mt-2 {
    display: none !important;
  }

  .price span {
    font-size: 0.757143rem !important;
  }

  .price {
    font-size: 0.757143rem !important;
  }

  .img img {
    max-width: 100% !important;
    max-height: 100% !important;
    height: unset !important;
  }

  .like-img {
    width: 25px;
    margin-left: auto;
    margin-right: 10px;

  }

  .like-img-ar {
    width: 25px;
    margin-right: auto;
    margin-left: 10px;
    margin-bottom: 4px;
  }

  .img {
    width: 100%;
    height: 150px;
    margin: auto;
    display: flex;
    justify-content: center;
  }

  .description {
    color: #212529;
    font-size: 13px;
    margin-top: 10px;
    height: 70px;

    p {
      font-size: 13px !important;
      line-height: normal;
    }
  }

  .disc-price {

    // font-size: 1.14286rem;
    font-size: 20px;
    font-weight: bold;
    color: #212529;
    font-family: 'Futura Heavy';
    line-height: normal;
  }

  .disc-price-text-color {
    color: #2A8703;
  }
  .priceamount{
    font-size: 20px;
    font-family: 'Futura Heavy';
  }
.currency{
  font-size: 15px;
  font-family: 'Futura Heavy';
}
  .price {
    font-size: 13px;
    color: #212529;
    margin-bottom: -5px;

  }
  button.out-of-stock-btn.btn.btn-secondary {
    margin-top: 15px;
    background-color: #74201341;
    border: none!important;
    border-radius: 5px;
}
button.quick-view-btn.btn.btn-secondary {
  margin-top: 15px;
  background-color: #742013;
  border: 1px solid #742013;
  border-radius: 5px;
}
  .rating {
    width: 30%;
    font-size: 11px;
    margin-right: 5px;
    margin-left: 0px;
    display: inline-flex;
    flex-direction: row;
    align-items: center;

    .number {
      color: rgb(245, 165, 35);
      font-weight: bold;
      margin-left: 2px;
      margin-right: 4px;
    }

    .count {
      color: rgb(178, 184, 202);
    }
  }

  .logo {
    display: inline-flex;
    align-items: center;
    padding-left: 7px;
    width: 65%;
    max-width: 65%;
    height: 20px;
    max-height: 20px;
    margin: auto;
  }

  .coupon-row {
    width: fit-content;
    padding-left: 10px;

    .coupon-box {
      border-radius: 3px;
      background: #742013;
      color: white;

      p {
        font-size: 13px !important;
      }
    }
  }

  .inquire-now-btn {
    display: block;
    border: 1px solid #4dc247;
    width: fit-content;
    background-color: #4dc247;
    color: white;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    border-radius: 5px;
    padding: 6px 12px;
    text-decoration: none;
  }

  .out-of-stock-btn {
    width: 100%;
    color: #dc3545;
    font-size: 14px;
    font-weight: bold;
    display: flex;
    align-items: center;
  }

  .notify-me-btn {
    border: 1px solid rgb(160, 160, 190);
    background-color: white;
    border-radius: 5px;
    align-items: center;
    display: flex;
    justify-content: center;
    color: black;
    cursor: pointer;
  }

  
}