@import "../general.scss";

.individual-category-container-desktop {
  width: 100%;
  position: absolute;
  z-index: 2;
 

  .inidividual-category-row {
    width: 100%;
  
    .individual-categories-container {
      width: 100%;
      max-width: 1440px;
      padding: 0px 20px;
      justify-content: start;
      display:none;
      .container-wrapper {
        .individual-category {
          width: calc(1400px / 4);
          min-height: 200px;
          background-color: white;
          padding-right: 0px;
          padding-left: 0px;
          border-inline-start: 1px solid #70707057;
          border-bottom: 1px solid #70707057;
          display:none;
          &:first-child {
            border-inline-start: none;
          }
        }
      }
    }
  }
}
