@import "../../../mixins.scss";

.order-summary-desktop {
  border: 1px solid rgb(220, 220, 220);
  border-radius: 2px;
  background-color: white;
  text-align: start;
 .suggestfree {
  background-color: #28a74540;
  text-align: center;
  color: #28a745;
  font-weight: 600;
  font-size: 0.85rem;
  letter-spacing: 0.5px;
  padding-top: 2px;
  padding-bottom: 2px;
}

  .upper,
  .lower {
    padding: 15px 20px 0 20px;
  }

  .br-2 {
    border-radius: 2px;
  }

  $subtitle-font-size: 1.05rem;
  $subtitle-font-weight: 400;

  .summary-row {
    border-bottom: 1px solid rgb(220, 220, 220);

    h3 {
      font-size: 1.109875rem;
      font-weight: 700;
      color: $head-color;
    }

    .subtotal {
      font-size: $subtitle-font-size;
      margin-top: 0.8rem;
      font-weight: $subtitle-font-weight;
    }

    .subtotal-value {
      margin-top: 0.8rem;
      font-size: 1.1rem;
      font-weight: $subtitle-font-weight;
    }

    .shipping,
    .shipping-value {
      font-size: $subtitle-font-size;
    }

    .green {
      color: #447c2c;
    }
    .blue {
      color: $blue-header;
    }
  }

  .total-container {
    color: #706773;

    .total {
      font-size: 1.2rem;
      font-weight: 500;
    }

    .vat {
      color: #a2a6c4;
    }
    .total-value {
      font-size: 1.2rem;
      font-weight: 700;
    }

    .estimated-vat,
    .estimated-vat-value {
      color: #a2a6c4;
      font-size: 0.9rem;
    }
  }

  .shiping-row {
    padding: 10px 0px;
    background-color: #f7f7fa;

    h3 {
      padding-left: 20px;
      padding-right: 20px;
      font-size: 1rem;
      font-weight: 700;
      color: $head-color;
      opacity: 0.6;
    }

    .edit {
      text-decoration: underline;
    }
  }

  .contact {
    color: #38415a;
    font-weight: 400;

    .name {
    }

    .address {
      margin-bottom: 5px;
    }
  }
}
