@import "../mixins.scss";
@import "../variables.scss";
.ofrtxt-cat{
color: #d9002a!important;
font-family: futura heavy;
}
.all-categories .all-categories-row .all-categories-container .colum-wrapper .all-categories-col {
  min-height: 500px;
}
.most-popular li a {
  font-size: 0.80rem!important;
}
.all-categories .all-categories-row .all-categories-container .colum-wrapper .all-categories-col ul li:hover {
  // background-color: white;
  width: initial;
}
.all-categories {
  $link-color: #000000;
  $hover-color: #7d252a;
  $hover-weight:700;
  width: 100%;
  position: relative;
  z-index: 2;
  .text-align {
    text-align: start;
  }
  .all-categories-row {
    position: absolute;
    width: 100%;
    border-radius: 3px;

    .all-categories-container {
      width: 100%;
      max-width: 1440px;
      margin: 0px auto;

      .colum-wrapper {
        width: fit-content;

        .all-categories-col-ar-padding {
          padding-right: $page-content-padding !important;
        }

        .all-categories-col {
          width: $all-categories-dropdown-width + $page-content-padding + 1 -28;
          min-width: $all-categories-dropdown-width + $page-content-padding + 1 -28;
          padding-left: $page-content-padding;
          padding-right: 0px;
          border-bottom: 1px solid #e2e5f1;
          border-right: 1px solid #e2e5f1;
          background: #ffffff;

          .child-dropdown {
            position: absolute;
            left: 278px;
            background-color: #ffffff;
            border-bottom: 1px solid #e2e5f1;
            border-right: 1px solid #e2e5f1;
            border-left: 1px solid #e2e5f1;
            width: 298px;
            top: 0;
            padding-top: 5px;
            padding-bottom: 5px;
            padding-left: 20px;

            .top-title {
              font-size: 15px;
              font-weight: 800;
            }
          }

          .child-dropdown-ar {
            position: absolute;
            right: 278px;
            background-color: #ffffff;
            border-bottom: 1px solid #e2e5f1;
            border-right: 1px solid #e2e5f1;
            border-left: 1px solid #e2e5f1;
            width: 298px;
            top: 0;
            padding-top: 5px;
            padding-bottom: 5px;
            padding-right: 20px;

            .top-title {
              font-size: 15px;
              font-weight: 800;
            }
          }

          .child-dropdown1 {
            left: 250px !important;
            min-height:500px;
          }

          .child-dropdown1-ar {
            right: 250px !important;
            min-height:500px;
          }

          .child-dropdown2 {
            left: 296px !important;
            min-height:500px;
          }

          .child-dropdown2-ar {
            right: 296px !important;
            min-height:500px;
          }

          .child-dropdown3 {
            left: 296px !important;
            min-height:500px;
          }

          .child-dropdown3-ar {
            right: 296px !important;
            min-height:500px;
          }
          

          @media screen and (min-width: 1440px) {
            width: $all-categories-dropdown-width + $page-content-padding;
            min-width: $all-categories-dropdown-width + $page-content-padding;
          }

          ul {
            position: relative;
            padding: 0px;
            // width: fit-content;
            padding-top: 5px !important;
            padding-bottom: 5px !important;
            margin-bottom: 0px !important;

            li {
              // width: fit-content;
              width: 277px;
              // max-width: 225px;
              list-style: none;
              width: 100%;

              a {
                display: inline-block;
                padding: 4px 0px 4px 0px;
                text-decoration: none !important;
                font-size: 0.87rem;
                color: $link-color;
                width: 100%;

                &:hover {
                  color: #d4982b;
                  font-weight: $hover-weight;
                }
              }
            }
          }
        }

        .category-col {
          width: 500px;
          min-width: 500px;
          border-right: 1px solid #e2e5f1;
          border-bottom: 1px solid #e2e5f1;

          .all-category-header {
            font-weight: 700;
            font-size: 0.87rem;
            color: $link-color;
            margin-bottom: 10px;
            border-bottom: 1px solid rgb(241, 243, 249);
            text-align: initial;
          }

          .category-detail {
            text-align: initial;
            margin-inline-start: 12px;
            .most-popular {
              width: 60%;

              .header {
                font-size: 0.87rem;
                font-weight: 700;
                color: $link-color;
              }

              ul {
                padding: 0px;
                margin: 0px;

                li {
                  list-style: none;

                  a {
                    display: inline-block;
                    padding: 5px 0px;
                    text-decoration: none !important;
                    font-size: 0.87rem;
                    color: $link-color;

                    &:hover {
                      color: #d4982b;
                      font-weight: $hover-weight;
                    }
                  }
                }
              }
            }

            .top-brand {
              width: 40%;
        

              .header {
                font-size: 0.87rem;
                font-weight: 700;
                color: $link-color;
              }

              ul {
                padding: 0px;
                margin: 0px;

                li {
                  list-style: none;

                  a {
                    display: inline-block;
                    padding: 5px 0px;
                    text-decoration: none !important;
                    font-size: 0.87rem;
                    color: $link-color;

                    &:hover {
                      color: $hover-color;
                      font-weight: $hover-weight;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
