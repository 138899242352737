@import "../mixins.scss";

.working-container {
  width: 100%;
  margin-left: 15%;

  .working-heading-row {
    width: 100%;
    margin-bottom: 33px;

    .col-sm-header {
      margin-top: 20px;
      margin-left: 15px;

      div {
        text-align: center;
        font-size: 25px;
        font-weight: bold;
        color: #3f3f3f;
        opacity: 1;
      }
    }

    .col-sm-des {
      margin-left: 20px;
      div {
        text-align: left;
        font-size: 10.5px;
        font-weight: 400;
        color: #3f3f3f;
        opacity: 1;
      }
    }
  }

  .working-description-row {
    width: 81%;
    position: relative;
    margin-left: 125px !important;

    @include for-768-screen {
      margin-left: 70px !important;
    }

    @include for-992-screen {
      margin-left: 88px !important;
    }
  }
}
