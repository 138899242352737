.homepage-main-banner-swiper {
  z-index: 1;


  .swiper-button-prev,
  .swiper-button-next {
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.4);
    height: 40px;
    width: 40px;
    color: black;
  }

  .swiper-button-next:after,
  .swiper-button-prev:after {
    font-size: 1.5rem;
  }

  .swiper-image {
    width: 100%;
    height: 100%;

  }
}
