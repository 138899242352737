@import "../../mixins.scss";
.cart-sidebar-product-card-container-desktop {
  display: flex;
  width: auto;
  color: black;
  padding: 15px 5px 5px;
  border-bottom: 1px solid rgba(119,119,119,.2);

  .MuiInputBase-root {
    font-size: 13px;
  }
  .date-heading {
    font-size: 14px;
    color: #404553;
  }
  .form-control {
    height: 0px;
    padding: 0.7rem 0.75rem;
    font-size: 13px;
  }
  .price {
    color: #b93f51;
    text-align: end;
    font-size: 0.9em;
    width: 100%;
    font-weight: bold;
  }

  .main {
    flex-grow: unset !important;
    display: flex;
    justify-content: center;
    align-items: center;
    .image-container {
      width: 80px;
      height: 80px;
      text-align: center;

      .image {
        width: auto;
        height: auto;
        max-width: 80px;
        max-height: 80px;
        height: inherit;
      }
    }
  }

  .details-container {
    display: flex;
    flex-direction: column;
    padding-top: 5px;
    padding-right: 15px;
    justify-content: space-between;

    .description {
      align-self: flex-start;
      text-align: start;
      font-weight: 500;
      font-size: 0.85rem;
      overflow: hidden;
    }
    .sku {
      align-self: flex-start;
      text-align: start;
      font-family: Montserrat, sans-serif;
      font-size: 0.7rem;
      overflow: hidden;
      margin-top: -0.9rem;
      margin-bottom: 0.9rem;
    }

    .qunatity-and-price {
      display: flex;
      justify-content: space-between;
      align-content: center;
      .quantity-container {
        justify-self: left;
        max-width: 100px;
      }

      .price-container {
        display: flex;
        align-items: center;
        direction: ltr;
        .para {
          margin-bottom: 0px !important;
          margin-right: 15px;
          .sp-count {
            font-size: 0.6rem;
            padding-inline-end: 2px;
          }
          .sp-sign {
            font-size: 0.5rem;
            display: inline;
            padding-bottom: 1px;
          }
          .sp-total {
            font-size: 0.7rem;
            padding-inline-start: 2px;
            font-weight: bold;
            color: #7d252a;
          }
        }
      }
    }
  }
  .confirmation-container {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    margin-left: auto;
    margin-top: 5px;
    .remove-btn {
      padding-left: 22px;
      svg {
        color: $p-color;
      }
    }
    .checked {
      padding-right: 15px;
    }
  }
}
