@import "../variables.scss";
.verify-phone-mobile {
  $black-text: #4b4b50;
  $gray: #e9e6ec;
  $dark-gray: #9d9d9c;
  $shadow: 0px 2px 6px #00000024;
  $input-border-color: #e9e6ec;
  font-size: 0.95rem;

  color: $black-text;

  .wrapper {
    width: 95%;

    .cta {
      font-weight: 700;
    }
  }
  .input-container {
    #otp {
      width: 50%;
      border: none;
      padding: 10px 20px;
      border-bottom: 1px solid $input-border-color;
      font-size: 1.5rem;
      color: $dark-gray;
    }
    input {
      text-align: right;
    }
  }

  .bottom {
    .verify-btn {
      border: none;
      font-weight: 700;
      background-color: $form-btn-bg;
      color: white;
    }
  }

  .actions {
    .resend {
      .gray-text {
        color: rgb(171, 170, 173);
      }

      .timer {
        font-weight: 600;
        color: rgb(209, 155, 60);
      }
    }

    .change-number {
      color: #3b5ba3;
    }
  }

  ::-webkit-input-placeholder {
    text-align: center;
  }

  :-moz-placeholder {
    text-align: center;
  }

  ::-moz-placeholder {
    text-align: center;
  }

  :-ms-input-placeholder {
    text-align: center;
  }
}
