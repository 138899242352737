@import "../../../mixins.scss";

.wishlist-card-container-desktop {
  display: flex;
  flex-direction: row;
  border: 1px solid rgb(220, 220, 220);

  color: black;
  background-color: white;
  margin-bottom: 5px;
  padding: 5px;

  .main {
    display: flex;
    justify-content: center;
    align-items: center;
    .image-container {
      width: 140px;
      max-width: 140px;
      height: 140px;
      max-height: 140px;
      overflow: hidden;
      padding: 12px;

      .image {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }

  .details-container {
    flex-direction: column;
    padding-top: 5px;
    padding-right: 15px;
    padding-left: 15px;

    .brand-and-price {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .brand {
        display: inline;
        font-size: 0.875rem;
        color: $p-color;
      }
    }

    .description {
      align-self: flex-start;
      font-weight: 700;
      font-size: 0.875rem;
      color: #404553;
      width: 472px;
      line-height: 16px;
      overflow: hidden;
      margin-top: 5px;
      p {
        text-align: start;
      }
    }
    .date-heading {
      font-size: 14px;
      color: #404553;
    }
    .sold-by {
      padding-top: 5px;
      padding-bottom: 5px;
      font-size: 0.875rem;
      color: $p-color;
      font-weight: 400;

      text-align: start;
      .company {
        font-size: 0.94rem;
        font-weight: 700;
        color: #404553;
      }
    }

    .buttons {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .small-text {
        font-size: 0.875rem;
        color: $p-color;
        margin-right: 10px;
      }
    }

    button {
      padding: 0px;
      border: none;

      svg {
        color: $p-color;
      }
    }
  }
  .price {
    display: flex;
    justify-content: flex-end;
    padding-right: 10px;
    .currency {
      padding-inline-end: 2px;
      color: #404553;
      display: inline-block;
      margin-bottom: 5px;
    }
    .amount {
      font-size: 24px;
      font-weight: 700;
      color: #404553;
    }
  }
}
