.ali-day-mob {
    background-color: white;

    .section {
        padding: 20px 0px 30px 0px;
    }
.neverwet{
    padding-left: 14px;
    padding-right: 14px;
    padding-top: 8px; 
}
    .alidaysecname.grey {
        color: #555555;
        font-family: 'Futura Heavy';
        font-size: 24px;
        margin-bottom: 5px;
    }
   
    .alidaysecname {
        color: #742013;
        font-family: 'Futura Heavy';
        font-size: 24px;
        margin-bottom: 5px;
    }

    .emptyspacer {
        height: 15px;
    }
.emptyspacermid{
    height: 7px;
}
    .alidaysecdetails {
        font-family: 'Futura';
        font-size: 15px !important;
        letter-spacing: 0.2px;
    }

    .alidaysubtitle {
        font-family: 'Futura';
        font-size: 16px !important;
    }

    .catalog1 {
        padding-right: 0px;
    }

    .catalog2 {
        padding-right: 8px;
    }

    .catalog3 {
        padding-left: 8px;
    }

    .catalog4 {
        margin-top: 14px;
        margin-bottom: 0px !important;
    }

    .catalogsection 
    {
    
    .row {
        padding-left: 6px;
        padding-right: 6px;
    }
    .col-6 {
        padding-left: 7px;
        padding-right: 7px;
        padding-bottom: 5px;
    }
    }

    .bg-light-theme {
        background-color: #d4982b21;
    }

    .herosec {

        button.quick-view-btn.btn.btn-secondary {
            padding-left: 30px;
            padding-right: 30px;
        }

        a {
            text-decoration: none;
        }

        h4 {
            color: #212529;
            font-family: 'Futura Heavy';
        }

        .herodesc {
            color: #212529;
            margin-top: 15px;
            font-weight: 500 !important;
        }

        .heroprice {
            margin-top: 15px;
        }

        .herofeatures {
            margin-bottom: 10px;

            p {
                text-decoration: none;
                color: #212529;
                margin: 0px !important;
            }
        }
    }

    .topicons {
        width: 30px;
        height: 30px;
    }

    .heroimagecol {
        text-align-last: center;
    }

    .combosec {
        a {
            text-decoration: none !important;
        }

        img {
            background-color: #f7e4ba;
            padding-left: 31px;
            padding-right: 31px;
            height: 110px;
        }

        .oddcombo {
            padding-right: 7.5px;
        }

        .evencombo {
            padding-left: 7.5px;
        }

        .top-space {
            margin-top: 8px;
        }

        .combocontent {
            background-color: #ddae52;

            .my-1 {
                margin-top: 0px !important;
            }
        }

        .comboprice {
            color: #742013;
            font-size: 18px;
            font-weight: 600;
            font-family: 'Futura Heavy';
            line-height: 0.5;
        }

        .comboname {
            color: #742013;
            font-size: 18px;
            font-weight: 600;
            text-decoration: none;
        }

        .col-md-3 {
            padding-right: 8px !important;
            padding-left: 8px !important;
        }
    }

    .sbcatsection {
        .combocontent {
            text-align: center;
            background-color: #ddae52;

            .my-1 {
                margin-top: 0px !important;
            }
        }

        .my-1 {

            line-height: 1 !important;
        }

        .comboprice {
            color: #742013;
            font-size: 20px;
            font-weight: 600;
            font-family: 'Futura Heavy';
            line-height: 0.5;
        }

        .comboname {
            color: #742013;
            font-size: 20px!important;
            font-weight: 600;
            text-decoration: none;
        }

        .cat-img:before {
            content: "";
            position: absolute;
            top: -9px;
            left: 0px;
            width: 100%;
            height: 15px;
            background: linear-gradient(135deg, #fff 12px, transparent 0%), linear-gradient(-135deg, #fff 12px, transparent 0%);
            background-size: 18px;
        }

        .evenslide {
            .sbc {
                background-color: #27aa88;

            }

            .combocontent {
                background-color: #add7cc;
                // width: 150px;
                padding: 5px;
                height: 54px;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .comboname {
                color: #107258;
                font-family: 'Futura Heavy';
            }

            .comboprice {
                color: #107258;
                font-family: 'Futura Heavy';
            }
        }

        .custom-p-right {
            padding-right: 8px;
        }

        .custom-p-left {
            padding-left: 7px;
        }

        .oddslide {
            .sbc {
                background-color: #27aa88;
            }

            .combocontent {
                background-color: #add7cc;
                // width: 150px;
                padding: 5px;
                height: 54px;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .comboname {
                color: #107258;
                font-family: 'Futura Heavy';
            }

            .comboprice {
                color: #107258;
                font-family: 'Futura Heavy';
            }
        }

        .evenslide {
            .sbc {
                background-color: #ff9a84;

            }

            .combocontent {
                background-color: #fbc0b4;
                // width: 150px;
            }

            .comboname {
                color: #eb502f;
                font-family: 'Futura Heavy';
            }

            color: #eb502f;
            font-family: 'Futura Heavy';
        }
    }



    button.quick-view-btn.btn.btn-secondary {
        margin-top: 15px;
        background-color: #742013;
        border: 1px solid #742013;
        border-radius: 100px;
    }

    .pagesbanner {
        padding-top: 15px;
        padding-bottom: 30px;
    }

    .paintcat {
        background-color: #f7e4ba;
        border: none;
        border-radius: 2px;
    }

    .brandfav img {
        border-radius: 100px;
        border: 2px solid #742013;
    }

    .paintbrand {
        background-color: #fcfaf4;
        border: 1px solid #d4982b;
        border-radius: 100px;
    }

    .topofferimg {
        width: 175px;
        -webkit-transform: rotate(362deg);
    }

    .catwelove .col-sm-4 {
        padding: 8px !important;
    }

    .offers.catbox {
        padding: 15px;
    }

    .six6.catsecpic img {
        border: 1px solid #9e9e9e52;
    }

    .surfacepat .product-list-desktop .product-list-container {

        // background-color: #fcfaf4;
    }

    .catrow {
        display: flex;

    }

    .six6 img {
        border: none;
    }

    .catbox {
        padding: 0px;
    }

    .offerbg {
        background-color: #ffffff;
        border-bottom: 140px solid #f8e89a;
        border-left: 23px solid transparent;
        border-right: 14px solid transparent;
        height: 0;
        border-radius: 50px;
        margin-top: 30px !important;
        margin: 15px;
        transform: rotate(2deg);
        -webkit-transform: rotate(358deg);
        -moz-transform: rotate(2deg);
        text-align: right;
    }

    .sectiontitle-individualpage {
        margin-bottom: 0px;
        text-align: center;
        color: #212529;
        font-weight: 600;
        font-size: 26px;
        font-family: 'Futura Heavy';
    }

    .titlewrap.noclr {
        background-color: #ffffff;
    }

    .offertitles {
        text-align: left;
        float: left;
        width: 170px;
        font-family: 'Futura heavy';
        margin-top: 29px;
        font-size: 35px;
        margin-left: 8PX;
        text-transform: uppercase;
        -webkit-transform: rotate(362deg);
        line-height: 0.9;
        /* -webkit-text-stroke: 1px white; */
        -webkit-text-stroke: thin;
    }

    span.offerprice {
        font-family: 'Futura heavy';
        margin-top: 29px;
        color: #ff5722;
    }

    .mypages-container {
        margin-right: auto !important;
        margin-left: auto !important;
        padding-left: 10px !important;
        padding-right: 10px !important;
    }
}