@import "../../../mixins.scss";
@import "../../../variables.scss";

.country-mob {
  padding-top: 15%;
  max-width: 768px !important;
  margin: auto;
  img {
    border-radius: 2px;
  }
  .logo-cont {
    width: 100%;

    .logo-size {
      margin-left: auto;
      margin-right: auto;
      width: 53%;
      height: 16%;
    }
  }

  .heading {
    margin-top: 15%;
    color: #404553;
    font-weight: 700;
    font-size: 1.7rem;
    text-align: center;
  }

  .body-text {
    color: rgb(126, 133, 155);
    font-size: 0.75rem;
    text-align: center;
  }

  .country-cont {
    margin-top: 12%;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 75%;
    height: 55px;
    margin-left: auto;
    margin-right: auto;
    border: 0.2px solid $border-color;
    border-radius: 3px;

    .flag {
      display: inline-flex;
      width: 23px;
      height: 15px;
      margin-inline-start: 15px;
    }
    .text {
      display: inline-flex;
      margin-inline-start: 15px;
      color: #404553;
      font-size: 15px;
      font-weight: 700;
    }
  }
}
