@import "../../../mixins.scss";

.customer-address-data-mobile {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: white;
  margin-top: 10px;
  margin-bottom: 10px;

  .data-header {
    margin-bottom: 10px;
    margin-left: 20px;
    margin-right: 10px;
    padding-bottom: 9px;
    border-bottom: 0.5px solid #eee6e6;

    .label {
      color: #40434d;
      font-weight: 700;
      font-size: 14px;
    }

    .default {
      color: white;
      font-weight: 600;
      font-size: 12px;
      background-color: #3e64d5;
      border-radius: 18px;
      padding: 1px 9px;
    }

    .edit-cont {
      font-size: 13.5px;

      .edit-delete {
        color: #40434d;
        margin-inline-end: 1rem;
        margin-inline-start: 0.5rem;
      }
    }
  }

  .data-body {
    margin-left: 20px;
    margin-right: 20px;
    text-align: start;
    .text-key {
      color: #b1b0b4;
      font-size: 13px;
      width: 35%;
    }
    .text-value {
      width: 65%;
      color: #62656a;
      font-size: 13px;
    }
  }
}
