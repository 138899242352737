.my-account-list-items-mobile {
  .header-logged-out {
    background-color: #f6f9ff;
    opacity: 0.8;
    color: #767d9f;
    font-weight: 600;
    font-size: 15px;
    padding-inline-start: 15px;
    height: 60px;
    display: flex;
    align-items: center;
  }

  .header-logged-in {
    background-color: #f6f9ff;
    opacity: 0.8;
    color: #767d9f;
    font-size: 14px;
    font-weight: bold;
    margin-inline-start: 15px;
    height: 50px;
    display: flex;
    align-items: center;
  }
  .list-main {
    background-color: white;
    .list-items {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-left: 15px;
      margin-right: 15px;
      background-color: white;
      height: 60px;
      border-bottom: 1px solid #e1e1e3;
      &:last-child {
        border-bottom: none;
      }
      .left-pan {
        display: flex;
        width: 180px;
        height: auto;
        align-items: center;
        .left-icon {
          justify-self: flex-start;
        }
        .title-logged-out {
          color: #474c59;
          font-weight: bold;
          font-size: 13px;
          margin-inline-start: 30px;
        }
        .title-logged-in {
          color: #474c59;
          font-weight: bold;
          font-size: 13px;
          margin-inline-start: 30px;
        }
      }
      .right-pan {
        display: flex;
        .right-icon {
          margin-inline-end: 10px;
          .inner-span {
            width: 40px;
            height: 20px;
            font-size: 13px;
            font-weight: bold;
          }
        }
        .forward-icon {
        }
      }
    }
  }
}
