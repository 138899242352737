@import "../mixins.scss";

.signin-dropdown {
  font-size: 17px;
  $font-color: rgb(64, 69, 83);
  $icon-dimension: 28px;
  width: 100%;
  background-color: white;

  .item-row {
    cursor: pointer;
    font-size: 0.75em;
    padding: 12px 15px;
    color: rgb(64, 69, 83);

    .item-icon {
      border-radius: 100%;
      background-color: rgb(247, 247, 250);
      margin-right: 8px;
      height: $icon-dimension;
      width: $icon-dimension;
      max-width: $icon-dimension;
      max-height: $icon-dimension;

      svg {
        max-width: $icon-dimension;
        max-height: $icon-dimension;
        margin-right: unset;
        margin-top: unset;
      }
    }

    .item-text {
      text-align: start;
      text-decoration: none;
    }

    &:hover {
      background-color: white;
      text-decoration: none !important;
    }
  }

  .sigout-row {
    padding: 15px;
    border-top: 1px solid rgb(233, 233, 233);

    .signout-text {
      color: rgb(64, 69, 83);
      font-size: 0.8rem;
      font-weight: 400;
    }
  }
}
