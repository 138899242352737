@import "../mixins.scss";

.search-popover-mobile {
  width: 100%;
  position: absolute;
  z-index: 999999;
  left:0%;
  top: 70px;
  // top: 100px;
  box-shadow: 0px 5px 4px 0px rgba(0, 0, 0, 0.18);
  background-color: white;
  max-height: 400px;
  overflow-y: auto;

  .search-popover-row {
    margin: 0px !important;
    width: 100%;
    .trending-searches {
      .trending-tags {
        padding: 10px;
      }

      .trending-left-col {
        font-size: 0.8rem;
        font-weight: 600;
        color: #000000;
        letter-spacing: 0.3px;
        padding: 10px;
      }

      span {
        margin: 5px;
        border: 1px solid #ccc;
        background-color: #d4982b2b;
        padding-left: 4px;
        padding-right: 4px;
        border-radius: 4px;
        display: inline-block;
      }

      span a {
        text-decoration: none;
        color: black;
        font-size: 0.8rem;
      }
    }

    .recent-search {
      width: 100%;

      .recent-search-row {
        width: 100%;
        padding: 10px;

        .recent-left-col {
          font-size: 0.8rem;
          font-weight: 600;
          color: #000000;
          letter-spacing: 0.3px;
       
        }

        .recent-right-col {
          font-size: 0.7rem;
          font-weight: 400;
          color: $head-color;
        }
      }

      .recent-search-result-row {
        width: 100%;
        padding: 5px 10px;

        .recent-result-col {
          .icon {
            padding-right: 10px;
            font-size: 0.8rem;
            font-weight: 400;
            color: $head-color;

            svg {
              font-size: 1.2rem;
            }
          }
          .text {
            font-size: 0.8rem;
            font-weight: 400;
            color: $head-color;
          }
        }

        .icon-col {
          font-size: 1rem;
          font-weight: 400;
          color: $head-color;
        }
      }
    }

    .search-col {
      width: 100%;

      a {
        display: block;
      }
      .search-result-col {
        @include for-md-screen {
          width: 50%;
        }

        width: 60%;

        ul {
          margin: 0px !important;
          padding: 0px !important;
          margin-top: 5px !important;

          li {
            list-style: none;
            width: 100%;

            a {
              text-decoration: none;
              display: flex;
              align-items: center;
              padding: 3px;
              margin-bottom: 3px;
              font-size: 0.75rem;
              color: $head-color;
              width: 100%;

              .highlighted {
                color: royalblue;
                font-weight: 700;
              }
            }
          }
        }
      }

      .search-right-col {
        @include for-md-screen {
          width: 50%;
        }
        width: 40%;

        .wrapper {
          margin-top: 5px;
          border-left: 1px solid rgb(235, 236, 240);
          margin-right: 5px;

          .header {
            padding-top: 5px;
            font-weight: 700;
            font-size: 0.6rem;
            background-color: #d4982b2b;
            color: $p-color;
          }

          .category-name,
          .brand-name {
            text-decoration: none;
            display: block;
            margin-bottom: 3px;
            font-size: 0.75rem;
            color: $head-color;
            padding-left: 7px;
    padding-right: 7px;

            .category-search-item {
              padding: 0.3rem 0;
              overflow-wrap: anywhere;
            }

            .brand-search-item {
              padding: 0.3rem 0;
              overflow-wrap: anywhere;
            }
          }
        }
      }
    }
  }
}
