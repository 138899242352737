@import "../../../mixins.scss";

.item-checkbox-desktop {
  width: 100%;

  .search-style {
    border: 0.5px solid black;
    box-shadow: none;
    font-size: 13px;
    border-radius: 2px;
    padding: 1px 10px;

    @include for-mob-screen {
      border-radius: 4px;
      padding: 8px 15px;
      border: 1px solid rgb(243, 244, 248);
    }
    &:hover {
      border: 0.5px solid black;
      box-shadow: none;
      border-radius: 2px;
      @include for-mob-screen {
        border-radius: 4px;
        border: 1px solid rgb(243, 244, 248);
      }
    }
    &:focus {
      border: 0.5px solid black;
      box-shadow: none;
      border-radius: 2px;
      outline: none;
      @include for-mob-screen {
        border-radius: 4px;
        border: 1px solid rgb(243, 244, 248);
      }
    }
  }

  .items-checkbox {
    display: flex;
    margin-bottom: 10px;

    @media (min-width: 993px) {
      .checkbox-cont {
        display: flex;
        width: 100%;
        justify-content: space-between;
        margin-inline-start: 6px;

        .checkbox-text {
          font-size: 12px;
          color: #404553;
        }
      }
    }

    @include for-md-screen {
      .checkbox-cont {
        display: flex;
        width: 95%;
        justify-content: space-between;
        margin-inline-start: 6px;

        .checkbox-text {
          font-size: 12px;
          color: #404553;
        }
      }
    }
    @include for-mob-screen {
      .checkbox-cont {
        display: flex;
        width: 100%;
        justify-content: space-between;
        margin-inline-start: 10px;
        .checkbox-text {
          font-size: 12px;
          color: #404553;
        }
      }
    }
  }

  .view-more {
    font-size: 12px;
    background-color: transparent;
    color: #d4982b;
    outline: none;
    border: 0;
  }
}
