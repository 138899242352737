@import "../mixins.scss";

.page-container {
  .header-mobile {
    position: sticky;
    top: 0;
    z-index: 99999;
    background-color: #fff;
  }
}
