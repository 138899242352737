@import "../../../mixins.scss";

.profile-map-desktop {
  .map-row {
    width: 100%;
    padding: 0px 30px;
    margin-top: 30px;
    margin-bottom: 30px;

    @include for-md-screen {
      padding: 0px 20px;
    }

    @include for-lg1-screen {
      padding: 0px 30px;
    }

    .adress-col {
      padding: 10px 0px 10px 20px;
      background-color: rgba(231, 231, 241, 1);
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
      .label {
        color: #3f3f3f;
        font-size: 0.9rem;
        font-weight: 700;
      }
    }

    .map-col {
      padding: 0px;
      height: 370px;
    }

    .confirm-location-col {
      padding: 10px 20px 10px 0px;

      .location-button {
        padding: 10px 20px;
        line-height: 15px;
        color: #ffffff;
        font-size: 0.8rem;
        font-weight: 700;
        border-radius: 3px;
      }
      .location-background-inactive {
        background-color: rgba(231, 231, 241, 1);
        border: 1px solid rgba(231, 231, 241, 1);
      }
      .location-background-active {
        background-color: #d3af44;
        border: 1px solid #d3af44;
      }
      .back-button {
        padding: 10px 20px;
        line-height: 15px;
        color: #454545;
        font-size: 0.8rem;
        font-weight: 700;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
        background: #fff;
        border: none;
        border-radius: 3px;
      }
    }
  }
}
