.profile-mobile {
  .prfoile-cont {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 12px 6px 12px 6px;
    margin-bottom: 18px;

    .name {
      color: #404553;
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 5px;
    }
    .email {
      color: rgb(126, 133, 155);
      font-size: 11px;
    }
  }

  a {
    color: rgb(64, 69, 83);
    text-decoration: none;
  }
  .general-info-cont {
    margin-inline-start: 6px;
    margin-inline-end: 6px;
    text-align: start;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
    font-size: 14px;
    font-weight: 600;
    .general-text {
      background-color: rgb(241, 243, 250);
      color: #404553;
    }
  }

  .general-info-values {
    margin-left: 6px;
    margin-right: 6px;
    padding: 15px;
    background: white;

    .title {
      color: rgb(126, 133, 155);
      font-size: 13px;
      text-align: start;
    }

    .input {
      border: none;
      outline: none;
      background: transparent;
      color: rgb(64, 69, 83);
      font-size: 16px;
      width: 100%;
      margin-bottom: 25px;
      border-bottom: 1px solid rgb(226, 229, 241);
      &:hover {
        border: none;
        outline: none;
        border-bottom: 1px solid rgb(226, 229, 241);
      }
      &:focus {
        border: none;
        outline: none;
        border-bottom: 1px solid rgb(226, 229, 241);
      }
    }

    .button-cont {
      display: flex;
      -webkit-box-pack: end;
      justify-content: flex-end;
      margin-top: 15px;

      .button {
        border-radius: 3px;
        padding: 3px 14px;
        width: 190px;
        color: rgb(64, 69, 83);
        background-color: rgb(255, 255, 255);
        border: 1px solid rgb(226, 229, 241);

        .button-text {
          font-size: 15px;
          font-weight: bold;
          text-align: center;
          padding: 10px;
          cursor: pointer;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
        }
      }
    }

    .lang-cont {
      margin-top: 10px;
      margin-bottom: 25px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      border-bottom: 1px solid rgb(226, 229, 241);
      padding-bottom: 0px;

      .input-lang {
        border: none;
        outline: none;
        background: transparent;
        color: rgb(126, 133, 155);
        font-size: 14px;
      }
    }
    .options-cont {
      border: 1px solid rgb(226, 229, 241);
      border-radius: 3px;
      margin-top: -22px;
      background: rgb(241, 243, 250);
      padding: 10px 10px;
    }
  }

  .security-cont {
    margin: 15px 6px;
    text-align: start;
    .security-text {
      background-color: rgb(241, 243, 250);
      padding: 10px 15px;
      font-size: 14px;
      font-weight: 600;
      color: #404553;
    }
  }

  .security-values {
    margin-left: 6px;
    margin-right: 6px;
    padding: 15px;
    background: white;
    width: 200px;
    .title {
      color: rgb(126, 133, 155);
      font-size: 13px;
      text-align: start;
    }

    .input {
      border: none;
      outline: none;
      background: transparent;
      color: rgb(126, 133, 155);
      font-size: 14px;
      width: 100%;
      margin-bottom: 25px;
      border-bottom: 1px solid rgb(226, 229, 241);
      &:hover {
        border: none;
        outline: none;
        border-bottom: 1px solid rgb(226, 229, 241);
      }
      &:focus {
        border: none;
        outline: none;
        border-bottom: 1px solid rgb(226, 229, 241);
      }
    }

    .button-cont {
      display: flex;
      -webkit-box-pack: end;
      justify-content: flex-end;
      margin-top: 15px;

      .button {
        border-radius: 3px;
        padding: 3px 14px;
        width: 190px;
        color: rgb(64, 69, 83);
        background-color: rgb(255, 255, 255);
        border: 1px solid rgb(226, 229, 241);

        .button-text {
          font-size: 15px;
          font-weight: bold;
          text-align: center;
          padding: 10px;
          cursor: pointer;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}
