@import "../../mixins.scss";
@import "../../variables.scss";
.review-desktop {
  .back-col {
    .icon {
      font-size: 1.5rem;
      font-weight: 400;
      color: $head-color;
    }

    .text {
      font-size: 0.9rem;
      font-weight: 400;
      color: $head-color;
    }
  }

  .feedback {
    padding-left: 10px;
  }
  .review-container {
    margin-top: 17px;
    background-color: white;
    box-shadow: 0px 0px 5px rgba($color: #000000, $alpha: 0.1);
    border-radius: 4px;

    .review-header {
      text-align: start;
      padding: 5px 15px;
      background-color: #f0f3fa;
      color: $head-color;
      font-size: 1rem;
      font-weight: 500;
    }

    .review-body {
      .item-header-text {
        text-align: start;
        padding: 8px 10px;
        font-size: 0.9rem;
        font-weight: 400;
        color: $head-color;
      }
      .item-container {
        margin: 5px 0px 5px 10px;
        width: 60%;
        border: 1px solid $border-color;
        padding: 15px 5px;

        @media screen and (max-width: 1024px) {
          width: 95%;
        }

        .image-container {
          width: 80px;
          height: 80px;
        }

        .description-container {
          text-align: start;
          .text {
            margin-bottom: 4px;
            text-transform: capitalize;
            font-size: 1rem;
            font-weight: 400;
            color: $head-color;
          }

          .delivered-by {
            font-size: 1rem;
            font-weight: 450;
            color: rgb(58, 174, 3);
          }
        }
      }

      .store-info-row {
        width: 70%;
        padding: 5px 10px 10px;
        border-bottom: 1px solid $border-color;
        margin-bottom: 10px;

        @media screen and (max-width: 1024px) {
          width: 100%;
        }

        .store-info {
          .text {
            font-size: 1rem;
            font-weight: 400;
            color: $head-color;
          }

          .store-icon {
            margin: 0 10px;
            width: 30px;
            height: 30px;
            background-color: #f0f3f8;
            border-radius: 100%;
          }

          .store-name {
            font-size: 1rem;
            font-weight: 600;
            color: $head-color;
          }
        }

        .store-review-col {
          .text {
            font-size: 1rem;
            font-weight: 400;
            color: $head-color;
          }

          .rating {
            .fill-color {
              color: yellow;
            }

            .empty-color {
              color: $p-color;
            }

            svg {
              margin-left: 5px;
              width: 20px;
              height: 20px;
            }
          }
        }
      }

      .feedback-row {
        padding: 5px 10px;
        width: 70%;

        @media screen and (max-width: 1200px) {
          width: 100%;
        }

        .feedback-col {
          padding-inline-end: 10px;

          .feedback-header-row {
            .header-text {
              font-size: 1rem;
              font-weight: 500;
              color: $head-color;
              margin-bottom: 5px;
            }
            .feedback-optional-text {
              font-size: 1rem;
              font-weight: 400;
              color: $p-color;
            }
          }
          textarea {
            border: 2px solid $border-color;
            border-radius: 2px;
            outline: none;
            box-shadow: none;
          }
        }

        .your-review-col {
          padding-inline-start: 10px;

          .your-review-header {
            text-align: start;
            font-size: 1rem;
            font-weight: 500;
            color: $head-color;
            margin-bottom: 5px;
          }

          .verified-review {
            background-color: rgb(248, 246, 234);
            height: 130px;
            padding: 15px 10px;

            .review-with-date {
              .rating-bar {
                margin-bottom: 25px;

                .fill-color {
                  color: yellow;
                }

                .empty-color {
                  color: $p-color;
                }

                svg {
                  margin-left: 5px;
                  width: 20px;
                  height: 20px;
                }
              }

              .date {
                font-size: 1rem;
                font-weight: 400;
                color: $head-color;
              }
            }
            .verified-icon {
              .icon {
                svg {
                  width: 20px;
                  height: 20px;
                  color: $p-color;
                }
              }

              .text {
                margin-left: 5px;
                font-size: 1rem;
                font-weight: 400;
                color: $head-color;
              }
            }
          }
        }
      }

      .radio-buttons-group-row {
        padding: 5px 10px;

        .toggle-button {
          margin-bottom: 20px;

          .text {
            margin-left: 10px;
            font-size: 1rem;
            font-weight: 400;
            color: $head-color;
          }
        }
        .item-row {
          width: 70%;
          border-bottom: 1px solid $border-color;
          margin-bottom: 10px;

          .radio-text {
            margin-left: 10px;
            font-size: 1rem;
            font-weight: 600;
            color: $head-color;
            margin-bottom: 8px;
          }
          .radio-button {
            margin-bottom: 10px;
            margin-left: 15px;

            .circle {
              width: 15px;
              height: 15px;
              border: 1px solid $border-color;
              border-radius: 100%;
              margin-right: 7px;
            }
            .label {
              margin-bottom: -2px;
            }
          }
        }
        .submit-button-row {
          .submit-button {
            background-color: $form-btn-bg;
            color: white;
            font-size: 1rem;
            font-weight: 600;
            border: none;
            outline: none;
            box-shadow: none;
            border-radius: 4px;
            text-align: center;
            width: 230px;
            height: 44px;
          }
          padding-bottom: 15px;
        }
      }
    }
  }
  .error {
    color: red;
    font-size: 13px;
    margin-top: 3px;
  }
}
