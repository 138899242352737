.modal-dialog {
    max-width: 684px !important;
}

.modal-content {
    border-radius: 0.5rem;
}

.text {
    font-family: Futura Heavy !important
}