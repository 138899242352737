.middle-offers-mob {
    padding: 10px;
 
.mib{
    height:unset;
}
.shop-text{
    text-align: center;
    display: block;
    padding-bottom: 15px;
}
.col-lg-6.mib {
    padding-left: 15px;
    padding-right: 15px;
}
.col-lg-6.mib .col-6 img {
    height: 100%;
}
.col-lg-6.mia {
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 20px;
}
.col-lg-6.mib .col-6 {
    margin-bottom: 15px;
    padding-left: 10px;
    padding-right: 10px;
}
.homeofferimg {
    height: 100%;
    width: 100%;
    border-radius: 5px;
}

.nav-tabs .nav-link {
    color: black;
    font-weight: 500;
}
 .nav-link.active {
    font-weight: 600;
    color: red !important;
}
nav.mb-3.justify-content-center.nav.nav-tabs a {
    display: inline-block;
}
nav.mb-3.justify-content-center.nav.nav-tabs {
    overflow: scroll;
    white-space: nowrap;
    display: block;
    overflow-y: hidden;
}
} 