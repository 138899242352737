.notification-card {
    padding: 15px;
    border-bottom: 1px solid #ebecf0;
    transition: background-color 0.3s ease;
    cursor: pointer;
  }
  
  .notification-card h4 {
    margin: 0;
    font-size: 18px;
    color: #404553;
  }

  .notification-card:hover {
    background-color: rgb(239, 242, 247) !important;
  }
  
  .notification-card p {
    font-size: 14px;
    margin: 5px 0;
    color: #777;
  }

  .notification-empty {
    color: #000;
    text-align: center;
    font-weight: 500;
    padding-top: 20px,
  }
