@import "../../../mixins.scss";
@import "../../../variables.scss";

.items-page-desktop {
  @media (min-width: 993px) {
    .sidebar-desktop {
      width: 20%;
    }

    .items-desktop {
      margin-top: 40px;
      width: 100%;
    }
  }

  @include for-md-screen {
    .sidebar-desktop {
      width: 25%;
    }

    .items-desktop {
      margin-top: 40px;
      width: 70%;
    }
  }
}

.items-page-mobile {
  .all-page-container {
    @include for-mob-screen {
      padding: 0px;
    }
  }

  .mobile-header {
    @include for-desktop-screen {
      display: none;
    }

    @include for-mob-screen {
      padding: 0px 10px;
      background: #fff;
      height: 50px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .logo {
      }

      .mobile-search {
        background-color: #fff;
        width: 72%;
        height: 70%;
        border: 1px solid rgb(198, 204, 221);
        border-radius: 8px;
        margin-left: 2%;
        display: flex;
        align-items: center;

        .input-field {
          border: none;
          outline: none;
          background-color: transparent;
          width: 80%;
          font-size: 11.5px;
          font-weight: 500;
          color: #979aa7;
          &::placeholder {
            color: #979aa7;
          }
          &:hover {
            outline: none;
          }
          &:focus {
            outline: none;
          }
        }
      }
    }
  }

  .sidebar-desktop {
    @include for-mob-screen {
      display: none;
    }
  }

  .items-desktop {
    @include for-mob-screen {
      width: 100%;
    }
  }

  .header-mobile {
    position: sticky;
    top: 0;
    z-index: 99999;
    background-color: #fff;
    @include for-mob-screen {
      display: none;
    }
  }

  .footer-mobile {
    @include for-mob-screen {
      display: none;
    }
  }
}
