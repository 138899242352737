@import "../mixins.scss";

.address-form-desktop {
  background-color: transparent;

  .mt-error {
    margin-top: -20px;
    margin-bottom: 20px;
  }
  .mt2-error {
    margin-top: -20px;
    margin-bottom: 20px;
  }

  .error {
    color: red;
    font-size: 12px;
    text-align: start;
  }
  .back-icon-col {
    font-size: $customer-page-subheading-size;
    font-weight: $customer-page-subheading-weight;
    color: $head-color;
    margin-bottom: 20px;
    svg {
      margin-inline-end: 8px;
    }
  }
  .add-address-form {
    width: 100%;
    border: 1px solid rgb(226, 229, 241);
    background-color: rgb(255, 255, 255);

    .header-col {
      width: 100%;
      background-color: rgb(252, 251, 244);
      padding: 10px 15px 9px;
      border-bottom: 1px solid $border-color;

      .edit-address {
        font-size: 0.8125rem;
        font-weight: 600;
        color: $head-color;
      }

      .default-address {
        font-size: 0.8rem;
        font-weight: 400;
        color: $head-color;

        label {
          margin-bottom: 0px;
          margin-left: 10px;
        }
      }
    }

    .form-col {
      width: 100%;
      padding: 15px 15px 25px;

      .information-col {
        padding-inline-start: 3px;

        .location-info-col {
          @include for-md-screen {
            padding-inline-end: 10px;
          }

          @include for-lg1-screen {
            padding-inline-end: 10px;
          }

          .header {
            margin-bottom: 0px;

            .label {
              font-size: $header-heading-size;
              font-weight: 700;
              color: $head-color;
              padding-inline-start: 5px;
            }

            img {
              width: 34px;
              height: 20px;
              padding-inline-end: 5px;
            }
          }

          .set-map {
            border: 1px solid $border-color;
            border-radius: 5px;
            margin: 20px 0px 58px;

            .map-selection {
              padding: 10px;

              .label {
                font-size: 0.7rem;
                font-weight: 600;
                color: $head-color;

                svg {
                  margin-bottom: 5px;
                  margin-right: 5px;
                  font-size: 1rem;
                }
              }

              .selection-heading {
                font-size: 0.8rem;
                font-weight: 600;
                color: $head-color;
              }
            }

            .map {
              width: 80px;
              height: 80px;
              padding: 7px;
            }
          }

          .input-ltr {
            border-bottom: 1px solid #e2e5f1;
            margin-bottom: 20px;
            margin-top: 15px;

            .MuiFormControl-root {
              label {
                font-size: 1rem;
                font-weight: 400;
                color: $p-color;
              }

              .MuiInputBase-root {
                &::before {
                  border-bottom: 1px solid $border-color;
                }

                &::after {
                  border-bottom: 1px solid $border-color;
                }

                .MuiInputAdornment-root {
                  color: gray !important;
                }
              }
            }
          }
          .input-rtl {
            border-bottom: 1px solid #e2e5f1;
            margin-bottom: 20px;
            margin-top: 15px;

            .MuiFormControl-root {
              label {
                left: auto;
                font-size: 1rem;
                font-weight: 400;
                color: $p-color;
              }

              .MuiInputBase-root {
                &::before {
                  border-bottom: 1px solid $border-color;
                }

                &::after {
                  border-bottom: 1px solid $border-color;
                }

                .MuiInputAdornment-root {
                  color: gray !important;
                }
              }
            }
          }
        }

        .personal-info-col-ltr {
          padding-inline-start: 10px;
          padding-inline-end: 10px;
        }
        .personal-info-col-rtl {
          padding-inline-end: 10px;
          padding-inline-start: 10px;
        }
        @include for-md-screen {
          padding-inline-start: 3px;
        }

        @include for-lg1-screen {
          padding-inline-start: 3px;
        }

        .header {
          margin-bottom: 20px;

          .label {
            font-size: $header-heading-size;
            font-weight: 700;
            color: $head-color;
          }
        }

        .intl-phone-ltr {
          border-bottom: 2px solid $border-color;
          margin-bottom: 20px;
          margin-top: 30px;

          input {
            border: none !important;
            box-shadow: none !important;
            outline: none !important;
            margin-left: 10px;
          }

          .flag-dropdown {
            background-color: #ffffff !important;
            border: none !important;
          }
        }
        .intl-phone-rtl {
          border-bottom: 2px solid $border-color;
          margin-bottom: 20px;
          margin-top: 30px;
          margin-right: 20px;

          input {
            border: none !important;
            box-shadow: none !important;
            outline: none !important;
            margin-left: 10px;
            direction: rtl;
            margin-right: 35px;
          }

          .flag-dropdown {
            background-color: #ffffff !important;
            border: none !important;
          }
        }

        .first-name-ltr {
          border-bottom: 1px solid $border-color;
          margin-bottom: 20px;
          margin-top: 30px;

          .MuiFormControl-root {
            label {
              font-size: 1rem;
              font-weight: 400;
              color: $p-color;
            }
            .MuiInputBase-root {
              &::before {
                border-bottom: 1px solid $border-color;
              }

              &::after {
                border-bottom: 1px solid $border-color;
              }

              .MuiInputAdornment-root {
                color: gray !important;
              }
            }
          }
        }
        .first-name-rtl {
          border-bottom: 1px solid $border-color;
          margin-bottom: 20px;
          margin-top: 30px;

          .MuiFormControl-root {
            label {
              left: auto;
              font-size: 1rem;
              font-weight: 400;
              color: $p-color;
            }
            .MuiInputBase-root {
              &::before {
                border-bottom: 1px solid $border-color;
              }

              &::after {
                border-bottom: 1px solid $border-color;
              }

              .MuiInputAdornment-root {
                color: gray !important;
              }
            }
          }
        }

        .last-name-ltr {
          border-bottom: 1px solid $border-color;
          margin-bottom: 20px;
          margin-top: 30px;
          .MuiFormControl-root {
            label {
              font-size: 1rem;
              font-weight: 400;
              color: $p-color;
              text-align: start;
            }

            .MuiInputBase-root {
              &::before {
                border-bottom: 1px solid $border-color;
              }

              &::after {
                border-bottom: 1px solid $border-color;
              }

              .MuiInputAdornment-root {
                color: gray !important;
              }
            }
          }
        }
        .labels-input-wrapper {
          display: flex;
          width: 300px;
          font-size: 13px !important;
          .radio-button {
            cursor: pointer;
          }
        }
        .last-name-rtl {
          border-bottom: 1px solid $border-color;
          margin-bottom: 20px;
          margin-top: 30px;

          .MuiFormControl-root {
            label {
              left: auto;
              font-size: 1rem;
              font-weight: 400;
              color: $p-color;
              text-align: start;
            }

            .MuiInputBase-root {
              &::before {
                border-bottom: 1px solid $border-color;
              }

              &::after {
                border-bottom: 1px solid $border-color;
              }

              .MuiInputAdornment-root {
                color: gray !important;
              }
            }
            .new-upcoming-label {
              display: flex;
              align-items: center;
            }
          }
        }
      }

      .add-label {
        .MuiFormControl-root {
          label {
            left: auto;
            font-size: 1rem;
            font-weight: 400;
            color: $p-color;
            text-align: start;
          }

          .MuiInputBase-root {
            &::before {
              border-bottom: 1px solid $border-color;
            }

            &::after {
              border-bottom: 1px solid $border-color;
            }

            .MuiInputAdornment-root {
              color: gray !important;
            }
          }
        }
      }
    }
    .button-group-col {
      .address-label {
        width: 50%;
        padding-right: 10px;
        padding-left: 0px;

        .label {
          width: 40%;

          .bold-text {
            font-size: 0.8rem;
            font-weight: 700;
            color: $head-color;
          }

          .small-text {
            font-size: 0.8rem;
            font-weight: 400;
            color: $head-color;
          }
        }

        .input-group {
          width: 60%;

          .radio-button {
            cursor: pointer;

            label {
              font-size: 0.8rem;
              font-weight: 600;
              color: $head-color;
            }
          }
        }
      }

      .save-address {
        width: 50%;
        padding-left: 10px;
        padding-right: 0px;

        .add-button {
          cursor: pointer;
        }
        .label {
          padding-inline-start: 5px;
          font-size: 13px;
          font-weight: 600;
          color: $head-color;
        }

        .save-button {
          .button {
            text-align: center;
            width: 150px;
            min-width: 150px;
            height: 42px;
            min-height: 42px;
            padding: 10px;
            color: #ffffff;
            font-size: 0.8rem;
            font-weight: 700;
            background-color: #d3af44;
            border: 1px solid #d3af44;
          }
        }
      }
      .new-upcoming-label {
        display: flex;
        align-items: center;
        padding-right: 10px;
      }
    }
  }
}
