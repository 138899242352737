.payment-data-mob-main {
  width: 100%;
  max-width: 768px;
  background-color: #fafafa;

  .payment-data-mob {
    margin-top: 10px;
    padding-top: 10px;

    background-color: white;
    .data-box {
      border-bottom: 1px solid #f3f3f3;
      .uper-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .uper-left-side {
          .ending-in {
            font-weight: bold;
            padding-left: 5px;
          }
        }
        .uper-right-side {
        }
      }
      .bottom-row {
        margin-bottom: 7px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .bottom-left-span {
          display: flex;
          flex-direction: column;
          .name-label {
            font-size: 13px;
            font-weight: bold;
            opacity: 0.9;
            color: #828597;
          }
        }
        .bottom-right-span {
          display: flex;
          flex-direction: column;
          font-weight: 500;
          .expiry-label {
            font-size: 13px;
            font-weight: bold;
            opacity: 0.9;
            color: #828597;
          }
          .expiry_date {
            font-size: 13px;
          }
        }
      }
    }
    .delete-label-and-switch {
      margin-top: 5px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .label {
        font-size: 13px;
        opacity: 0.8;
      }
      .switch {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        .switch-label {
          font-size: 13px;
          color: #6478b1;
        }
        .MuiSwitch-colorSecondary.Mui-checked {
          color: #3866de;
        }

        .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
          background-color: #d3dde6;
        }
      }
    }
  }
  .bottomNavigation {
    width: 100%;
    max-width: 768px;
    position: absolute;
    bottom: 0;
    overflow: hidden;
  }
}
