@import "../../../mixins.scss";
@import "../../../variables.scss";

.frequentContainer {
  margin-top: 15px;

  .freqprod1 a {
    display: block;
    align-items: center;
    color: black;
    text-decoration: none;
  }

  .freqprodcard {
    cursor: pointer;
  }

  .frequent {
    display: flex;
  }

  .imgholder {
    height: 100px;
    display: grid;
    align-items: center;
    justify-content: center;
  }

  .frequent1empty {

    width: 58%;

  }

  .frequent2main {
    padding: 12px;
    width: 40%;
    background-color: #F7F9FE;
  }

  .freqadddiv {
    padding-top: 10px;
  }

  .addcheck {
    cursor: pointer;
  }

  .frequenttxt {
    padding-bottom: 10px;
    font-family: 'Futura Heavy';
    font-size: 14px;
    display: block;
  }

  .freqprodbox {


    max-width: 400px;
    position: relative;
  }

  .freqprodcont {
    display: flex;
    background: rgb(255, 255, 255);
    border: 1px solid rgb(235, 236, 240);
  }

  .fp {
    position: relative;
    padding: 12px 6px;
    height: 100%;
    display: flex;
    flex-direction: row;
    -webkit-box-align: center;
    align-items: center;
    text-align: center;
    flex-shrink: 0;
    -webkit-box-flex: 1;
    flex-grow: 1;
    width: 130px;

    img {
      width: 100px;
      height: inherit;
    }
  }

  .plusdiv {
    display: flex;
  }

  .freqprodname {
    height: 35px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-height: 1.5;
  }

  .freqprice span {
    font-family: 'Futura Heavy';
  }

  .freqadd {
    width: 100%;
    border: 1px solid #742013;
    background-color: white;
    border-radius: 5px;
    padding: 7px;
    color: #742013;
    font-family: 'Futura Heavy';
    font-size: 15px;
    display: inline-block;
    /* "to left" / "to right" - affects initial color */
    background: linear-gradient(to left, rgb(255, 255, 255) 50%, #742013 50%) right;
    background-size: 200%;
    transition: .5s ease-out;
  }

  .loading-btn {
    width: 100%;
    border: 1px solid #742013;
    background-color: #742013;
    border-radius: 5px;
    padding: 7px;
    font-family: 'Futura Heavy';
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .freqadd:hover {}

  .freqaddinactivebtn {
    background-color: #74201336;

    width: 100%;
    border-radius: 5px;
    padding: 7px;
    color: #777;
    font-family: 'Futura Heavy';
    font-size: 15px;
    display: inline-block;
  }

  .chknimg {
    cursor: pointer;
  }

  .frequent2main:hover .freqadd {
    background-position: left;
    color: white;
  }

  .addcheck {
    position: absolute;
    top: 10px;
    left: 15px;
  }

}