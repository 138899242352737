@import "../../../variables.scss";
@import "../../../mixins.scss";

.order-details {
  .small-screen-none {
    @include for-mob-screen {
      display: none !important;
    }
  }

  .large-screen-none {
    @include for-desktop-screen {
      display: none !important;
    }
  }
  .back {
    font-size: 0.75rem;
    cursor: pointer;
    text-align: initial;

    .icon {
      font-size: large;
      margin-inline-start: -0.2rem;
    }

    .text {
      margin-top: 2px;
      margin-inline-start: 5px;
    }
  }

  .top-section {
    .banner {
      height: 150px;
      background: rgb(63, 62, 62);
      color: #fff;
      border-radius: 4px;
      background: transparent linear-gradient(90deg, #a0d494 0%, #e8e97c 100%)
        0% 0% no-repeat padding-box;
    }
  }

  .shipments {
  }
}
