.main-container-wallet-card-mobile {
  border-radius: 10px;
  background-color: white;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  text-align: start;
  font-size: 13px;
  
  .wallet-card-inner-container {
    margin-top: 15px;
    height: auto;
    .red-color {
      color: red;
    }
    .green-color {
      color: green;
    }
    .label-for-balance {
      font-size: 12px;
      color: #9ea1a7;
    }
    display: flex;
    flex-direction: column;
    align-content: center;
    .main-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      .card-first-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        .left {
          display: flex;
          flex-direction: row;
          align-items: center;
          .return-refund-label {
            margin-inline-start: 8px;
            .returned-or-refunded {
              font-size: 13px;
              font-weight: bold;
            }
          }
        }
        .right {
          font-size: 13px;
          font-weight: bold;
        }
      }
    }
    .card-second-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      border-bottom: 1px solid grey;
      margin-inline-start: 24px;
      .left {
      }
      .right {
      }
    }
    .card-third-row {
      margin-inline-start: 24px;
      text-align: start;
    }
    .card-fourth-row {
      margin-inline-start: 24px;
      text-align: start;
    }
  }
}
