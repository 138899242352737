@import "../../mixins.scss";

.main-container-payment-method-mobile {
  background-color: #ecedf2;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 20px;
  margin-right: 10px;
  text-align: start;

  .bg-blue {
    border: 1px solid #5972b3;
  }

  .active {
    border: 1px solid #d4982b;
  }

  .inactive {
    border: 1px solid #cbcdcf;
  }

  .payment-method-label {
    font-size: 1rem;
    font-weight: 700;
    color: #404553;
  }

  .add-card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 7px;
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
    color: white;
    border-radius: 5px;
  }

  .active-btn {
    background-color: $form-btn-bg;
  }

  .inactive-btn {
    background-color: #cbcdcf;
  }

  .pay-label {
    font-size: 0.9rem;
    font-weight: 700;
    color: #404553;
  }

  .checkbocs {
    width: 25px;
    margin-inline-start: 10px;
  }

  .pay-with-noon-pay {
    background-color: #fff;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-inline-end: 10px;
    padding: 10px 0px;
    margin-top: 10px;

    .pay-label-and-balance {
      display: flex;
      flex-direction: column;
      margin-inline-start: 15px;

      .balance {
        font-size: 0.9rem;
        font-weight: 400;
        color: #404553;
      }
    }
  }

  .pay-with-card {
    margin-top: 10px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-inline-end: 10px;

    .checkbox-pay-card-label-icon {
      display: flex;
      flex-direction: row;
      border-bottom: 1px solid rgb(230, 232, 238);
      padding-bottom: 8px;

      .pay-card-label-icon {
        justify-content: space-between;
        margin-inline-start: 15px;
        display: flex;
        flex-direction: row;
        width: 90%;

        .card {
          margin-inline-end: 28px;
          border: none;
        }
      }
    }

    .expiry-input-div {
      align-items: center;
      justify-content: center;
      border: none;
      outline: none;
      box-shadow: 0px 0px 2px #0000004d;
      text-align: center;

      input {
        width: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        outline: none;
        // background-color: #e7effd;
        // padding: 14px 10px;
      }
    }

    .cards-div {
      display: flex;
      flex-direction: row;
      margin-top: 10px;
      height: auto;

      .radio-input {
        margin-inline-start: 20px;
        display: flex;
        align-self: center;
        justify-content: center;

        .radio {
          width: 50px;
          height: 1.5em;
        }

        .input-group-text {
          background-color: #e9ecef;
          border: 1px solid #ced4da;
          border-radius: 100%;
        }
      }

      .img-and-label-input {
        display: flex;
        width: 100%;
        justify-content: space-between;

        .security-number-payment-card {
          .sn-input {
            width: 90px;
            height: 40px;
            margin-inline-end: 15px;
          }
        }
      }
    }

    label {
      font-size: 0.9rem;
      font-weight: 400;
      color: #404553;
    }

    input {
      padding: 10px 5px;
      outline: none;
      border: 1px solid $border-color;
      border-radius: 3px;
    }
  }
}