@import "../mixins.scss";





.emptyspacer {
  height: 30px;
}
.emptyspacer-small {
  height: 20px;
}

.homepage-main-banner-swiper {
  max-width: $max-page-width;
  margin: 20px auto !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.row.align-items-center.brands-lg-section{
  padding-bottom: 30px;
  background-color: white;
  margin-left: 0px;
  margin-right: 0px;
  padding-top: 30px;
  border-radius: 5px;
  padding-left: 10px;
  padding-right: 10px;
}
.category-content {
  background-color: #f8f8f8;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  text-align: justify;

  p {
    font-size: 14px !important;
  }

  li {
    font-size: 14px !important;
  }

  h2 {
    text-align: left;
  }
}

.read-more-link {
  display: block;
  text-align: center;
  color: black;
  letter-spacing: 0.3px;
  font-weight: 800;
  font-size: 14px !important;
  cursor: pointer;
}


.catsecpic img {
  border: 1px solid #9e9e9e52;
}

.six6 img {
  border: 1px solid #9e9e9e52;
}

.brands-row {
  display: flex;
  overflow-x: scroll;
}

.emptyspacer-big {
  height: 60px;
}

.imgofr-desk {
  background-color: #d9002a;
  height: 100px;
  width: 100px;
  border-radius: 50px;
  margin-bottom: 5px;
}

.imgofr-desk h4 {
  font-size: 16px;
  text-align: center;
  padding-top: 30px;
  color: white;
  padding-bottom: 40px;
  font-weight: 800;
  font-family: 'Futura Heavy';
  animation: blinker 1s step-start infinite;
  text-transform: uppercase;
}

span.ofrtxt {
  display: block;
  font-size: 17px;
  color: black !important;
  font-weight: 900;
  text-align: center;
  font-family: 'Futura Heavy';
  letter-spacing: 0px;
}

.imgofr-desk span {
  display: block;
  font-size: 10px;
  color: #ffffff;
  text-transform: uppercase;
  margin-bottom: 3px;
}

.one1 {
  position: relative;
}

.two2 {
  display: flex;
  align-items: flex-start;
  -webkit-box-pack: justify;
  justify-content: space-between;
  margin: 0px 15px;
}

.three3 {
  padding: 0px 15px;
  display: flex;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
}

.four4 {
  flex: 0 0 calc(14.2857% - 7.5px);
  padding-bottom: 0px;
  flex: 1 1 0%;
  box-sizing: border-box;
  position: relative;
}

.six6 {
  position: absolute;
  inset: 0px;
  display: flex;
  -webkit-box-pack: center;
  -webkit-box-align: center;
  align-items: center;
  padding-right: 5px;
  padding-left: 5px;
}

.six6 img {
  display: block;
  max-width: 100%;
}

.catsecbtn {
  // float: right;
}

.catsec {
  display: unset;
}

p.section-subtitle {
  display: block;
  font-weight: 600;
  letter-spacing: 0.7px;
}

img.homeofferimg {
  width: -webkit-fill-available;
}

.mic,
.mid {
  margin-bottom: 30px;
}

.featuredcat {
  // padding-left: 50px;
  // padding-right: 50px;
}

h2.section-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  // display: block;
  font-size: 22px;
  font-family: 'Futura Heavy';
  font-weight: 700;
}


h2.stageheadingbk {
  display: block;
  align-items: center;
  justify-content: space-between;
  font-size: 33px;
  background: linear-gradient(90deg, #742013 0%, #D4982B 15.5%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: 'Futura Heavy';
  font-weight: 700;
}

span.stageheading {
  display: block;
  align-items: center;
  justify-content: space-between;
  font-size: 33px;
  background: linear-gradient(90deg, #742013 0%, #D4982B 15.5%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: 'Futura Heavy';
  font-weight: 700;
}


.brands-section img{
  border-radius: 5px;
  background-color: white;
  border: 1px solid rgb(229, 229, 229);
}
h3.section-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  // display: block;
  font-size: 30px;
  background: linear-gradient(90deg, #742013 0%, #D4982B 15.5%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: 'Futura Heavy';
  font-weight: 700;
}
.whitecard{
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  padding-bottom: 30px;

   .sectiontitle-container-left {
    // padding-left: 20px;
    // padding-right: 20px;
}
.brands-section{
  // padding-left: 20px;
  // padding-right: 20px;
  .sectiontitle-container-left{
    padding-left: unset;
    padding-right: unset;
  }
.swiper-slide {
    margin-right: 18px !important;
}
}

.product-list-desktop .swiper-container{
  // padding-left: 20px;
  // padding-right: 20px;
}
.all-categories-section .sectiontitle-container-left{
  padding-left: unset;
  padding-right: unset;
}


.all-categories-section .col-md-3{
  padding-right: 0px;
}
.all-categories-section{
  padding-right: 20px;
}
}

.whitecard1{
  background-color: white;
  padding: 20px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;

  padding-bottom: 30px;
}

.section-underline {
  position: relative;
  z-index: 1;
}

.sectiontitle-container-left {
  text-align: left;
  // margin: 0px 10px 0px 10px;
}

.catsecpic {
  padding-left: 5px;
  padding-right: 5px;
}

.sectiontitle-container {
  text-align: center;
}

.middleoffershide {
  display: none !important;
}

.section-underline:after {
  position: absolute;
  content: "";
  left: 50%;
  bottom: 2px;
  width: calc(100% + 4px);
  height: 8px;
  background: #d4982b;
  border-radius: 10px;
  z-index: -1;
  transform: translate(-50%);
}

.nav-tabs {
  border: none !important;
}

.nav-link.active {
  border: 1px solid black !important;
  background-color: unset !important;
  font-weight: 600 !important;
  border-radius: 100px !important;
  padding: 0.5rem 1rem !important;
}


.ViewAllbtn {
  text-align: center;
  font-weight: 400;
}

.ViewAllbtn a {
  padding: 0.5rem 1rem;
  border-radius: 5px;
  color: white;
  background-color: #742013;
  font-size: 1rem;
  // border: 1px solid #212529;
  // color: #212529;
}

// .ViewAllbtn a:hover {
//   border: 2px solid #212529;
//   text-decoration: none;
//   color: #212529;

// }

.temphide {
  display: none !important;
}

.kid-fashion-banner-row.mx-auto.last-bannernew.row {
  display: none;
}

.top-category-header span {
  position: relative;
  z-index: 1;
}


// .modal-backdrop{
//   z-index: 99999 !important;
// }

.offers-modal {
  z-index: 99999 !important;

  .modal-dialog {
    margin-top: 70px;
  }

  .modal-dialog-centered {
    margin: auto !important;
  }

  .modal-content {
    border: none;
    background-color: transparent;
  }
}

.product-home-page-desktop {
  .home-banner {
    background: url('../../../assets/images/homepage/banner_1.png');
    padding: 20px;
    height: 500px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    .banner-content {
      margin-top: 130px;
    }

    h2 {
      color: white;
      font-weight: bold;
    }

    .search-form {
      display: flex;
      margin-top: 20px;
      width: 650px;

      .form-control {
        color: #742013;
        border-radius: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none !important;
        box-shadow: none !important;
        height: 44px;
        padding: 12px;
      }

      .search-box {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: -115px;

        .search-btn {
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 20px;
          border: 1px solid #742013;
          background-color: #742013;
          color: white;
          height: 36px;
          padding: 0px 10px;
          span {
            font-size: 13px;
          }
        }
      }
    }
  }

  .middle-cards-row {
    background-color: #4F1910;
    padding-top: 40px;
    padding-bottom: 40px;

    .col-md-3 {
      padding-left: 25px;
      padding-right: 25px;
    }

    .custom-card {
      border-radius: 20px;
      background-color: #52281f;
      color: white;
      padding: 25px 20px;

      .icon-div {
        background-color: #5a3229;
        border-radius: 50px;
        width: fit-content;
        padding: 10px;

        .engineer-icon {
          width: 70px;
          height: 70px;
        }
      }

      .title {
        margin-top: 10px;
        font-weight: bold;
      }

      .content {
        font-size: 15px !important;
        line-height: 20px;
      }
    }
  }

  .middle-section {
    background-color: #F7F2F0;

    .col-md-2, .col-md-5 {
      padding: 25px 8px;
    }

    .auth {
      background-color: white;
      border-radius: 20px;
      padding: 20px 0px;

      p {
        text-align: center;
        margin: 10px 0px;
        font-size: 15px !important;
      }

      button {
        border-radius: 20px;
        border: none;
        font-weight: bold;
        border: 1px solid #742013;
        background-color: white;
        color: #742013;
        width: 100%;
        padding: 6px;
      }

      .active {
        background-color: #742013;
        color: white;
      }
    }

    .get-app {
      background-color: white;
      border-radius: 20px;
      padding: 20px;
      text-align: center;

      h5 {
        font-weight: bold;
        color: #742013;
      }

      .get-app-img {
        height: 130px;
      }

      .googleplay-img {
        height: 40px;
      }
    }

    .banners-section {
      background-color: white;
      border-radius: 20px;
      padding: 10px;

      img {
        width: 100%;
        height: 148px;
        margin-bottom: 10px;
      }

      .last-img {
        margin-bottom: 0px;
      }
    }

    .reasons-to-shop-section {
      background-color: white;
      border-radius: 20px;
      padding: 20px;

      h5 {
        color: #742013;
        font-weight: bold;
      }

      .card {
        border-radius: 10px;
        height: 190px;
        background-color: #F5F4F4;
        border: none;
      }
    }
  }

  .discover-building-solutions {
    background-color: white;
    padding: 80px 20px 50px 20px;

    h2 {
      font-weight: bold;
    }

    .count-div {
      border-left: 5px solid #DDDDDD;

      .count {
        font-weight: bold;
        color: #D4982B;
      }
    }
  }

  .categories-section {
    background-color: white;
    padding: 20px 20px 50px 20px;

    img {
      height: 170px;
      width: auto;
    }

    .img-category {
      width: 110px;
      height: 110px;
      border-radius: 50%;
      background-color: #F5F4F4;
      border: 1px solid #CCCCCC;
    }
  }

  .onboarding {
    background-color: #74201399;
    padding: 35px;
    color: white;

    .title {
      font-weight: bold;
      text-align: center;
      margin-bottom: 35px;
    }

    h3 {
      font-weight: bold;
      margin: 0px;
    }

    ul {
      margin-top: 115px;
      padding-left: 20px;
      list-style: "✓  ";

      li {
        font-size: 15px;
      }
    }
    
    .data {
      width: fit-content;
      margin-left: auto;
    }

    .step {
      width: 70px;
      height: 70px;
      background: white;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: auto;

      p {
        margin: 0px;
        font-size: 42px !important;
        font-weight: bold;
        color: #6b2619;
      }
    }

    p {
      font-size: 15px !important;
      margin: 0px;
      text-align: center;
    }

    .lines {
      text-align: center;
      p {
        margin: 0px;
        line-height: 16px;
      }
    }
  }

  .personalized-recommendations {
    text-align: center;
    background-color: white;
    padding-top: 70px;
    padding-bottom: 90px;

    h3 {
      font-weight: bold;
      margin-bottom: 15px;
    }

    .register-btn {
      background-color: #742013;
      color: white;
      cursor: pointer;
      padding: 7px 25px;
      border-radius: 20px;
      text-decoration: none;
      font-weight: bold;
    }

    a {
      color: black;
    }
  }

  .all-categories-section {
    .category-view {
      margin-top: 10px;
      border: 1px solid #cccccb;
      border-radius: 5px;
      padding: 15px 15px;
      align-items: center;

      p {
        margin: 0px;
        color: #212529;
        text-decoration: none;
        font-family: 'Futura Heavy';

      }
      &:hover {
        border: 1px solid #212529;
        p{
          text-decoration: underline;
        }
      }
    }
  }

  .swiper-button-next,
  .swiper-button-prev {
    border-radius: 50%;
    background: #d4982b;
    height: 40px;
    width: 40px;
    color: black;
  }

  .swiper-button-disabled {
    display: none;
  }

  .swiper-button-next:after,
  .swiper-button-prev::after {
    font-size: 1.5rem;
    color: white;
  }

  max-width: 100%;
  // background-color: #e3e6e6bf;

  .kid-fashion-banner-row.mx-auto.row:last-child img {
    height: 100px;
  }

  .kid-fashion-banner-row.mx-auto.row:last-child {
    height: 100px;
  }

  // .new-arrivals .product-price-container {
  //   display: none !important;
  // }

  // .new-arrivals .product-rating-row {
  //   display: none !important;
  // }

  .content-wrapper {
    margin: auto;
    width: 100%;
    max-width: $max-page-width;

    .top-category-header {
      font-weight: 700;
      display: block;
      width: 100%;
      font-size: 32px;
      text-align: center;
      color: #7d252a;
    }


    .swiper-container {}

    .product-category-list-row {
      margin-bottom: 50px;
    }

    .working-row {
      margin: 50px 0;
      display: none;
    }

    .stageheading {

      .title1{
        font-family: 'Futura Heavy';
        padding-top: 20px;
      }
    .title2 {
      background: linear-gradient(88deg, #742013 0%, #D4982B 60%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-family: 'Futura Heavy';
    }
  }

    .brands-lg-section {
      h2 {
        font-family: 'Futura Heavy';
      }

      .title2 {
        background: linear-gradient(90deg, #742013 0%, #D4982B 10%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      p {
        font-size: 15px !important
      }

      .view-brands-btn {
        background-color: #742013;
        color: white;
        padding: 10px 15px;
        border-radius: 5px;
        font-size: 15px;
        text-decoration: none;
      }

      .shop-now-btn {
        border: 1px solid #484848;
        color: #484848;
        padding: 10px 15px;
        border-radius: 5px;
        font-size: 15px;
        text-decoration: none;
      }

      .brand-img {
        border: 1px solid #777777;
        border-radius: 50%;
      }
    }

    .products-section {
      position: relative;
      // padding-left: 10px;
      // padding-right: 10px;
      box-sizing: border-box;
    }

    .product-list-row {
      // margin-bottom: 50px;
      max-width: 1440px;
      justify-content: center;
      margin-top: 15px;

      @include for-768-screen {
        margin-bottom: 0px;
      }

      .header {
        margin-bottom: 0px;
        font-weight: 700;
        color: #7d252a;
        opacity: 1;
        text-transform: capitalize;
        width: 100%;
        padding: 20px 0 0 0px;
        font-size: 27px;
        text-align: left;
      }
    }

    .kid-fashion-banner-row {

      max-width: 1440px;
      height: 300px;

      .kid-fashion-banner-container {
        width: 100%;
      }
    }

    .two-banners-row {
      margin-bottom: 50px;
      max-width: 1440px;
      height: 300px;
      margin-top: 70px;

      .two-banners-container {
        width: 100%;

        .banner-inner {
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-template-rows: 1fr;
          column-gap: 10px;
        }
      }
    }
  }
}