@import "../mixins.scss";

.search-popover::-webkit-scrollbar-thumb {
  background: #742013;
}

.search-popover::-webkit-scrollbar {
  background: #fff;
  width: 8px;
}

.search-right-col .category-name .category-search-item:hover {
  background-color: rgba(212, 152, 43, .16862745098039217);
}

.search-result-col.pl-2 li:hover {
  background-color: rgba(212, 152, 43, .16862745098039217);
}

.search-popover {
  width: 100%;
  position: absolute;
  z-index: 999999;
  top: 105%;
  margin-top: 5px;
  box-shadow: 0px 5px 4px 0px rgba(0, 0, 0, 0.18);
  background-color: white;
  max-height: 400px;
  overflow-y: auto;
  padding-bottom: 10px;

  .trending-searches .recent-search-result-row:hover {
    background-color: unset;
  }

  .recent-search-result-row:hover {
    background-color: #d4982b2b;
  }

  .search-popover-row {
    margin: 0px !important;
    width: 100%;

    .trending-searches {
      .trending-tags {
        padding: 10px;
      }

      .trending-left-col {
        font-size: 0.8rem;
        font-weight: 600;
        color: #000000;
        letter-spacing: 0.3px;
        padding: 10px;
      }

      span {
        margin-left: 5px;
        border: 1px solid #ccc;
        margin-right: 5px;
        background-color: #d4982b2b;
        padding: 4px;
        border-radius: 4px;
      }

      span a {
        text-decoration: none;
        color: black;
      }
    }

    .recent-search {
      width: 100%;

      .recent-search-row {
        width: 100%;
        padding: 10px;

        .recent-left-col {
          font-size: 0.8rem;
          font-weight: 600;
          color: #000000;
          letter-spacing: 0.3px;
        }

        .recent-right-col {
          font-size: 0.7rem;
          font-weight: 400;
          color: $head-color;
        }
      }

      .recent-search-result-row {
        width: 100%;
        padding: 5px 10px;

        .recent-result-col {
          .icon {
            padding-right: 10px;
            font-size: 0.8rem;
            font-weight: 400;
            color: $head-color;

            svg {
              font-size: 1.2rem;
            }
          }

          .text {
            font-size: 0.8rem;
            font-weight: 400;
            color: $head-color;
          }
        }

        .icon-col {
          font-size: 1rem;
          font-weight: 400;
          color: $head-color;
        }
      }
    }

    .search-col {
      width: 100%;

      a {
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .search-result-col {
        @include for-md-screen {
          width: 50%;
        }

        width: 60%;
        border-right: 1px solid #74201317;

        ul {
          margin: 0px !important;
          padding: 0px !important;
          margin-top: 5px !important;

          li {
            list-style: none;
            text-align: start;

            a {
              text-decoration: none;
              display: flex;
              align-items: center;
              padding: 5px;
              margin-bottom: 5px;
              font-size: 0.88020625rem;
              color: $head-color;

              .highlighted {
                color: royalblue;
                font-weight: 700;
              }
            }
          }
        }
      }

      .search-right-col {
        background-color: #fcfaf4;

        @include for-md-screen {
          width: 50%;
        }

        width: 40%;

        .wrapper {


          .header {
            padding-top: 5px;
            font-weight: 700;
            font-size: 0.75rem;
            color: $p-color;
            background-color: rgba(212, 152, 43, .16862745098039217);
          }

          .category-name,
          .brand-name {
            text-decoration: none;
            display: block;
            margin-bottom: 5px;
            font-size: 0.88020625rem;
            color: $head-color;

            .category-search-item {
              color: #404553;
              padding: 0.3rem 10px;
              text-align: start;
              text-decoration: none;
            }

            .brand-search-item {
              padding: 0.3rem 10px;
              text-align: start;
            }
          }
        }
      }
    }
  }
}