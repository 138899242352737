.paintspage {
    background-color: white;


    .title-row {
        display: flex;
        width: 100%;
        justify-content: space-between;
        padding-top: 15px;
        span.uptostyle {
          font-family: Futura Heavy;
          color: #d9002a;
      }
    
        .title {
          display: block;
         font-family: 'Futura Heavy';
         font-size: 1.5rem;
          letter-spacing: 0.2px;
          text-align: left;
        
        }
        .viewalldiv{
          width: 30%;
          text-align: right;
        }
        .viewalldiv-ar{
          text-align: left !important;
        }
        .view-all-text {
          padding-top: 0.4rem;
          padding-bottom: 0.4rem;
          padding-left: 0.8rem;
          padding-right: 0.8rem;
          font-size: 0.916666rem;
          background-color: black;
          color: white;
          width: 30%;
        }
      }

    .pagesbanner {
        padding-top: 15px;
        padding-bottom: 30px;
    }

    .paintcat {
        background-color: #fcfaf4;
        border: 1px solid #d4982b;
        border-radius: 10px;
    }

    .paintbrand {
        background-color: #fcfaf4;
        border: 1px solid #d4982b;
        border-radius: 100px;
    }

    .topofferimg {
        width: 175px;
        -webkit-transform: rotate(362deg);
    }

    .offers.catbox {
        padding: 15px;
    }

    .six6.catsecpic img {
        border: 1px solid #9e9e9e52;
    }

    .surfacepat .product-list-desktop .product-list-container {

        background-color: #fcfaf4;
    }

    .catrow {
        display: flex;

    }

    .six6 img {
        border: none;
    }

    .catbox {
        padding: 0px;
    }

    .offerbg {
        background-color: #ffffff;
        border-bottom: 140px solid #f8e89a;
        border-left: 23px solid transparent;
        border-right: 14px solid transparent;
        height: 0;
        border-radius: 50px;
        margin-top: 30px !important;
        margin: 15px;
        transform: rotate(2deg);
        -webkit-transform: rotate(358deg);
        -moz-transform: rotate(2deg);
        text-align: right;
    }

    .sectiontitle-individualpage {
        margin-bottom: 0px;
        text-align: center;
        color: #742013;
        font-weight: 800;
        font-family: 'Futura Heavy';
        font-size: 32px;
    }

    .titlewrap.noclr {
        background-color: #ffffff;
    }

    .offertitles {
        text-align: left;
        float: left;
        width: 170px;
        font-family: 'Futura heavy';
        margin-top: 29px;
        font-size: 35px;
        margin-left: 8PX;
        text-transform: uppercase;
        -webkit-transform: rotate(362deg);
        line-height: 0.9;
        /* -webkit-text-stroke: 1px white; */
        -webkit-text-stroke: thin;
    }

    span.offerprice {
        font-family: 'Futura heavy';
        margin-top: 29px;
        color: #ff5722;
    }

    .mypages-container {
        margin-right: auto !important;
        margin-left: auto !important;
        padding-left: 10px !important;
        padding-right: 10px !important;
        width: 1300px;
    }

    @media (min-width: 1360px) {
        .container {
            width: 1300px;
        }
    }

    @media (min-width: 1280px) {
        .container {
            width: 1220px;
        }
    }

    @media (min-width: 1170px) {
        .container {
            width: 1024px;
        }
    }

    @media (min-width: 992px) {
        .container {
            width: 970px;
        }
    }

    @media (min-width: 768px) {
        .container {
            width: 750px;
        }
    }
}