@import "../mixins.scss";

.map-desktop {
  width: 100%;
  height: 400px;

  .spinner {
    position: absolute;
    height: 25px;
    width: 25px;
    z-index: 10;
    top: 50%;
    left: 50%;
  }

  .search-box {
    position: absolute;
    z-index: 10;
    width: 96%;
    left: 2%;

    .search-input {
      border: none;
      outline: none;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
      border-radius: 4px;
      font-size: 14px;
      font-weight: 300;
      padding-top: 12px;
      padding-bottom: 12px;
    }
  }
  .locate-me {
    @include for-mob-screen {
      position: fixed;
    }
    @include for-desktop-screen {
      position: absolute;
    }
    z-index: 1;
    right: 2%;
    background: white;
    border: 1px solid black;

    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    border-radius: 20px;
    display: flex;
    height: 38px;
    width: 120px;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 14px;
    cursor: pointer;
  }

  .error-message {
    position: absolute;
    width: 85%;
    left: 5%;
    z-index: 10;
    color: #d6939a;
    font-size: 15px;
    background-color: #f7e7e8;
  }
}
