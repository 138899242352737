@import "../mixins.scss";
@import "../variables.scss";

.offerrow{
  margin-top:15px;
  margin-left: 0px !important;
  margin-right: 0px !important;
}
.freeshipmob p {
  font-size: 14px!important;
  color: black;
}
.freeshipmob {
background-color: #d4982b2b;
}

.shop-by-brands .swiper-wrapper {
  justify-content: flex-start;
}

.paintbrand {
  background-color: #fcfaf4;
  border: 1px solid #d4982b;
  border-radius: 100px;
}

.all-page-container {
  max-width: 1440px !important;
  .sectiontitle-individualpage-mob {
    color: #000000;
    font-size: 25px;
    font-family: "Futura", sans-serif;
    font-weight: 600;
    text-align: center;
    margin: 0;
  }
  .row.about-and-contact-row.mx-auto.px-4 .col-md-4 {
    border: 1px solid #d4982b;
    border-right-width: thick;
    box-shadow: 1px 1px 0px 2px #d4982b;
    height: 301px;
    border-top-left-radius: 40%;
    border-top-right-radius: 40%;
    margin: 15px!important;
    background-color: #fff4e0;
}

  .title-row {
    display: flex;
    width: 100%;
    padding-top: 15px;
    span.uptostyle {
      font-family: Futura Heavy;
      color: #d9002a;
      
  }
  .mobtit .uptostyle{
    display: block;
  }
    .title {
      display: block;
      font-family: 'Futura Heavy';
      letter-spacing: 0.2px;
      text-align: left;
      width: 70%;
     
    }
    .mobtit{
      font-size: 1rem!important;
    }
    .viewalldiv{
      width: 30%;
      text-align: right;
    }
    .view-all-text {
      padding-top: 0.4rem;
      padding-bottom: 0.4rem;
      padding-left: 0.8rem;
      padding-right: 0.8rem;
      font-size: 0.916666rem;
      background-color: black;
      color: white;
      width: 30%;
    }
  }
}

  .offer-tabs {

  }
  ul.react-tabs__tab-list {
    background-color: #d4982b;
    text-align: center;
    padding: 2px;
}
.react-tabs__tab {
  border: unset;
  font-weight: 700;
  font-size: 22px;
}
.react-tabs__tab:focus {
  box-shadow: unset;
  border-color: unset;
  outline: none;
}
.react-tabs__tab:focus:after {
  content: "";
  position: absolute;
  height: 5px;
  left: -4px;
  right: -4px;
  bottom: -5px;
  background: unset;
}
.offerends{
  font-size: 13px;
}
.weeklysales{
  font-weight: 800;
  margin-bottom: 50px;
}
.react-tabs__tab-panel  {
  max-width: 1440px !important;
  margin-left: auto;
  margin-right: auto;
  padding: 20px 20px;
}
.avcc {
  text-align: -webkit-center;
}
.react-tabs__tab {
  color: white;
}
.react-tabs__tab--selected{
  color:black;
}

.row.offerrow.xyz .col-4 {
padding-left: 5px;
padding-right: 5px;
}
.offctxt {
  text-align: center;
  color: #7d252a;
  font-weight: 600;
  font-size: 15px;
}
.row.offerrow.xyz img {
  border-radius: 2px;
}